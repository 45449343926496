import { useContext } from "react";
import { LoginContext } from "../context/login-context/login-context";
import { apiPost } from "../api/api-calls";

export function useNotifyApprovers() {
  const { activeClub } = useContext(LoginContext);
  const notifyApprovers = (campaignName: string, userName: string, comment?: string) =>
    apiPost(`/api/approvers/${activeClub}`, { campaignName, userName, comment });
  return { notifyApprovers };
}
