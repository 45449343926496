import React, { Fragment, useMemo } from "react";
import { useTable, useSortBy, Column, usePagination, useFilters, useExpanded, Row } from "react-table";
import { DefaultColumnFilter } from "../helpers/dashboard-helpers/default-column-filter";
import { Pagination } from "./pagination";
import { DMAEvent } from "../types";
import { useTranslation } from "../hooks/use-translation";
import { generateSortingIndicator } from "../helpers/dashboard-helpers/generate-sorting-indicator";

//NB: requires extending the react-table default types with the plugins that we actually use
// see the doc at https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/react-table for more information

export type IColumn = {
  column: IColumnVariables;
};
export type IColumnVariables = {
  filterValue: string;
  preFilteredRows: Array<Row>;
  filteredRows: Array<Row>;
  setFilter: (filterValue: string | undefined) => void;
};

type Props = {
  events: DMAEvent[];
};

export const EventsTable: React.FC<Props> = ({ events }) => {
  const { translate } = useTranslation();
  const columns = useMemo<Column<DMAEvent>[]>(
    () => [
      {
        Header: "ID",
        accessor: "eventId",
      },
      {
        Header: translate("events.date"),
        accessor: "createdAt",
        disableFilters: true,
        Cell: (props) => {
          const formatedDate = new Date(props.value).toLocaleString();
          return <span>{formatedDate}</span>;
        },
      },
      {
        Header: translate("events.action"),
        accessor: "action",
        disableFilters: true,
        Cell: (props) => {
          if (!props.value) return <span />;
          const translatedStatus = translate(`status.${props.value}`);
          return <span>{translatedStatus}</span>;
        },
      },
      {
        Header: translate("events.author"),
        accessor: "user",
        disableFilters: true,
      },
      {
        Header: translate("events.details"),
        accessor: "comment",
        disableFilters: true,
      },
    ],
    [translate]
  );

  const data = useMemo<DMAEvent[]>(() => events, [events]);

  const filterTypes = React.useMemo(
    () => ({
      text: (rows: any[], id: React.Key, filterValue: any) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable<DMAEvent>(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: ["eventId"],
        pageSize: 5,
        sortBy: [
          {
            id: "createdAt",
            desc: true,
          },
        ],
      },
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  return (
    <>
      <table {...getTableProps()} className="table is-bordered is-hoverable is-fullwidth">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  <span {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                  </span>
                  {column.canFilter ? <>{column.canFilter ? column.render("Filter") : null}</> : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <Fragment key={row.id}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
      <Pagination
        pageSize={pageSize}
        setPageSize={setPageSize}
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageIndex={pageIndex}
      />
    </>
  );
};
