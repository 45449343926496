import React, { useEffect } from "react";
import { useAsyncDebounce } from "react-table";
import { FontAwesome } from "../../components/icons/font-awesome";
import { Channel } from "../../types";
import { useModulesPermissions } from "../../hooks/use-modules-permissions";
import cx from "classnames";
import { useTranslation } from "../../hooks/use-translation";

export function GlobalFilter({
  globalFilter,
  setGlobalFilter,
  commonDashboardFilter,
  setCommonDashboardFilter,
}: {
  globalFilter: "SMS" | "EMAIL";
  setGlobalFilter: any;
  commonDashboardFilter?: Channel;
  setCommonDashboardFilter: (string?: Channel) => void;
}) {
  //TODO NEED TO BE REMOVE WHEN GO TO PRODUCTION

    const { isSmsModuleActivated } = useModulesPermissions();
  const { translate } = useTranslation();

  const onChange = useAsyncDebounce((value) => {
    setCommonDashboardFilter(value || undefined);
    setGlobalFilter(value || undefined);
  }, 0);

  useEffect(() => {
    if (commonDashboardFilter !== undefined) {
      if (commonDashboardFilter !== globalFilter) {
        onChange(commonDashboardFilter);
      }
    }
  }, [commonDashboardFilter, globalFilter, onChange]);

  const handleButtonFilterClick = (buttonType: Channel) => {
    if (buttonType === globalFilter) {
      onChange(undefined);
    } else {
      onChange(buttonType);
    }
  };
  return (
    <div className="box is-shadowless has-background-info-light">
      <div className="field is-grouped">
        <div className="field is-grouped">
          <div className="control">
            <h2> {translate("general.filters")} :</h2>
          </div>
          <div className="control">
            <button
              className={cx("button", "is-small", "is-primary", {
                "is-outlined": globalFilter !== "EMAIL",
              })}
              onClick={() => handleButtonFilterClick("EMAIL")}
            >
              <span className="icon is-small">
                <FontAwesome name="envelope" fontAwesomeVariant="far" />
              </span>
              <span>Email</span>
            </button>
          </div>
          <div
            className={cx("control", {
              isDisabled: !isSmsModuleActivated,
            })}
          >
            <button
              className={cx("button", "is-small", "is-primary", {
                "is-outlined": globalFilter !== "SMS",
              })}
              onClick={() => handleButtonFilterClick("SMS")}
            >
              <span className="icon is-small">
                <FontAwesome name="mobile-alt" />
              </span>
              <span>SMS</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
