import * as React from "react";
import { RouteComponentProps, navigate, Link } from "@reach/router";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";

import styles from "./login.module.scss";
import { InputField } from "../components/input-field";
import { Loader } from "../components/loader";
import { useTranslation } from "../hooks/use-translation";
import { useQueryCache } from "react-query";

type Autologin = {
  login?: string;
  password?: string;
};

let autologin: Autologin;
if (process.env.NODE_ENV === "development") {
  if (process.env.REACT_APP_AUTOLOGIN !== undefined) autologin = JSON.parse(process.env.REACT_APP_AUTOLOGIN);
}

export type LoginData = {
  login: string;
  password: string;
};

export const validationSchema = yup.object().shape({
  login: yup.string().required("please enter your login"),
  password: yup.string().required("Please enter your password"),
});

type LoginHandler = (loginData: LoginData) => Promise<boolean>;

export const Login: React.FC<RouteComponentProps & { onLogin: LoginHandler }> = ({ onLogin }) => {
  const { translate } = useTranslation();

  const handleLogin = async (loginData: LoginData) => {
    const result = await onLogin(loginData);
    if (!result) {
      return false;
    }
    navigate("/campaigns");
    return true;
  };

  return (
    <div className="box is-shadowless">
      <LoginForm onLogin={handleLogin} />
      <p className="is-size-6 has-text-centered">
        {translate("login.forgotPassword")} ? <Link to="/password">{translate("login.clickHereToReset")}.</Link>
      </p>
    </div>
  );
};

const LoginForm: React.FC<{ onLogin: LoginHandler; title?: string }> = ({ onLogin, title }) => {
  const { register, handleSubmit, errors, setError, reset } = useForm<LoginData>({
    resolver: yupResolver(validationSchema),
  });

  const [isLoggingIn, setIsLoggingIn] = React.useState(false);

  const { translate } = useTranslation();

  React.useEffect(() => {
    if (autologin) {
      reset(autologin);
    }
  }, [reset]);

  const fieldProps = { register, errors };

  const handleLogin = async (loginData: LoginData) => {
    setIsLoggingIn(true);
    const result = await onLogin(loginData);
    if (!result) {
      setIsLoggingIn(false);
      const error = { type: "invalid", message: "Login or password is invalid" };
      setError("login", error);
      setError("password", error);
    }
  };

  return (
    <form className={`${styles.login} panel is-primary`} onSubmit={(e) => e.preventDefault()}>
      <p className="panel-heading">{title || translate("login.title")}</p>
      <div className="panel-block">
        {isLoggingIn ? (
          <Loader />
        ) : (
          <div className={styles.fullWidth}>
            <InputField name={"login"} label={translate("userName")} {...fieldProps} />
            <InputField name={"password"} label={translate("password")} type="password" {...fieldProps} />
            <div className="field is-grouped is-grouped-right">
              <p className="control">
                <button
                  className="button is-primary has-text-weight-bold"
                  type="submit"
                  onClick={handleSubmit(handleLogin)}
                >
                  {translate("login.title")}
                </button>
              </p>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export const LoginModal: React.FC<
  RouteComponentProps & {
    onLogin: LoginHandler;
    setLoginRequired: (boolean: boolean) => void;
  }
> = ({ onLogin, setLoginRequired }) => {
  const queryCache = useQueryCache();
  const { translate } = useTranslation();
  const handleLogin = async (loginData: LoginData) => {
    const result = await onLogin(loginData);
    if (!result) {
      return false;
    }
    queryCache.invalidateQueries();
    setLoginRequired(false);
    return true;
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-content">
        <LoginForm onLogin={handleLogin} title={translate("general.modal.sessionExpired")} />
      </div>
    </div>
  );
};
