import React from "react";
import { AttributeCriterion } from "../../types";

type Props = {
  value: AttributeCriterion;
  handleInputChange: (event: {
    target: {
      name: string;
      value: string;
    };
  }) => void;
  inputType?: string;
};

export const DefaultInput: React.FC<Props> = ({ value, handleInputChange, inputType = "text" }) => {
  return (
    <input
      name="value"
      className="input is-fullwidth is-text-overflow"
      value={value.value || ""}
      onChange={handleInputChange}
      type={inputType}
    />
  );
};
