import { useCallback, useState } from "react";
import { useMutation, useQuery, useQueryCache } from "react-query";
import { apiGet, apiPut } from "../api/api-calls";
import { Targeting, TargetingResolution } from "../types";

const fetchTargeting = (_key: "targetings", campaignId: string) =>
  apiGet<Targeting>(`/api/campaigns/${campaignId}/targeting`);

const fetchTargetingResolution = (_key: "targetings", campaignId: string, _action: "resolution") =>
  apiGet<TargetingResolution>(`/api/campaigns/${campaignId}/target`);

const updateTargeting = ({ campaignId, targeting }: { campaignId: string; targeting: Targeting }) => {
  return apiPut<{}>(`/api/campaigns/${campaignId}/targeting`, targeting);
};

export function useTargetingFromCampaign(campaignId?: string) {
  const { data } = useQuery(["targetings", campaignId], fetchTargeting, {
    enabled: campaignId !== undefined,
    refetchOnWindowFocus: false,
  });
  return data;
}

export function useTargetingResolutionFromCampaign(campaignId?: string) {
  const { data, isFetching } = useQuery(["targetings", campaignId, "resolution"], fetchTargetingResolution, {
    enabled: campaignId !== undefined,
    refetchOnWindowFocus: false,
  });
  return { data, isFetching };
}

export function useTargetingMutator(campaignId?: string) {
  const [targetingMutatorError, setTargetingMutatorError] = useState<unknown>();
  const queryCache = useQueryCache();
  const [mutate, { isLoading }] = useMutation(updateTargeting, {
    onSuccess: () => {
      setTargetingMutatorError(undefined);
      return queryCache.invalidateQueries(["targetings", campaignId]);
    },
    onError: (error) => {
      setTargetingMutatorError(error);
    },
  });
  const handleNewTargeting = useCallback(
    (targeting: Targeting) => {
      if (campaignId !== undefined) {
        mutate({ campaignId, targeting });
      }
    },
    [campaignId, mutate]
  );
  return { handleNewTargeting, targetingMutatorError, isLoading };
}
