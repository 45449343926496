import React, { useContext } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { LoginContext } from "../../context/login-context/login-context";
import { FontAwesome } from "../icons/font-awesome";
import { useTranslation } from "../../hooks/use-translation";
import { GenericMessage } from "../generic-message";
import { WizardContext } from "../../context/wizard-context";
import cx from "classnames";

type Recipient = { [emailAdress: string]: string };

type Props = {
  onTestSubmit: (emails: string[]) => void;
  statusCanTest: boolean;
  isTesting?: boolean;
  isErrorOnTest: boolean;
};

export const SendTestForm: React.FC<Props> = ({ onTestSubmit, isTesting = false, statusCanTest, isErrorOnTest }) => {
  const { userInfos } = useContext(LoginContext);
  const { channel } = useContext(WizardContext);
  const { translate } = useTranslation();

  const contactMethod = channel === "EMAIL" ? userInfos?.emailAddress : userInfos?.gsmNumber;

  const defaultValues = { recipients: [{ contactMethod: contactMethod }] };

  const { control, register, handleSubmit, errors } = useForm({
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "recipients",
  });

  const hasNoRecipients = fields.length === 0;

  const onSubmit = ({ recipients }: { recipients: Recipient[] }) => {
    const recipientList = recipients.map((recipient) => recipient.contactMethod);
    onTestSubmit(recipientList);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="box">
      <div className="recipients-list">
        {fields.map((recipient, index) => (
          <div className="field" key={recipient.id}>
            <div className="field-body">
              <div className="field">
                <div className="field is-grouped">
                  <p className="control is-expanded">
                    <input
                      name={`recipients[${index}].contactMethod`}
                      ref={register({
                        required: { value: true, message: "Field is required" },
                        pattern:
                          channel === "EMAIL"
                            ? {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Please enter a valid email address",
                              }
                            : {
                                value: /^\+(?:[0-9]●?){6,14}[0-9]$/i,
                                message: "Please enter a valid phone number",
                              },
                      })}
                      className="input"
                      type={channel === "EMAIL" ? "email" : "tel"}
                      placeholder={channel === "EMAIL" ? "e.g. jordan.picard@gmail.com" : "+xxxxxxxxxx"}
                      defaultValue={recipient.contactMethod}
                    />
                  </p>
                  <p className="control">
                    <button type="button" className="button" onClick={() => remove(index)}>
                      <span className="icon is-small">
                        <FontAwesome name="minus" />
                      </span>
                    </button>
                  </p>
                </div>
                {errors.recipients?.[index] && (
                  <p className="help is-danger">{errors.recipients?.[index]?.contactMethod?.message}</p>
                )}
              </div>
            </div>
          </div>
        ))}

        <div className="field ">
          <p className="control">
            <button type="button" className="button" onClick={() => append({}, true)}>
              <span>{translate("test.addRecipient")}</span>
              <span className="icon is-small">
                <FontAwesome name="plus" />
              </span>
            </button>
          </p>
        </div>
      </div>

      {isErrorOnTest && <GenericMessage translationKey="test.testing.error" />}

      <div className="buttons is-right">
        <button
          className={cx("button", "is-primary", "is-right", {
            "is-loading": isTesting,
          })}
          disabled={!statusCanTest || hasNoRecipients || isTesting}
        >
          <span>{translate("test.sendTest")}</span>
          <span className="icon">
            <FontAwesome name="paper-plane" />
          </span>
        </button>
      </div>
    </form>
  );
};
