import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FontAwesome } from "../icons/font-awesome";
import { useCampaign, useCampaignMutators } from "../../hooks/use-campaign";
import { useTranslation } from "../../hooks/use-translation";
import { CampaignStatus } from "../../types/campaigns";
import { GenericMessage } from "../generic-message";
import { useNotifyApprovers } from "../../hooks/use-notify-approvers";
import cx from "classnames";
import { CampaignDatePicker } from "./campaign-date-picker";
import {Channel} from "../../types";

type Props = {
  userRole?: string;
  campaignId: string;
  user: string;
  onSendCampaign: (comment?: string, sendingDate?: string) => Promise<void>;
  statusCanSend: boolean;
  statusCanApprove: boolean;
  statusCanRequestApproval: boolean;
  isSending: boolean;
  isErrorOnSend: boolean;
  formClass?: string;
  channel?: Channel;
};

export const SendCommentForm: React.FC<Props> = ({
  formClass,
  channel,
  userRole,
  campaignId,
  user,
  onSendCampaign,
  isSending,
  statusCanSend,
  statusCanApprove,
  statusCanRequestApproval,
  isErrorOnSend,
}) => {
  const { translate } = useTranslation();
  const { register, handleSubmit, errors, reset } = useForm();
  const { updateStatus } = useCampaignMutators();
  const { notifyApprovers } = useNotifyApprovers();
  const [campaignPlanning, setCampaignPlanning] = useState<boolean>(false);
  const [sendingDate, setSendingDate] = useState<string>();
  const { fetchedCampaignName } = useCampaign(campaignId, channel);

  let approver = "";
  if (userRole === "approver") {
    approver = user;
  }

  const statusUpdator = (status: CampaignStatus) => async ({ comment }: { comment?: string }) => {
    await updateStatus({ campaignId, user, status, comment, approver });
    if (status === "PENDING_APPROVAL") {
      await notifyApprovers(fetchedCampaignName, user, comment);
    }
    reset({ comment: "" });
  };

  const handleSend = async ({ comment }: { comment?: string }) => {
    await onSendCampaign(comment, sendingDate);
  };

  const handleCampaignPlanningChange = () => {
    setCampaignPlanning((campaignPlanning) => !campaignPlanning);
    handleDatePickerChange("");
  };

  const handleDatePickerChange = (date: string) => {
    setSendingDate(date);
  };

  return (
    <form className={cx(formClass)}>
      <div className="field">
        <textarea
          name="comment"
          defaultValue=""
          placeholder={translate("send.comment.placeholder")}
          ref={register({ required: true })}
          className="textarea"
          disabled={userRole === "approver" ? false : !statusCanRequestApproval}
        ></textarea>
      </div>

      <div className="field">
        <p className="help is-danger"> {errors.comment && <span>{translate("send.comment.error")}</span>}</p>
      </div>

      {isErrorOnSend && <GenericMessage translationKey="send.sending.error" themeColor="is-danger" />}

      {userRole === "approver" ? (
        <>
          <div className="field">
            <label className="checkbox">
              <input type="checkbox" onChange={handleCampaignPlanningChange} />{" "}
              {translate("send.comment.planify.sending")}
            </label>
          </div>

          {campaignPlanning && (
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">{translate("send.comment.sendingDate")} :</label>
              </div>
              <div className="field-body is-flex-grow-4">
                <div className="field">
                  <div className="control has-icons-right">
                    <CampaignDatePicker {...{ handleDatePickerChange }} />
                    <span className="icon is-small is-right">
                      <i className="fas fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!campaignPlanning ? (
            <div className="field level is-grouped is-grouped-multiline">
              <div className="control">
                <button
                  className="button is-danger is-right"
                  disabled={!statusCanSend || isSending}
                  onClick={handleSubmit(statusUpdator("REJECTED"))}
                >
                  <span>{translate("send.comment.button.reject")}</span>
                  <span className="icon">
                    <FontAwesome name="times" />
                  </span>
                </button>
              </div>
              <div className="control">
                <button
                  className="button is-info"
                  disabled={!statusCanApprove || isSending}
                  onClick={handleSubmit(statusUpdator("APPROVED"))}
                >
                  <span>{translate("send.comment.button.approve")}</span>
                  <span className="icon">
                    <FontAwesome name="check" />
                  </span>
                </button>
              </div>

              <div className="control">
                <button
                  className={cx("button", "is-primary", {
                    "is-loading": isSending,
                  })}
                  disabled={!statusCanSend || isSending}
                  onClick={handleSubmit(handleSend)}
                >
                  <span>
                    {statusCanApprove
                      ? translate("send.comment.button.approveAndSend")
                      : translate("send.comment.button.send")}
                  </span>
                  <span className="icon">
                    <FontAwesome name="paper-plane" />
                  </span>
                </button>
              </div>
            </div>
          ) : (
            <div className="field level is-grouped is-grouped-right">
              <div className="control">
                <button
                  className={cx("button", "is-primary", {
                    "is-loading": isSending,
                  })}
                  disabled={!statusCanSend || isSending}
                  onClick={handleSubmit(handleSend)}
                >
                  <span>{translate("send.comment.button.schedule")}</span>
                  <span className="icon">
                    <FontAwesome name="paper-plane" />
                  </span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="buttons field is-right">
          <button
            className={cx("button", "is-primary", {
              "is-loading": isSending,
            })}
            disabled={!statusCanRequestApproval || isSending}
            onClick={handleSubmit(statusUpdator("PENDING_APPROVAL"))}
          >
            <span>{translate("send.comment.button.approval")}</span>
            <span className="icon">
              <FontAwesome name="check" />
            </span>
          </button>
        </div>
      )}
    </form>
  );
};
