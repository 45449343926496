import React from "react";
import { Targeting } from "../../types";
import { useSegments } from "../../hooks/use-segments";
import { Loader } from "../loader";
import { useTranslation } from "../../hooks/use-translation";
import { useOperators } from "../../hooks/use-operators";
import { format } from "date-fns";

type Props = {
  targeting?: Targeting;
};

export const ReportingSummary: React.FC<Props> = ({ targeting }) => {
  const { translate } = useTranslation();

  const operators = useOperators();
  const { segmentStore, isLoading } = useSegments();

  if (isLoading) {
    return <Loader />;
  }

  const segments = segmentStore?.segments || [];

  return (
    <article className="message">
      {targeting ? (
        <div className="message-body">
          {targeting?.subscription && <p>{translate("reporting.summary.profiles.subscription")} : {targeting?.subscription}</p>}
          <p>{translate("reporting.summary.profiles")} :</p>
          <ul>
            {targeting?.segmentCriteria.map((criteria) => {
              return criteria.segmentNames.map((segmentName, index) => {
                const displayName =
                  segments.find((current) => current.technicalName === segmentName)?.displayName || segmentName;
                return <li key={index}>- {displayName}</li>;
              });
            })}
          </ul>
          <p>{translate("reporting.summary.fieldCriterias")} :</p>
          <ul>
            {targeting?.attributeCriteria.map(({ attributeName, operator, value }, index) => {
              return (
                <li key={index}>
                  - {attributeName} {operators[operator]} {value instanceof Date ? format(value, "MM-dd-yyyy") : value}
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <div className="message-body">
          <p>{translate("reporting.summary.noTargeting")}.</p>
        </div>
      )}
    </article>
  );
};
