import React, {useCallback, useContext, useMemo, useState} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {Link, RouteComponentProps, useNavigate} from "@reach/router";
import {Dashboard} from "../components/dashboard";
import {Loader} from "../components/loader";
import {Cell, CellProps, Column} from "react-table";
import {FontAwesome} from "../components/icons/font-awesome";
import {LoginContext} from "../context/login-context/login-context";
import {Campaign, CampaignStatus} from "../types/campaigns";
import {useCampaigns} from "../hooks/use-campaigns";
import {useTranslation} from "../hooks/use-translation";
import {useModal} from "../hooks/use-modal";
import {GenericMessage} from "../components/generic-message";
import {Channel, FinishedCampaigns} from "../types";
import styles from "./campaigns.module.scss";
import {useCampaignMutators} from "../hooks/use-campaign";
import {Modal} from "../components/modal/modal";
import {ButtonsModalFooter} from "../components/modal/footer-buttons-modal";
import {InputModal} from "../components/modal/input-modal";
import {useClientContext} from "../hooks/use-client-context";
import {APIError, apiGet} from "../api/api-calls";
import {useNotification} from "../hooks/use-notification";

const iconRegister: Record<Channel, { iconName: string; iconSize: string; iconVariant?: string }> = {
  SMS: {
    iconName: "mobile-alt",
    iconSize: "fa-lg",
  },
  EMAIL: {
    iconName: "envelope",
    iconSize: "fa-lg",
    iconVariant: "far",
  },
  "IN-DEFINITION": { iconName: "code", iconSize: "fa-lg" },
};

export const Campaigns: React.FC<RouteComponentProps> = () => {
  const { user, activeClub,language } = useContext(LoginContext);
  const { translate } = useTranslation();
  const {addNotification} = useNotification();
  const {clientContext} = useClientContext();
  const enabledTemplateWithActito = clientContext?.enabledTemplateWithActito;
  const { inProgressCampaigns, sentCampaigns, scheduledCampaigns, isLoading } = useCampaigns(activeClub || "");
  const { deleteCampaign, copyCampaign, reportingCampaign, cancel } = useCampaignMutators();
  const { isShown, toggleModal, modalConfiguration, setModalConfiguration } = useModal();

  const navigate = useNavigate();

  const inProgressData = useMemo<Campaign[]>(() => inProgressCampaigns || [], [inProgressCampaigns]);
  const scheduledData = useMemo<Campaign[]>(() => scheduledCampaigns || [], [scheduledCampaigns]);
  const sentData = useMemo<Campaign[]>(() => sentCampaigns || [], [sentCampaigns]);

  const [commonDashboardFilter, setCommonDashboardFilter] = useState<Channel | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState(false);

  const createHandleEditCampaign = useCallback(
    ({
      campaignId,
      channel = "EMAIL",
      status,
    }: {
      campaignId: string;
      channel: Channel;
      status: CampaignStatus;
    }) => () => {
      const url =
        status === "SCHEDULED"
          ? `/campaigns/edit-${channel}-campaign/${campaignId}/send`
          : `/campaigns/edit-${channel}-campaign/${campaignId}/customize`;


      apiGet<Campaign>(`/api/campaigns/${campaignId}?channel=${channel}`)
          .then(campaign => {
              apiGet<Campaign>(`/api/campaigns/${campaignId}/template?channel=${channel}`)
                  .then(template => navigate(url))
                  .catch(e => {
                      if (e.name !== "APIError") {
                          console.error("Unexpected error", e);
                      } else {
                          const apiError = e as APIError;
                          if (apiError.response.status === 404) {
                              addNotification({message: translate("notification.edit.error.delete.template"), props: {status: "error"}});
                          } else if (apiError.response.status === 400) {
                              const data = apiError.response.json();
                              if(data) {
                                  data.then(res => {
                                      if(res.error && res.error === "subject") {
                                          addNotification({message: translate("notification.edit.error.missing.subject"), props: {status: "error"}});
                                      }
                                  })
                              }
                          }
                      }
                  })
          })
          .catch((e) => {
              if (e.name !== "APIError") {
                  console.error("Unexpected error", e);
              } else {
                  const apiError = e as APIError;
                  if (apiError.response.status === 404) {
                      addNotification({message: translate("notification.edit.error.delete"), props: {status: "error"}});
                  }
              }
          });
    },
    [navigate, addNotification, translate]
  );


  const createHandleDeleteCampaign = useCallback(
    ({ campaignId }: { campaignId: string }) => () => {
      const handleDelete = () => {
        deleteCampaign({ campaignId });
        toggleModal();
      };
      setModalConfiguration({
        headerText: translate("modal.delete.header.text"),
        modalContent: translate("modal.delete.content.text"),
        modalFooter: <ButtonsModalFooter onConfirm={handleDelete} onCancel={toggleModal} />,
      });
      toggleModal();
    },
    [deleteCampaign, setModalConfiguration, toggleModal, translate]
  );

  const createHandleCancelCampaign = useCallback(
    ({ campaignId }: { campaignId: string }) => () => {
      const handleCancel = () => {
        cancel({ user: user || "", campaignId });
        toggleModal();
      };
      setModalConfiguration({
        headerText: translate("modal.cancel.header.text"),
        modalContent: translate("modal.cancel.content.text"),
        modalFooter: <ButtonsModalFooter onConfirm={handleCancel} onCancel={toggleModal} />,
      });
      toggleModal();
    },
    [cancel, setModalConfiguration, toggleModal, translate, user]
  );

  const createHandleDuplicateCampaign = useCallback(
    (original: Campaign) => () => {
        const { campaignId, name, scope } = original;
        apiGet<Campaign>(`/api/campaigns/${campaignId}?channel=${original.channel}`)
        .then(r => {
            apiGet<Campaign>(`/api/campaigns/${campaignId}/template?channel=${original.channel}`)
                .then(template => {
                    let newName = "";
                    const handleNewName = (inputText: string) => {
                        newName = inputText;
                    };

                    const handleCopy = () => {
                        if (!newName) return;
                        copyCampaign({ campaignId, user: user || "", newName, scope });
                        toggleModal();
                    };

                    setModalConfiguration({
                        headerText: translate("modal.copy.header.text"),
                        modalContent: (
                            <InputModal
                                onChange={(text) => handleNewName(text)}
                                placeholder={name}
                                label={translate("modal.copy.content.text")}
                            />
                        ),
                        modalFooter: <ButtonsModalFooter onConfirm={handleCopy} onCancel={toggleModal} />,
                    });
                    toggleModal();
                })
                .catch(e => {
                    if (e.name !== "APIError") {
                        console.error("Unexpected error", e);
                    } else {
                        const apiError = e as APIError;
                        if (apiError.response.status === 404) {
                            addNotification({message: translate("notification.edit.error.delete.template"), props: {status: "error"}});
                        } else if (apiError.response.status === 400) {
                            const data = apiError.response.json();
                            if(data) {
                                data.then(res => {
                                    if(res.error && res.error === "subject") {
                                        addNotification({message: translate("notification.edit.error.missing.subject"), props: {status: "error"}});
                                    }
                                })
                            }
                        }
                    }
                })
            }
        )
        .catch((e) => {
            if (e.name !== "APIError") {
                console.error("Unexpected error", e);
            } else {
                const apiError = e as APIError;
                if (apiError.response.status === 404) {
                    addNotification({message: translate("notification.edit.error.delete"), props: {status: "error"}});
                }
            }
        });
    },
    [copyCampaign, setModalConfiguration, toggleModal, translate, user, addNotification]
  );

  const createHandleReportingCampaign = useCallback(
      (original: Campaign) => () =>{
          setIsLoaded(true);
          const { actitoCampaignId,} = original;
          const campaignId = actitoCampaignId?.toString()!;
          reportingCampaign({ campaignId }).then(reportingCampaign => {
              setIsLoaded(false);
              const urlWithLanguage = `${reportingCampaign?.url!}?lg=${language}`;
              window.open(urlWithLanguage,"_blank");
          }).catch(error => setIsLoaded(false));
        },
        [reportingCampaign, setIsLoaded, language]
    );

  const columns = useMemo<Column<Campaign>[]>(
    () => [
      {
        Header: () => null,
        id: "expander",
        style: { width: 45, textAlign: "center" },
        Cell: ({ row }: Cell) => (
          <div {...row.getToggleRowExpandedProps()} style={{ textAlign: "center" }}>
            <FontAwesome name={row.isExpanded ? "angle-down" : "angle-right"} />
          </div>
        ),
      },
      {
        Header: translate("table.channel"),
        accessor: "channel",
        disableSortBy: true,
        disableFilters: true,
        style: { width: 60, textAlign: "center" },
        Cell: ({
          row: {
            original: { channel },
          },
        }) => {
          return (
            <FontAwesome
              name={iconRegister[channel].iconName}
              fontAwesomeSize={iconRegister[channel].iconSize}
              fontAwesomeVariant={iconRegister[channel].iconVariant}
            />
          );
        },
      },
      {
        Header: translate("table.campaignName"),
        accessor: "name",
        disableGlobalFilter: true,
        style: {
          width: "325px",
          maxWidth: "325px",
          textAlign: "center",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
        cellStyle: { textAlign: "left" },
        Cell: (props) => {
          return <span title={props.value}>{props.value}</span>;
        },
      },
      {
        Header: translate("table.createdAt"),
        accessor: "createdAt",
        style: { width: 150, textAlign: "center" },
        Cell: (props) => {
          const formatedDate = new Date(props.value).toLocaleString();
          return <span>{formatedDate}</span>;
        },
      },
      {
        Header: translate("table.updatedAt"),
        accessor: "updatedAt",
        style: { width: 150, textAlign: "center" },
        Cell: (props) => {
          if (!props.value) return <span />;
          const formatedDate = new Date(props.value).toLocaleString();
          return <span>{formatedDate}</span>;
        },
      },
      {
        Header: translate("table.scope"),
        accessor: "scope",
        disableFilters: true,
        style: { width: 100, textAlign: "center" },
      },
      {
        Header: translate("table.status"),
        accessor: "status",
        disableFilters: true,
        style: { width: 90, textAlign: "center" },
        Cell: (props) => {
          if (!props.value) return <span />;
          const translatedStatus = translate(`status.${props.value}`);
          return <span>{translatedStatus}</span>;
        },
      },
      {
        Header: translate("table.creator"),
        accessor: "creator",
        disableFilters: true,
        style: { width: 110, textAlign: "center" },
      },
      {
        Header: translate("table.approver"),
        accessor: "approver",
        disableFilters: true,
        style: { width: 110, textAlign: "center" },
      },
      {
        Header: "",
        accessor: "campaignId",
        disableSortBy: true,
        disableFilters: true,
        style: { textAlign: "center" },
        Cell: (cellProps) => {
          const { original } = cellProps.row;
          return (
              <div className={styles.toolbar}>
                  <div className={styles.buttonGroup} data-hover-flex="">
                      <button
                          data-test="edit-button"
                          className={`is-small button is-primary is-outlined ${styles.button}`}
                          onClick={createHandleEditCampaign(original)}
                      >
                          {translate("dashboard.toolbar.button.edit")}
                      </button>
                      <button
                          data-test="duplicate-button"
                          className={`is-small button is-success is-outlined ${styles.button}`}
                          onClick={createHandleDuplicateCampaign(original)}
                      >
                          {translate("dashboard.toolbar.button.copy")}
                      </button>
                      <button
                          data-test="delete-button"
                          className={`is-small button is-danger is-outlined ${styles.button}`}
                          onClick={createHandleDeleteCampaign(original)}
                      >
                          {translate("dashboard.toolbar.button.delete")}
                      </button>
                  </div>
                  <span className="button is-small" data-hover-hide="">
                    {translate("dashboard.toolbar.button.manage")}
                  </span>
            </div>
          );
        },
      },
    ],
    [createHandleDeleteCampaign, createHandleDuplicateCampaign, createHandleEditCampaign, translate]
  );

  const scheduledColumns = useMemo<Column<Campaign>[]>(
    () => [
      {
        Header: () => null,
        id: "expander",
        style: { width: 45, textAlign: "center" },
        Cell: ({ row }: Cell) => (
          <div {...row.getToggleRowExpandedProps()} style={{ textAlign: "center" }}>
            <FontAwesome name={row.isExpanded ? "angle-down" : "angle-right"} />
          </div>
        ),
      },
      {
        Header: translate("table.channel"),
        accessor: "channel",
        disableSortBy: true,
        disableFilters: true,
        style: { width: 60, textAlign: "center" },
        Cell: ({
          row: {
            original: { channel },
          },
        }) => {
          return (
            <FontAwesome
              name={iconRegister[channel].iconName}
              fontAwesomeSize={iconRegister[channel].iconSize}
              fontAwesomeVariant={iconRegister[channel].iconVariant}
            />
          );
        },
      },
      {
        Header: translate("table.campaignName"),
        accessor: "name",
        disableGlobalFilter: true,
        style: {
          width: "325px",
          maxWidth: "325px",
          textAlign: "center",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
        cellStyle: { textAlign: "left" },
        Cell: (props) => {
          return <span title={props.value}>{props.value}</span>;
        },
      },
      {
        Header: translate("table.createdAt"),
        accessor: "createdAt",
        style: { width: 150, textAlign: "center" },
        Cell: (props) => {
          const formatedDate = new Date(props.value).toLocaleString();
          return <span>{formatedDate}</span>;
        },
      },
      {
        Header: translate("table.updatedAt"),
        accessor: "updatedAt",
        style: { width: 150, textAlign: "center" },
        Cell: (props) => {
          if (!props.value) return <span />;
          const formatedDate = new Date(props.value).toLocaleString();
          return <span>{formatedDate}</span>;
        },
      },
      // {
      //   Header: translate("table.scheduledAt"),
      //   accessor: "scheduleDate",
      //   style: { width: 150, textAlign: "center" },
      //   Cell: (props) => {
      //     if (!props.value) return <span />;
      //     const formatedDate = new Date(props.value).toLocaleString();
      //     return { formatedDate };
      //   },
      // },
      {
        Header: translate("table.scope"),
        accessor: "scope",
        disableFilters: true,
        style: { width: 100, textAlign: "center" },
      },
      {
        Header: translate("table.creator"),
        accessor: "creator",
        disableFilters: true,
        style: { width: 110, textAlign: "center" },
      },
      {
        Header: translate("table.approver"),
        accessor: "approver",
        disableFilters: true,
        style: { width: 110, textAlign: "center" },
      },
      {
        Header: "",
        accessor: "campaignId",
        disableSortBy: true,
        disableFilters: true,
        style: { textAlign: "center" },
        Cell: (cellProps) => {
          const { original } = cellProps.row;
          return (
            <div className={styles.toolbar}>
              <div className={styles.buttonGroup} data-hover-flex="">
                  {enabledTemplateWithActito && original.channel === "EMAIL" ?
                      original.campaignType?.includes("ACTITO") && (
                          <>
                              <button
                                  data-test="edit-button"
                                  className={`is-small button is-primary is-outlined ${styles.button}`}
                                  onClick={createHandleEditCampaign(original)}
                              >
                                  {translate("dashboard.toolbar.button.edit")}
                              </button>
                              <button
                                  data-test="duplicate-button"
                                  className={`is-small button is-success is-outlined ${styles.button}`}
                                  onClick={createHandleDuplicateCampaign(original)}
                              >
                                  {translate("dashboard.toolbar.button.copy")}
                              </button>
                          </>
                      ) :
                      <>
                          <button
                              data-test="edit-button"
                              className={`is-small button is-primary is-outlined ${styles.button}`}
                              onClick={createHandleEditCampaign(original)}
                          >
                              {translate("dashboard.toolbar.button.edit")}
                          </button>
                          <button
                              data-test="duplicate-button"
                              className={`is-small button is-success is-outlined ${styles.button}`}
                              onClick={createHandleDuplicateCampaign(original)}
                          >
                              {translate("dashboard.toolbar.button.copy")}
                          </button>
                      </>
                  }
                  <button
                      data-test="cancel-button"
                      className={`is-small button is-danger is-outlined ${styles.button}`}
                      onClick={createHandleCancelCampaign(original)}
                  >
                      {translate("dashboard.toolbar.button.cancel")}
                  </button>
              </div>
                <span className="button is-small" data-hover-hide="">
                {translate("dashboard.toolbar.button.manage")}
              </span>
            </div>
          );
        },
      },
    ],
    [createHandleCancelCampaign, createHandleDuplicateCampaign, createHandleEditCampaign, translate, enabledTemplateWithActito]
  );

  const emailColumnsReporting = React.useMemo<Column<Campaign>[]>(
    () => [
      {
        Header: () => null,
        id: "expander",
        style: { width: 45, textAlign: "center" },
        Cell: ({ row }: Cell) => (
          <div {...row.getToggleRowExpandedProps()} style={{ textAlign: "center" }}>
            <FontAwesome name={row.isExpanded ? "angle-down" : "angle-right"} />
          </div>
        ),
      },
      {
        Header: translate("table.channel"),
        accessor: "channel",
        disableSortBy: true,
        disableFilters: true,
        style: { width: 60, textAlign: "center" },
        Cell: ({
          row: {
            original: { channel },
          },
        }) => {
          return (
            <FontAwesome
              name={iconRegister[channel].iconName}
              fontAwesomeSize={iconRegister[channel].iconSize}
              fontAwesomeVariant={iconRegister[channel].iconVariant}
            />
          );
        },
      },
      {
        Header: translate("table.campaignName"),
        accessor: "name",
        disableGlobalFilter: true,
        style: {
          width: "325px",
          maxWidth: "325px",
          textAlign: "center",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
        cellStyle: { textAlign: "left" },
        Cell: (props) => {
          return <span title={props.value}>{props.value}</span>;
        },
      },
      {
        Header: translate("reporting.finishedOn"),
        accessor: (row) => row.actitoCampaignData?.endedAt,
        disableFilters: true,
        Cell: (props: React.PropsWithChildren<CellProps<FinishedCampaigns, string>>) => {
          if (!props.row.original.actitoCampaignData) {
            return <h4>No data</h4>;
          }
          const formatedDate = !props.value ? "" : new Date(props.value).toLocaleString();
          return <span>{formatedDate}</span>;
        },
      },
      {
        Header: translate("reporting.sent"),
        accessor: (row) => row.actitoCampaignData?.report.sent,
        disableFilters: true,
        Cell: (props: React.PropsWithChildren<CellProps<FinishedCampaigns, string>>) => {
          if (!props.row.original.actitoCampaignData) {
            return <h4>No data</h4>;
          }
          return props.value;
        },
      },
      {
        Header: translate("reporting.delivered"),
        accessor: (row) => row.actitoCampaignData?.report.delivered,
        disableFilters: true,
        Cell: (props: React.PropsWithChildren<CellProps<FinishedCampaigns, string>>) => {
          if (!props.row.original.actitoCampaignData) {
            return <h4>No data</h4>;
          }
          return props.value;
        },
      },
      {
        Header: translate("reporting.openingRate"),
        accessor: (row) => row.actitoCampaignData?.report.opened,
        disableFilters: true,
        Cell: (props: React.PropsWithChildren<CellProps<FinishedCampaigns, string>>) => {
          if (!props.row.original.actitoCampaignData) {
            return <h4>No data</h4>;
          }
          const {
            row: {
              original: {
                actitoCampaignData: {
                  report: { opened, sent },
                },
              },
            },
          } = props;
          let ratio = (opened / sent) * 100;
          if (isNaN(ratio)) {
            ratio = 0;
          }
          return <>{ratio.toFixed(2)}%</>;
        },
      },
      {
        Header: translate("reporting.ratio.clickedOpened"),
        id: "ratioClickedOpened",
        disableFilters: true,
        Cell: (props: React.PropsWithChildren<CellProps<FinishedCampaigns, string>>) => {
          if (!props.row.original.actitoCampaignData) {
            return <h4>No data</h4>;
          }
          const {
            row: {
              original: {
                actitoCampaignData: {
                  report: { clicked, opened },
                },
              },
            },
          } = props;
          let ratio = (clicked / opened) * 100;
          if (isNaN(ratio)) {
            ratio = 0;
          }
          return <>{ratio.toFixed(2)}%</>;
        },
      },
      {
        Header: translate("reporting.ratio.clickedDelivered"),
        id: "ratioClickedDelivered",
        disableFilters: true,
        Cell: (props: React.PropsWithChildren<CellProps<FinishedCampaigns, string>>) => {
          if (!props.row.original.actitoCampaignData) {
            return <h4>No data</h4>;
          }
          const {
            row: {
              original: {
                actitoCampaignData: {
                  report: { clicked, delivered },
                },
              },
            },
          } = props;
          let ratio = (clicked / delivered) * 100;
          if (isNaN(ratio)) {
            ratio = 0;
          }
          return <>{ratio.toFixed(2)}%</>;
        },
      },
      {
        Header: "",
        accessor: "campaignId",
        disableSortBy: true,
        disableFilters: true,
        style: { textAlign: "center" },
        Cell: (cellProps) => {
          const { original } = cellProps.row;
          return (
            <div className={styles.toolbar}>
                <div className={styles.buttonGroup} data-hover-flex="">
                    {enabledTemplateWithActito && original.channel === "EMAIL" ?
                        original.campaignType?.includes("ACTITO") &&
                        (
                            <button
                                data-test="duplicate-button"
                                className={`is-small button is-success is-outlined ${styles.button}`}
                                onClick={createHandleDuplicateCampaign(original)}
                            >
                                {translate("dashboard.toolbar.button.copy")}
                            </button>
                        ) :
                        <button
                            data-test="duplicate-button"
                            className={`is-small button is-success is-outlined ${styles.button}`}
                            onClick={createHandleDuplicateCampaign(original)}
                        >
                            {translate("dashboard.toolbar.button.copy")}
                        </button>
                    }
                    {isLoaded && <FontAwesome name={"spinner"} pulse/>}
                    {original.channel === "EMAIL" &&
                      <button
                        data-test="reporting-button"
                        className={`is-small button is-success is-outlined ${styles.button}`}
                        onClick={createHandleReportingCampaign(original)}
                      >
                          {translate("dashboard.toolbar.button.reporting")}
                      </button>
                    }

                </div>
              <span className="button is-small" data-hover-hide="">
                {translate("dashboard.toolbar.button.manage")}
              </span>
            </div>
          );
        },
      },
    ],
    [createHandleDuplicateCampaign, isLoaded, createHandleReportingCampaign ,translate, enabledTemplateWithActito]
  );

  const handleNewCampaign = () => {
    navigate("/campaigns/new-campaign");
  };

  if (!activeClub) {
    return <GenericMessage translationKey="dashboard.error.noScope" />;
  }

  const tabs: Record<string, { key: string; content: string | JSX.Element }> = {
    pending: {
      key: "dashboard.pending",
      content: (
        <Dashboard
          columns={columns}
          data={inProgressData}
          dashboardType="pending"
          {...{ commonDashboardFilter, setCommonDashboardFilter }}
        />
      ),
    },
    scheduled: {
      key: "dashboard.scheduled",
      content: (
        <Dashboard
          columns={scheduledColumns}
          data={scheduledData}
          dashboardType="scheduled"
          {...{ commonDashboardFilter, setCommonDashboardFilter }}
        />
      ),
    },
    sent: {
      key: "dashboard.sent",
      content: (
        <Dashboard
          columns={emailColumnsReporting}
          data={sentData}
          dashboardType="sent"
          {...{ commonDashboardFilter, setCommonDashboardFilter }}
        />
      ),
    },
  };

  return (
    <>
      <div className="box is-shadowless">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <h1 className="title is-4">{translate("dashboard.title")}</h1>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item">
              <Link to="/campaigns/new-campaign/">
                <button className="button is-primary" onClick={handleNewCampaign}>
                  {translate("dashboard.button")}
                </button>
              </Link>
            </div>
          </div>
        </div>
        <Tabs className="tabs" selectedTabClassName="is-active" selectedTabPanelClassName="mt-5">
          <TabList>
            {Object.keys(tabs).map((tab, index) => {
              return (
                <Tab key={index}>
                  <p>{translate(tabs[tab].key)}</p>
                </Tab>
              );
            })}
          </TabList>
          {Object.keys(tabs).map((tab, index) => {
            return <TabPanel key={index}>{isLoading ? <Loader /> : tabs[tab].content}</TabPanel>;
          })}
        </Tabs>
      </div>
      <Modal
        isShown={isShown}
        hide={toggleModal}
        headerText={modalConfiguration?.headerText}
        modalContent={modalConfiguration?.modalContent}
        modalFooter={modalConfiguration?.modalFooter}
      />
    </>
  );
};
