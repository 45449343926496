import { createContext } from "react";
import { Channel } from "../types";

export interface IWizardContext {
  channel: Channel;

  templateId?: string;
  setChannel: (channel: Channel) => void;
}

export const WizardContext = createContext<IWizardContext>({
  channel: "EMAIL",
  templateId: "",
  setChannel: (channel: Channel) => {}
});
