import React from "react";
import { useTemplates } from "../../hooks/use-templates";
import { Channel } from "../../types";
import TemplateCard from "../card";
import { Loader } from "../loader";

type Props = {
  currentTemplateId?: string;
  onTemplateSelect: (templateName: string) => void;
  channel: Channel;
  isSelectionDisabled?: boolean,
  setIsSelectionDisabled: (value: boolean) => void;
};

export const TemplateList: React.FC<Props> = ({ currentTemplateId, onTemplateSelect, channel, isSelectionDisabled, setIsSelectionDisabled }) => {
  const { fetchedTemplates, isLoading } = useTemplates(channel);

  const sortedTemplates = [...Object.values(fetchedTemplates.templates)].sort(function (a, b) {
    if (a.displayOrder > b.displayOrder) return 1;
    if (a.displayOrder < b.displayOrder) return -1;

    if (a.technicalName > b.technicalName) return 1;
    if (a.technicalName < b.technicalName) return -1;
    return 0;
  });

  if (isLoading) return <Loader />;

  return (
    <>
      {sortedTemplates.map(
        ({ templateId, activated, displayName, description, previewImageUrl, acceptedSubscriptions, isSubjectPresent }, index) => (
          <TemplateCard
            key={index}
            templateId={templateId}
            selected={templateId === currentTemplateId}
            onSelect={onTemplateSelect}
            disabled={currentTemplateId !== undefined}
            isSubjectPresent={isSubjectPresent}
            previewImageUrl={previewImageUrl === null ?"":previewImageUrl}
            {...{ activated, description, displayName, acceptedSubscriptions }}
            isSelectionDisabled={isSelectionDisabled}
            setIsSelectionDisabled={setIsSelectionDisabled}
          />
        )
      )}
    </>
  );
};
