import React from "react";
import { useTranslation } from "../hooks/use-translation";
import { FontAwesome } from "./icons/font-awesome";
import styles from "./generic-message.module.scss";
import cx from "classnames";

type Props = {
  translationKey?: string;
  iconName?: string;
  themeColor?: string;
  messageSize?: string;
  fontAwesomeSize?: string;
  headerTranslationKey?: string;
  headerIcon?: boolean;
  headerAdditionalContent?: string;
  bodyIcon?: boolean;
};

export const GenericMessage: React.FC<Props> = ({
  translationKey,
  iconName,
  themeColor = "",
  messageSize = "",
  fontAwesomeSize = "",
  headerTranslationKey,
  headerIcon,
  headerAdditionalContent,
  bodyIcon,
  children,
}) => {
  const { translate } = useTranslation();
  return (
    <article
      className={cx("message", {
        [themeColor]: themeColor,
        [messageSize]: messageSize,
      })}
    >
      {headerTranslationKey && (
        <div className="message-header">
          <p>
            {headerIcon && iconName && <FontAwesome name={iconName} />} {translate(`${headerTranslationKey}`)}
            {headerAdditionalContent}
          </p>
        </div>
      )}
      <div
        className={cx("message-body", {
          [styles.messageBody]: iconName,
        })}
      >
        {bodyIcon && iconName && <FontAwesome name={iconName} fontAwesomeSize={fontAwesomeSize} />}
        <div className="is-flex-grow-1">
          {translationKey && <p>{translate(`${translationKey}`)} </p>}
          {children}
        </div>
      </div>
    </article>
  );
};
