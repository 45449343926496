import React from "react";
import BynderIcon from "./icons/bynder-icon";

type Props = {
  setDmaName?: (name: string) => void;
  name: string;
};

export const BynderButton: React.FC<Props> = ({ setDmaName, name }) => {
  const handleBynderClick = () => {
    if (!setDmaName) {
      throw new Error("Unexpected error : setDmaName should be defined for a bynder input");
    }

    // @ts-ignore
    window.BynderCompactView.open(window.BynderCompactViewConfig);

    if (!name) return;
    setDmaName(name);
  };
  return (
    <div className="control">
      <button className="button is-primary" onClick={handleBynderClick}>
        <BynderIcon />
      </button>
    </div>
  );
};
