import { useQuery } from "react-query";
import { apiGet } from "../api/api-calls";
import { Campaign } from "../types/campaigns";

type IResult = { campaigns: Campaign[] };

type DashboardDataReturn = {
  sentCampaigns?: Campaign[];
  inProgressCampaigns?: Campaign[];
  scheduledCampaigns?: Campaign[];
  isLoading: boolean;
};

const FIVE_MINUTES_IN_MILLISECONDS = 300000;

export function useCampaigns(club: string): DashboardDataReturn {

  const { campaigns: inProgressCampaigns, isLoading: isLoadingInProgress } = useCampaignQuery(club, "DRAFT");
  const { campaigns: scheduledCampaigns, isLoading: isLoadingScheduled } = useCampaignQuery(club, "SCHEDULED");
  const { campaigns: sentCampaigns, isLoading: isLoadingSent } = useCampaignQuery(club, "SENT");

  const isLoading = isLoadingInProgress || isLoadingScheduled || isLoadingSent;

  return { inProgressCampaigns, sentCampaigns, scheduledCampaigns, isLoading };
}

function useCampaignQuery(club: string, status: string) {
  const { data, isLoading} = useQuery(["campaigns", club, status], campaignsFetcher, {
    staleTime: 0,
    refetchInterval: FIVE_MINUTES_IN_MILLISECONDS,
    enabled: club,
    refetchOnWindowFocus: false,
  });
  const campaigns = data?.campaigns || []
  return { campaigns, isLoading };
}

async function campaignsFetcher(_key: string, club: string, status: string) {
  return await apiGet<IResult>(`/api/campaigns?scope=${club}&status=${status}`);
}
