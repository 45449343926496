import React, { useContext, useState } from "react";
import { parseISO } from "date-fns";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import enGB from "date-fns/locale/en-GB";
import { LoginContext } from "../../context/login-context/login-context";

registerLocale("fr", fr);
registerLocale("en", enGB);

type DatePickerProps = {
  defaultValue?: string;
  handleDatePickerChange: (date: string) => void;
};

export const CampaignDatePicker: React.FC<DatePickerProps> = ({ defaultValue, handleDatePickerChange }) => {
  const { language } = useContext(LoginContext);
  const [date, setDate] = useState(defaultValue ? parseISO(defaultValue) || null : null);

  const handleNewDate = (dateValue: Date | null) => {
    setDate(dateValue);

    const newDate = dateValue ? dateValue.toISOString() : undefined;
    handleDatePickerChange(newDate || "");
  };

  return (
    <ReactDatePicker
      wrapperClassName="is-flex"
      className="input"
      selected={date}
      onChange={handleNewDate}
      showTimeSelect
      timeFormat="p"
      timeIntervals={15}
      dateFormat="Pp"
      minDate={new Date()}
      showDisabledMonthNavigation
      locale={language}
    />
  );
};
