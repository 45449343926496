import React, { useState } from "react";
import { Link, RouteComponentProps } from "@reach/router";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import { unsafeApiPatch } from "../api/api-calls";
import { useMatch } from "@reach/router";
import { GenericMessage } from "../components/generic-message";
import { useTranslation } from "../hooks/use-translation";
import cx from "classnames";

export type Props = {
  userId?: string;
  securityCode?: string;
};

export const ResetPassword: React.FC<RouteComponentProps & Props> = ({ userId, securityCode }) => {
  const { translate } = useTranslation();

  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .required(translate("resetPassword.password.requiered"))
      .min(8, translate("resetPassword.password.length")),
    confirmPassword: yup
      .string()
      .required(translate("resetPassword.password.requiered"))
      .oneOf([yup.ref("newPassword")], translate("resetPassword.password.match")),
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [feedbackState, setFeedbackState] = useState<string>("");

  const onSubmit = async (data: { [string: string]: string }) => {
    const { confirmPassword } = data;
    const newPassword = { uuid: securityCode, password: confirmPassword };
    try {
      await unsafeApiPatch(`/api/generate-new-password/${userId}`, newPassword);
      setFeedbackState("success");
    } catch (e) {
      setFeedbackState("error");
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((oldIsVisible) => !oldIsVisible);
  };

  const matchPath = useMatch("/password/reset/:userId/:securityCode");

  return (
    <div className="column is-half-desktop is-three-quarters mx-auto">
      <div className="panel is-primary ">
        <p className="panel-heading ">{matchPath ? "Reset your password" : "Set your password"}</p>
        <div>
          <form className="box is-shadowless" onSubmit={handleSubmit(onSubmit)}>
            <div className="field">
              <label className="label" htmlFor="newPassword">
                {translate("activate.password")}
              </label>
              <div className="control has-icons-right">
                <input
                  className="input"
                  type={isPasswordVisible ? "text" : "password"}
                  name="newPassword"
                  id="newPassword"
                  ref={register}
                />
                <span className="icon is-small is-right pointer-events" onClick={togglePasswordVisibility}>
                  <i
                    className={cx("fas", "fa-lg", {
                      "fa-eye-slash": isPasswordVisible,
                      "fa-eye": !isPasswordVisible,
                    })}
                  ></i>
                </span>
              </div>
            </div>
            <p className="has-text-danger mb-3">{errors.newPassword?.message}</p>
            <div className="field">
              <label className="label" htmlFor="confirmPassword">
                {translate("activate.password.confirmation")}
              </label>
              <div className="control has-icons-right">
                <input
                  className="input"
                  type={isPasswordVisible ? "text" : "password"}
                  name="confirmPassword"
                  id="confirmPassword"
                  ref={register}
                />
                <span className="icon is-small is-right pointer-events" onClick={togglePasswordVisibility}>
                  <i
                    className={cx("fas", "fa-lg", {
                      "fa-eye-slash": isPasswordVisible,
                      "fa-eye": !isPasswordVisible,
                    })}
                  ></i>
                </span>
              </div>
            </div>
            <p className="has-text-danger mb-3">{errors.confirmPassword?.message}</p>
            <div className="field">
              <div className="control has-text-right">
                <button type="submit" className="button is-primary" onClick={handleSubmit(onSubmit)}>
                  {translate("submit")}
                </button>
              </div>
            </div>
            {feedbackState === "success" && (
              <>
                <p className="is-size-6 has-text-centered has-text-weight-bold">
                  {translate("activate.success")} {matchPath ? translate("general.reset") : translate("general.set")}.
                </p>
                <p className="is-size-6 has-text-centered has-text-weight-bold">
                  <Link to="/">{translate("general.backToHomepage")}</Link>
                </p>
              </>
            )}
            {feedbackState === "error" && <GenericMessage translationKey="general.error" />}
          </form>
        </div>
      </div>
    </div>
  );
};
