import React, { useContext } from "react";
import { WizardContext } from "../context/wizard-context";
import { Substitutions } from "../types";
import { EmailPreview } from "./email-preview";
import { MessagePreview } from "./sms/message-preview";

type Props = {
  templateId?: string; // Provided by navigation
  substitutions?: Substitutions;
  campaignId?: string,
};

export const WithPreview: React.FC<Props> = ({ templateId = "", substitutions, campaignId, children }) => {
  const { channel } = useContext(WizardContext);
  return (
    <div className="tile is-ancestor is-main-height">
      <div className="tile is-6 is-parent wizard-left-container">
        <div className="tile is-child box is-shadowless">{children}</div>
      </div>
      <div className="tile is-6 is-parent">
        <div className="tile is-child box is-shadowless">
          <div className="is-sticky">
            {channel === "EMAIL" ? <EmailPreview {...{ substitutions, templateId, campaignId }} /> : <MessagePreview />}
          </div>
        </div>
      </div>
    </div>
  );
};
