import { useQuery } from "react-query";
import {
  TemplateFieldsConfiguration,
  generateDefaultValues,
  generateVariableNames,
} from "../helpers/generate-default-values";
import { Substitutions } from "../types";
import { useTemplate } from "./use-templates";

type TemplateConfiguration = {
  templateCustomizations?: TemplateFieldsConfiguration;
  substitutions: Substitutions;
  variableNames: string[];
  isLoading: boolean;
};

const emptyTemplateConfig: TemplateConfiguration = {
  substitutions: {},
  variableNames: [],
  isLoading: true,
};

export function useTemplateFormConfigurationForSMS(templateId?: string,channel?: string): TemplateConfiguration {
  const { template, isLoading } = useTemplate(templateId);
  const { data } = useQuery(["templateCustomizationsForSMS",template?.customizationsUrl], templateFormConfigFetcher, {
    enabled: channel === "SMS" && templateId,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
  if (!template || !data) {
    return emptyTemplateConfig;
  } else {
    return { ...data, isLoading };
  }

  async function templateFormConfigFetcher(_: string, url: string) {
    if (!url || !template) throw new Error("Unexpected: canot fetch template configuration");
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Could not retrieve template customizations");
    }
    const templateCustomizations: TemplateFieldsConfiguration = await response.json();

    const { subject, replyTo } = template;

    return {
      templateCustomizations,
      substitutions: { subject, replyTo, ...generateDefaultValues(templateCustomizations) },
      variableNames: generateVariableNames(templateCustomizations),
    };
  }

}
