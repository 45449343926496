import React, {useContext, useEffect} from "react";
import { useForm, Controller } from "react-hook-form";
import { CriteriaListEditor } from "../components/criteria-list-editor";
import { ProfileListCriteria } from "../components/profile-list-criteria";
import { SegmentStore, FormValues, Targeting } from "../types";
import { targetingToFormValues } from "../helpers/targeting-to-formvalues";
import { generateNewTargeting } from "../helpers/generate-new-targeting";
import { Loader } from "../components/loader";
import { useTargetingMutator, useTargetingResolutionFromCampaign } from "../hooks/use-targeting";
import { useSegments } from "../hooks/use-segments";
import { useAttributes } from "../hooks/use-attributes";
import { useTranslation } from "../hooks/use-translation";
import { GenericMessage } from "../components/generic-message";
import { LoginContext } from "../context/login-context/login-context";
import { useCampaignMutators } from "../hooks/use-campaign";
import { usePostEventValidation } from "../hooks/use-post-event-validation";
import {useTemplate} from "../hooks/use-templates";
import styles from "./customize.module.scss";

export type Props = {
  campaignId?: string;
  segments?: SegmentStore;
  setSegments: (result: SegmentStore) => void;
  targeting?: Targeting;
  count?: "unknown" | number;
  templateId?: string;
  subscription?: string;
};

export const
    TargetingEditor: React.FC<Props> = ({ campaignId, targeting, templateId, count }) => {
      const { user } = useContext(LoginContext);
      const { translate } = useTranslation();
      const { control, register, handleSubmit, formState, reset } = useForm<FormValues>();
      const { segmentStore, isLoading } = useSegments();
      const { isDirty } = formState;

      const { handleNewTargeting, targetingMutatorError } = useTargetingMutator(campaignId);
      const { isFetching } = useTargetingResolutionFromCampaign(campaignId);

      const { fetchedAttributes, isLoading: isAttributesLoading } = useAttributes();
      const { updateStatus } = useCampaignMutators();
      const { validatePostEvent } = usePostEventValidation(campaignId);
      const { template } = useTemplate(templateId,campaignId);

      useEffect(() => {
        if (targeting === undefined) return;

        if (!isAttributesLoading) {
          if (segmentStore !== undefined) {
            const formValues = targetingToFormValues(segmentStore, targeting, fetchedAttributes);
            reset(formValues);
          }
        }
      }, [targeting, segmentStore, reset, isAttributesLoading, fetchedAttributes]);

      if (isLoading || targeting === undefined) {
        return <Loader />;
      }

      const onSubmit = (data: FormValues) => {
        handleNewTargeting(generateNewTargeting(targeting, data));
        if (!campaignId) return;
        updateStatus({
          campaignId,
          user: user || "",
          status: "DRAFT",
          shouldPostEvent: validatePostEvent("targeting.event"),
          comment: `${translate("targeting.event")}`,
        });
      };

      return (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={`${styles.previewHeader} level is-sticky`} style={{height: "40px"}}>
                <div className="level-left">
                  <div className="level-item">
                    <h1 className="title is-4">{translate("wizard.target.title")}</h1>
                  </div>
                </div>
                <div className="level-right">
                  <div className="level-item">
                    <div className="field is-grouped is-grouped-right">
                      <div className="field level">
                        {!isFetching && (
                            <button disabled={!isDirty} className="button is-primary" type="submit">
                              {translate("wizard.target.button.calculate")}
                            </button>
                        )}
                        {isFetching && (
                            <button className="button">
                              {translate("wizard.target.button.calculating")}&nbsp;
                              <Loader isSmall />
                            </button>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{backgroundColor:"orange", padding:"10px", borderRadius:"4px"}}>
                  <span style={{fontWeight:"bold"}}>{translate("wizard.target.warning.message1")}</span><br />
                {translate("wizard.target.warning.message2")}
              </div>
              <hr />
              <div className="field level">
                <p>{translate("wizard.target.selectedProfiles")} :</p>
                {!isDirty && !isFetching && (
                    <p>
                      <span data-testid="targeting-count">{count}</span> {translate("general.profiles")}
                    </p>
                )}
              </div>
              { template && template.acceptedSubscriptions && template.acceptedSubscriptions.length > 1 &&
                  <>
                    <h2 className="field has-text-weight-bold">{translate("wizard.target.subscription")} :</h2>
                    <div key={"subscription"} className="field">
                      <div className="control select">
                        <select data-test="subscription-picker" name={"subscription"} ref={register}>
                          {template.acceptedSubscriptions?.map(value => <option value={value}>{value}</option>)}
                        </select>
                      </div>
                    </div>
                    <hr />
                  </>
              }
              <h2 className="field has-text-weight-bold">{translate("wizard.target.segments")} :</h2>
              {isLoading ? <Loader /> : <ProfileListCriteria register={register} segments={segmentStore?.segments || []} />}
              <hr />
              <h2 className="field has-text-weight-bold">{translate("wizard.target.criteria")} :</h2>
              <Controller
                  control={control}
                  name="attributeCriteria"
                  defaultValue={[]}
                  render={({ value, onChange }) => {
                    return <CriteriaListEditor {...{ value, onChange, isAttributesLoading, fetchedAttributes }} />;
                  }}
              />
              {targetingMutatorError && <GenericMessage translationKey="targeting.calculate.error" />}
            </form>
          </>
      );
    };
