import React from "react";
import { FontAwesome } from "./icons/font-awesome";

type IPagination = {
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  previousPage: () => void;
  canPreviousPage: boolean;
  nextPage: () => void;
  canNextPage: boolean;
  pageIndex: number;
};

export const Pagination: React.FC<IPagination> = ({
  pageSize,
  setPageSize,
  previousPage,
  canPreviousPage,
  nextPage,
  canNextPage,
  pageIndex,
}) => {
  return (
    <div className="pagination is-small is-right">
      <div className="select">
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </div>
      <div className="pagination-list">
        <button onClick={() => previousPage()} disabled={!canPreviousPage} className="pagination-link">
          <FontAwesome name="arrow-left" />
        </button>
        <span className="pagination-link is-current">Page {pageIndex + 1}</span>
        <button onClick={() => nextPage()} disabled={!canNextPage} className="pagination-link">
          <FontAwesome name="arrow-right" />
        </button>
      </div>
    </div>
  );
};
