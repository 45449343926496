import React, { FC, useState } from "react";

interface InputModalProps {
  onChange: (newName: string) => void;
  placeholder: string;
  label: string;
}
export const InputModal: FC<InputModalProps> = ({ onChange, placeholder, label }) => {
  const [inputValue, setInputValue] = useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <>
      <div className="field">
        <label className="label">{label}</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder={placeholder}
            onChange={handleInputChange}
            value={inputValue}
          />
        </div>
      </div>
    </>
  );
};
