import { FetchedAttributes } from "../components/criteria-list-editor";
import { SegmentStore, Targeting, FormValues } from "../types";

export function targetingToFormValues(
  segments: SegmentStore,
  targeting: Targeting,
  fetchedAttributes: FetchedAttributes,
): FormValues {
  const result: FormValues = {
    selectedLists: {},
    attributeCriteria: targeting.attributeCriteria,
    subscription: targeting.subscription
  };

  const some = targeting.segmentCriteria.find((criterion) => criterion.operator === "SOME");
  const someLists = some ? some.segmentNames : [];

  const allListTechnicalNames: string[] = segments.segments.map(({ technicalName }) => technicalName);

  for (const listName of allListTechnicalNames) {
    result.selectedLists[listName] = someLists.includes(listName);
  }

  return result;
}
