import React, { FC } from "react";
import useCookie from "react-use-cookie";
import { useModal } from "../hooks/use-modal";
import { Modal } from "./modal/modal";
import { useSplashMessage } from "../hooks/use-splash-message";
import { useTranslation } from "../hooks/use-translation";

type Props = {
  user?: string;
};

export const SplashMessage: FC<Props> = () => {
  const { toggleModal } = useModal();
  const { splashMessage } = useSplashMessage();
  const [storedSplashChecksum, setStoredSplashChecksum] = useCookie("storedSplashChecksum", "0");
  const { translate } = useTranslation();

  if (!splashMessage.checksum) {
    return null;
  }

  const handleModalClose = () => {
    setStoredSplashChecksum(splashMessage.checksum.toString());
    toggleModal();
  };

  const modaleVisibility = storedSplashChecksum !== splashMessage.checksum.toString() ? true : false;

  return (
    <>
      <Modal
        isShown={modaleVisibility}
        hide={handleModalClose}
        headerText={translate("modal.splash.message.header")}
        modalContent={
          <>
            {splashMessage.paragraphs.map((text, index) => (
              <p key={index}>{text}</p>
            ))}
          </>
        }
        modalFooter={
          <button className="button" onClick={handleModalClose}>
            OK
          </button>
        }
      />
    </>
  );
};
