import { useQuery } from "react-query";
import {apiGet} from "../api/api-calls";
import { LicenceConstants} from "../types";

export const useClientContext = () => {
  const { data } = useQuery(["clientContext"], getClientContext, {
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });
  const clientContext = data;

  return { clientContext };
};

const getClientContext = () => apiGet<LicenceConstants>("/api/client-context");
