import { useQuery } from "react-query";
import { apiGet } from "../api/api-calls";

export type CloudinaryCredentials = {
  cloud_name: string;
  api_key: string;
  username: string;
  timestamp: number;
  signature: string;
};

export function useCloudinaryCredentials() {
  const fifteenMinutesInMS = 1000 * 60 * 15;
  const { data } = useQuery(["cloudinary"], fetchCloudinaryCredentials, {
    staleTime: fifteenMinutesInMS,
    refetchOnWindowFocus: false,
  });
  return data;
}

const fetchCloudinaryCredentials = () =>
  apiGet<{ credentials: CloudinaryCredentials; baseFolder?: string }>(`/api/cloudinary/credentials`);
