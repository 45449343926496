type Customizations = { field: string; value: string }[];

export type JsonCustomizations = {
  subject?: string;
  replyTo?: string;
} & Record<string, string>;

export const generateCustomizationsObject = (customizations: Customizations) => {
  const generatedCustomizations: JsonCustomizations = {};
  if (customizations === undefined) return generatedCustomizations;
  customizations?.map(({ field, value }) => {
    generatedCustomizations[field] = value;
  });
  return generatedCustomizations;
};
