import React, { useState } from "react";
import { SelectDisplayValues } from "./select-display-values";
import { DefaultInput } from "./default-input";
import { BooleanInput } from "./boolean-input";
import { AttributeCriterion } from "../../types";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO } from "date-fns";
import { toISOIgnoreTimezone } from "../../helpers/utils";

type StandardInputChange = {
  handleInputChange: (event: {
    target: {
      name: string;
      value: string;
    };
  }) => void;
};

type DisplaySpecificInputProps = {
  acceptedValues?: string[];
  type?: string;
  value: AttributeCriterion;
};

export const DisplaySpecificInput: React.FC<DisplaySpecificInputProps & StandardInputChange> = ({
  acceptedValues,
  handleInputChange,
  value,
  type,
}) => {
  switch (type) {
    case "STRING":
      if (acceptedValues !== undefined) {
        return <SelectDisplayValues {...{ acceptedValues, handleInputChange, value }} />;
      } else {
        return <DefaultInput {...{ value, handleInputChange }} />;
      }

    case "DATETIME":
    case "DATE":
      const singleValue = Array.isArray(value.value) ? value.value[0] : value.value;
      return (
        <div className="field">
          <div className="control has-icons-right">
            <DatePicker defaultValue={singleValue} handleInputChange={handleInputChange} />
            <span className="icon is-small is-right">
              <i className="fas fa-calendar"></i>
            </span>
          </div>
        </div>
      );

    case "BOOLEAN":
      return <BooleanInput {...{ acceptedValues, value, handleInputChange }} />;

    case "INTEGER":
      return <DefaultInput {...{ value, handleInputChange }} inputType="number" />;

    default:
      return <DefaultInput {...{ value, handleInputChange }} />;
  }
};

type DatePickerProps = {
  defaultValue?: string;
  handleInputChange: (event: {
    target: {
      name: string;
      value: string;
    };
  }) => void;
};

const DatePicker: React.FC<DatePickerProps> = ({ defaultValue, handleInputChange }) => {
  const [date, setDate] = useState(defaultValue ? parseISO(defaultValue) : undefined);

  React.useEffect(() => {
    // If we change operator, we enforce the date to be undefined
    if (defaultValue === "") setDate(undefined);
  }, [defaultValue]);

  const handleNewDate = (dateValue: Date) => {
    setDate(dateValue);
    const newDate = dateValue ? toISOIgnoreTimezone(dateValue) : undefined;
    handleInputChange({ target: { name: "value", value: newDate || "" } });
  };

  return (
    <ReactDatePicker
      wrapperClassName="is-flex"
      className="input"
      selected={date}
      onChange={handleNewDate}
      dateFormat="dd/MM/yyyy"
    />
  );
};
