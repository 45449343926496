import React, { useState } from "react";
import { Link, RouteComponentProps } from "@reach/router";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import { unsafeApiPost } from "../api/api-calls";
import { useTranslation } from "../hooks/use-translation";
import cx from "classnames";

export type Props = {
  activationToken?: string;
};

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .required("Please define a new password")
    .min(8, "Your password should be at least 8 characters long"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("newPassword")], "Passwords must match")
    .required("Required field"),
});

export const Activate: React.FC<RouteComponentProps & Props> = (props) => {
  const { activationToken } = props;

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [succesMessageVisibility, setSuccesMessageVisibility] = useState(false);
  const [serverError, setServerError] = useState("");
  const { translate } = useTranslation();

  const onSubmit = async (data: { [string: string]: string }) => {
    const { confirmPassword } = data;
    try {
      await unsafeApiPost(`/api/activation/activate`, { activationToken, password: confirmPassword });
      setSuccesMessageVisibility(true);
    } catch (err) {
      setServerError("Could not activate");
      console.error(err);
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((oldIsVisible) => !oldIsVisible);
  };

  return (
    <div className="column is-half-desktop is-three-quarters mx-auto">
      <div className="panel is-primary ">
        <p className="panel-heading ">{translate("activate.title")}</p>
        <div>
          <form className="box is-shadowless" onSubmit={handleSubmit(onSubmit)}>
            <div className="field">
              <label className="label" htmlFor="newPassword">
                {translate("activate.password")}
              </label>
              <div className="control has-icons-right">
                <input
                  className="input"
                  type={isPasswordVisible ? "text" : "password"}
                  name="newPassword"
                  id="newPassword"
                  ref={register}
                />
                <span className="icon is-small is-right pointer-events" onClick={togglePasswordVisibility}>
                  <i
                    className={cx("fas", "fa-lg", {
                      "fa-eye-slash": isPasswordVisible,
                      "fa-eye": !isPasswordVisible,
                    })}
                  ></i>
                </span>
              </div>
            </div>
            <p className="has-text-danger mb-3">{errors.newPassword?.message}</p>
            <div className="field">
              <label className="label" htmlFor="confirmPassword">
                {translate("activate.password.confirmation")}
              </label>
              <div className="control has-icons-right">
                <input
                  className="input"
                  type={isPasswordVisible ? "text" : "password"}
                  name="confirmPassword"
                  id="confirmPassword"
                  ref={register}
                />
                <span className="icon is-small is-right pointer-events" onClick={togglePasswordVisibility}>
                  <i
                    className={cx("fas", "fa-lg", {
                      "fa-eye-slash": isPasswordVisible,
                      "fa-eye": !isPasswordVisible,
                    })}
                  ></i>
                </span>
              </div>
            </div>
            <p className="has-text-danger mb-3">{errors.confirmPassword?.message}</p>
            <div className="field">
              <div className="control has-text-right">
                <button type="submit" className="button is-primary" onClick={handleSubmit(onSubmit)}>
                  {translate("submit")}
                </button>
              </div>
            </div>
            {succesMessageVisibility && (
              <>
                <p className="is-size-6 has-text-centered has-text-weight-bold">
                  {translate("activate.success")} {translate("general.set")}.
                </p>
                <p className="is-size-6 has-text-centered has-text-weight-bold">
                  <Link to="/">{translate("general.backToHomepage")}</Link>
                </p>
              </>
            )}
            {serverError && (
              <p className="is-size-6 has-text-centered has-text-weight-bold has-text-danger">{serverError}</p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
