import React, { useContext } from "react";
import { Link } from "@reach/router";
import cx from "classnames";
import { WizardStep, steps } from "./wizard-wrapper";
import { WithPreview } from "./with-preview";
import { Substitutions } from "../types";
import { useTranslation } from "../hooks/use-translation";
import { WizardContext } from "../context/wizard-context";
import { useCampaign } from "../hooks/use-campaign";
import { CampaignStatus } from "../types/campaigns";

type StepProps = {
  currentStep: WizardStep;
  step: WizardStep;
  templateId?: string;
  title?: string;
  campaignId?: string;
};
export const StepsWithMarker: React.FC<StepProps> = ({ currentStep, templateId, step, title, campaignId }) => {
  const { channel } = useContext(WizardContext);
  const stepIndex = steps.indexOf(step);
  const { status = "DRAFT" } = useCampaign(campaignId, channel);

  const blockedStepsStatus: CampaignStatus[] = ["SCHEDULED", "SENT"];

  const content = (
    <>
      <div className="step-marker">{stepIndex + 1}</div>
      <div className="step-details">
        <p className="step-title">{title}</p>
      </div>
    </>
  );

  return (
    <div
      data-testid={`step-${step}`}
      className={cx("step-item", {
        "is-active": step === currentStep,
        "is-completed": stepIndex < steps.indexOf(currentStep),
      })}
    >
      {templateId ? (
        blockedStepsStatus.includes(status) ? (
          content
        ) : (
          <Link className="is-primary" to={`/campaigns/edit-${channel}-campaign/${campaignId}/${step}`}>
            {content}
          </Link>
        )
      ) : (
        content
      )}
    </div>
  );
};
export const StepContent = (props: any) => {
  return <div className="steps-content">{props.children}</div>;
};

type Props = {
  currentStep: WizardStep;
  templateId?: string;
  campaignId?: string;
  props: any;
  stepComponents: Record<WizardStep, JSX.Element>;
  substitutions?: Substitutions;
};
export const Steps: React.FC<Props> = ({
  currentStep,
  templateId = "",
  campaignId,
  props,
  stepComponents,
  substitutions = {},
}) => {
  const { translate } = useTranslation();

  const stepTitles: Record<WizardStep, string> = {
    customize: translate("general.customize"),
    target: translate("general.target"),
    send: translate("general.send"),
    template: translate("general.template"),
  };
  return (
    <div className="box steps is-shadowless">
      {steps.map((step) => (
        <StepsWithMarker {...{ currentStep, templateId, step, campaignId }} title={stepTitles[step]} key={step} />
      ))}
      <StepContent props={props}>
        {currentStep === "template" ? (
          stepComponents["template"]
        ) : (
          <WithPreview {...{ substitutions, templateId, campaignId }}>{stepComponents[currentStep]}</WithPreview>
        )}
      </StepContent>
    </div>
  );
};
