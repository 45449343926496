import React from "react";
import { useOperators } from "../../hooks/use-operators";
import { AttributeCriterion } from "../../types";

type Props = {
  value: AttributeCriterion;
  handleOperatorChange: (event: { target: { name: string; value: string } }) => void;
};

export const OperatorField: React.FC<Props> = ({ value, handleOperatorChange }) => {
  const operators = useOperators();

  return (
    <select name="operator" value={value.operator || ""} onChange={handleOperatorChange} className="is-text-overflow">
      {!value.operator && <option value="">---</option>}
      {Object.entries(operators).map((operator) => (
        <option key={operator[0]} value={operator[0]}>
          {operator[1]}
        </option>
      ))}
    </select>
  );
};
