import React from "react";

const DocumentationIcon = () => {
    return (

        <svg style={{maxHeight:"30px"}} width="28" height="30" viewBox="0 0 48 36" fill="black" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M20 0a11.32 11.32 0 012.08 22.46v4.59a2.08 2.08 0 01-4.15 0v-6.46A2.08 2.08 0 0120 18.51a7.18 7.18 0 10-7.18-7.19 2.07 2.07 0 01-4.14 0A11.34 11.34 0 0120 0zm0 33.54a3.23 3.23 0 11-3.22 3.24A3.24 3.24 0 0120 33.54z"></path>
        </svg>

    );
};

DocumentationIcon.displayName = "DocumentationIcon";
export default DocumentationIcon;
