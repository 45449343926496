import React from "react";
import { AttributeCriterion } from "../types";
import { Loader } from "./loader";
import { useTranslation } from "../hooks/use-translation";
import { CriterionEditor } from "./criteria-list-editor/criterion-editor";

export type FetchedAttributes = {
  attributes: Attributes[];
};

export type Attributes = { technicalName: string; displayName: string; type: string; acceptedValues?: string[] };

type CriteriaListProps = {
  value: AttributeCriterion[];
  onChange: (newValue: AttributeCriterion[]) => void;
  fetchedAttributes: FetchedAttributes;
  isAttributesLoading: boolean;
};

export const defaultValuesOperators: Record<string, string | null> = {
  IS_NULL: null,
  IS_NOT_NULL: null,
};

function setCriterionDefaultValues(criterion: AttributeCriterion): AttributeCriterion {
  if (criterion.operator === undefined) {
    return criterion;
  }
  const defaultValue = defaultValuesOperators[criterion.operator];
  if (defaultValue === undefined) {
    return criterion;
  }
  return { ...criterion, value: defaultValue !== null ? defaultValue : undefined };
}

export const CriteriaListEditor: React.FC<CriteriaListProps> = ({
  value = [],
  onChange,
  fetchedAttributes,
  isAttributesLoading,
}) => {
  const { translate } = useTranslation();

  const handleAppend = () => {
    onChange([...value, {}]);
  };
  const handleCriterionChange = (index: number) => (criterion: AttributeCriterion) => {
    onChange([...value.slice(0, index), setCriterionDefaultValues(criterion), ...value.slice(index + 1)]);
  };
  const handleRemove = (index: number) => () => {
    onChange([...value.slice(0, index), ...value.slice(index + 1)]);
  };

  if (isAttributesLoading) {
    return <Loader />;
  }

  return (
    <fieldset className="criteria-fieldset">
      {value.map((criterion, index) => {
        return (
          <CriterionEditor
            key={index}
            value={criterion}
            onChange={handleCriterionChange(index)}
            onRemove={handleRemove(index)}
            fetchedAttributes={fetchedAttributes}
          />
        );
      })}
      <button type="button" className="button" onClick={handleAppend}>
        {translate("wizard.target.button.criteria")}
      </button>
    </fieldset>
  );
};
