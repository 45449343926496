import React, { useState, useEffect } from "react";
import { Navbar } from "./components/navbar";
import { Router, RouteComponentProps, useNavigate, useLocation } from "@reach/router";
import { Campaigns } from "./pages/campaigns";
import { LoginContext, loginContextReducer, loginContextInitialState } from "./context/login-context/login-context";
import { Login, LoginData, LoginModal } from "./pages/login";
import { WizardWrapper } from "./components/wizard-wrapper";
import { ResetPassword } from "./pages/reset-password";
import { SendEmailPassword } from "./pages/send-password";
import { login, isTokenValid, apiPost, apiGet, unsafeApiGet } from "./api/api-calls";
import { Activate } from "./pages/activate";
import { AdminActivation } from "./pages/admin-activation";
import { Loader } from "./components/loader";
import { Logout } from "./pages/logout";
import { LicenceConstants, UserInfos } from "./types";
import { NotificationProvider } from "./context/notification-provider";
import { Notification } from "./components/notification/notification";
import { SplashMessage } from "./components/splash-message";
import {DocumentationLink} from "./hooks/use-documentation";

const RoutedApp: React.FC<RouteComponentProps> = () => {
  const [loginState, dispatch] = React.useReducer(loginContextReducer, loginContextInitialState);

  const { isLoginRequired, isCssLoaded, clientCode, activeClub, user } = loginState;

  const setActiveClub = (club: string) => {
    dispatch({ type: "SET_ACTIVE_CLUB", activeClub: club });
  };

  const setIsLoginRequired = (boolean: boolean) => {
    dispatch({ type: "SET_IS_LOGIN_REQUIRED", isLoginRequired: boolean });
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [docLink,setDocLink] = useState("");
  const [backgroundTab, setBackgroundTab] = useState(false);

  const isAnonymousPath =
    location.pathname === "/" ||
    location.pathname.startsWith("/password") ||
    location.pathname.startsWith("/activate") ||
    location.pathname.startsWith("/activation");

  const handleLogin = async (input: LoginData) => {
    try {
      const cleanedUserLogin = input.login.trim();
      const payload = await login(cleanedUserLogin, input.password);
      if (!payload) return false;
      const { roles } = payload;

      dispatch({
        type: "SET_LOGIN_CONTEXT",
        isLoginRequired: false,
        user: cleanedUserLogin,
        availableClubs: roles,
        activeClub: activeClub ? activeClub : Object.keys(roles)[0],
      });

      const fetchedUserData = await fetchUser(cleanedUserLogin);
      const responseDocLink = await fetchLinkDocumentation();
      setDocLink(responseDocLink.url);
      dispatch({ type: "SET_USER_DATA", userData: fetchedUserData });

      return true;
    } catch (err) {
      return false;
    }
  };

  const handleLogout = async () => {
    const { user } = loginState;
    await apiPost("/api/logout", { user });
    dispatch({ type: "LOGOUT" });
  };

  const fetchUser = async (userToFetch: string) => {
    return await apiGet<UserInfos>(`/api/users/${userToFetch}`);
  };
  const fetchLinkDocumentation = async () => {
    return await apiGet<DocumentationLink>(`/api/documentation`);
  };

  useEffect(() => {
    let cancelled = false;

    function handleVisibilityChange() {
      if (!cancelled) {
        setBackgroundTab(document.hidden);
      }
    }
    document.addEventListener("visibilitychange", handleVisibilityChange, false);
    return () => {
      cancelled = true;
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let cancelled = false;
    async function checkTokenExpired() {
      if (isLoginRequired || isAnonymousPath) {
        return;
      }
      if (!isTokenValid()) {
        if (!cancelled) {
          return dispatch({ type: "SET_IS_LOGIN_REQUIRED", isLoginRequired: true });
        }
      } else {
        setTimeout(() => {
          if (!cancelled) {
            checkTokenExpired();
          }
        }, 5000);
      }
    }
    checkTokenExpired();
    return () => {
      cancelled = true;
    };
  }, [isAnonymousPath, backgroundTab, isLoginRequired]);

  useEffect(() => {
    let cancelled = false;
    async function loadCss() {
      const {
        clientCode: fetchedClientCode,
        language: fetchedLanguage,
        channels,
        smsAuthorizedScopes,
        openAiServiceIsEnabled,
        enabledTemplateWithActito
      } = await unsafeApiGet<LicenceConstants>("/api/client-context");
      if (!cancelled) {
        dispatch({
          type: "SET_CLIENT_CONTEXT",
          clientCode: fetchedClientCode,
          language: fetchedLanguage || "en",
          modulesPermissions: channels,
          smsChannelAuthorizedScopes: smsAuthorizedScopes,
          openAiServiceIsEnabled:openAiServiceIsEnabled || false,
          enabledTemplateWithActito:enabledTemplateWithActito || false,
        });
      }
      const pageTitle = "Actito DMA - " + fetchedClientCode;

      const title = document.head.getElementsByTagName("title")?.item(0);
      if (title) {
        title.innerHTML = pageTitle || fetchedClientCode;
      }

      const link = document.createElement("link");
      link.setAttribute("rel", "stylesheet");
      link.setAttribute("href", process.env.PUBLIC_URL+`/skin/${fetchedClientCode}/index.css`);
      link.onload = () => {
        if (!cancelled) {
          dispatch({
            type: "SET_IS_CSS_LOADED",
            isCssLoaded: true,
          });
        }
      };
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    loadCss();
    return () => {
      cancelled = true;
    };
  }, []);

  useEffect(() => {
    const { user } = loginState;
    if (!user) {
      if (isAnonymousPath) {
        return;
      }
      navigate("/", { replace: true });
    }
  }, [navigate, isAnonymousPath, loginState]);

  if (!isCssLoaded || !clientCode) return <Loader />;

  return (
    <>
      <LoginContext.Provider
        value={{
          ...loginState,
          handleLogout,
          setActiveClub,
        }}
      >
        <NotificationProvider>
          <div className="app">
            <Navbar urlLink={docLink}/>
            <Router primary={false} className="container">
              <Login path="/" onLogin={handleLogin} />
              <Logout path="/logout" />
              <SendEmailPassword path="/password" />
              <Activate path="/activate/:activationToken" />
              <AdminActivation path="/activation" />
              <ResetPassword path="/password/reset/:userId/:securityCode" />
              <ResetPassword path="/password/set/:userId/:securityCode" />
              <Campaigns path="/campaigns" />
              <WizardWrapper path="/campaigns/new-campaign" />
              <WizardWrapper path="/campaigns/:editChannel/:campaignId/:currentStep" />
            </Router>
            {isLoginRequired && !isAnonymousPath ? (
              <LoginModal onLogin={handleLogin} setLoginRequired={setIsLoginRequired} />
            ) : null}
          </div>
          {user && <SplashMessage />}
          <Notification />
        </NotificationProvider>
      </LoginContext.Provider>
    </>
  );
};

export function App() {
  return (
    <Router>
      <RoutedApp path="/*" />
    </Router>
  );
}
