import React from "react";
import { Segment } from "../types";

type Props = {
  register: (instance: HTMLInputElement | null) => void;
  segments: Segment[] | undefined;
};

export const ProfileListCriteria: React.FC<Props> = ({ segments, register }) => {
  return (
    <>
      {segments?.map(({ technicalName, displayName }) => (
        <div key={technicalName} className="field">
          <div className="control">
            <label className={"checkbox "}>
              <input
                data-test="list-picker"
                name={`selectedLists.${technicalName}`}
                type="checkbox"
                className="checkbox"
                ref={register}
              />
              &nbsp;
              {displayName}
            </label>
          </div>
        </div>
      ))}
    </>
  );
};
