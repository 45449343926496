import React from "react";
import { AttributeCriterion } from "../../types";
import { DisplayNestedInput } from "../target-step/display-nested-input";

type Props = {
  criterionValues: string[];
  handleRemove: (index: number) => void;
  handleNewItem: (values: string[]) => void;
  acceptedValues?: string[];
  type?: string;
};
export const MultiValuesInput: React.FC<Props> = ({
  criterionValues,
  handleRemove,
  handleNewItem,
  acceptedValues,
  type,
}) => {
  const handleNestedInputChange = (inputValue: string, index: number) => {
    const newArray = [...criterionValues];
    newArray[index] = inputValue;
    handleNewItem(newArray);
  };

  return (
    <>
      {criterionValues.map((value, index) => (
        <div key={index} className="field has-addons pb-3">
          <div className="control is-expanded">
            <DisplayNestedInput {...{ acceptedValues, handleNestedInputChange, type, value, index }} />
          </div>
          <div className="control">
            <button type="button" className="button" onClick={() => handleRemove(index)}>
              Delete
            </button>
          </div>
        </div>
      ))}
    </>
  );
};
type WrapperProps = {
  acceptedValues?: string[];
  handleInputChange: (event: {
    target: {
      name: string;
      value: string | string[];
    };
  }) => void;
  type?: string;
  value: AttributeCriterion;
};

export const MultiValuesInputWrapper: React.FC<WrapperProps> = ({ handleInputChange, acceptedValues, type, value }) => {
  const { value: nestedValues = [""] } = value;
  const criterionValues = Array.isArray(nestedValues) ? nestedValues : [nestedValues];

  const handleRemove = (index: number) => {
    handleInputChange({
      target: { name: "value", value: [...criterionValues.slice(0, index), ...criterionValues.slice(index + 1)] },
    });
  };

  const handleAppend = () => {
    handleInputChange({
      target: { name: "value", value: [...criterionValues, ""] },
    });
  };

  const handleNewItem = (values: string[]) => {
    handleInputChange({ target: { name: "value", value: values } });
  };
  return (
    <>
      <MultiValuesInput {...{ handleRemove, handleNewItem, criterionValues, acceptedValues, type }} />

      <div className="field ">
        <button
          type="button"
          className="button"
          onClick={() => {
            handleAppend();
          }}
          data-testid="nested-button-add"
        >
          +
        </button>
      </div>
    </>
  );
};
