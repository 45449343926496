import React, { useState } from "react";
import { Link, RouteComponentProps } from "@reach/router";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import { unsafeApiPost } from "../api/api-calls";
import * as yup from "yup";
import { useTranslation } from "../hooks/use-translation";
import cx from "classnames";

const schema = yup.object().shape({
  userNameToActivate: yup.string().required("This field is required"),
  adminPassword: yup.string().required("This field is required"),
});

export const AdminActivation: React.FC<RouteComponentProps> = () => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [succesMessageVisibility, setSuccesMessageVisibility] = useState(false);
  const [serverError, setServerError] = useState("");
  const { translate } = useTranslation();

  const onSubmit = async (data: { [string: string]: string }) => {
    const { userNameToActivate } = data;
    try {
      await unsafeApiPost(`/api/activation/trigger/${userNameToActivate}`);
      setSuccesMessageVisibility(true);
      setServerError("");
    } catch (err) {
      setServerError(translate("admin.activation.error.server"));
      setSuccesMessageVisibility(false);
      console.error(err);
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((oldIsVisible) => !oldIsVisible);
  };

  return (
    <div className="column is-half-desktop is-three-quarters mx-auto">
      <div className="panel is-primary ">
        <p className="panel-heading">{translate("admin.activation.title")}</p>
        <div>
          <form className="box is-shadowless" onSubmit={handleSubmit(onSubmit)}>
            <div className="field">
              <label className="label" htmlFor="userNameToActivate">
                {translate("admin.activation.login")}
              </label>
              <div className="control">
                <input className="input" type="text" name="userNameToActivate" id="userNameToActivate" ref={register} />
              </div>
            </div>
            <p className="has-text-danger mb-3">{errors.newPassword?.message}</p>
            <div className="field">
              <label className="label" htmlFor="adminPassword">
                {translate("admin.activation.password")}
              </label>
              <div className="control has-icons-right">
                <input
                  className="input"
                  type={isPasswordVisible ? "text" : "password"}
                  name="adminPassword"
                  id="adminPassword"
                  ref={register}
                />
                <span className="icon is-small is-right pointer-events" onClick={togglePasswordVisibility}>
                  <i
                    className={cx("fas", "fa-lg", {
                      "fa-eye-slash": isPasswordVisible,
                      "fa-eye": !isPasswordVisible,
                    })}
                  ></i>
                </span>
              </div>
            </div>
            <p className="has-text-danger mb-3">{errors.adminPassword?.message}</p>
            <div className="field">
              <div className="control has-text-right">
                <button type="submit" className="button is-primary" onClick={handleSubmit(onSubmit)}>
                  {translate("submit")}
                </button>
              </div>
            </div>
            {succesMessageVisibility && (
              <>
                <p className="is-size-6 has-text-centered has-text-weight-bold">
                  {translate("admin.activation.success")}.
                </p>
                <p className="is-size-6 has-text-centered has-text-weight-bold">
                  <Link to="/">{translate("general.backToHomepage")}</Link>
                </p>
              </>
            )}
            {serverError && (
              <p className="is-size-6 has-text-centered has-text-weight-bold has-text-danger">{serverError}</p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
