import {createContext} from "react";
import {isTokenValid} from "../../api/api-calls";
import {IModulesPermissions, Language, UserInfos} from "../../types";

type LoginContextState = {
    isLoginRequired: boolean;
    isCssLoaded: boolean;
    language: Language;
    user?: string;
    userInfos?: UserInfos;
    clientCode?: string;
    activeClub?: string;
    availableClubs?: { [scope: string]: string[] };
    modulesPermissions: IModulesPermissions;
    smsChannelAuthorizedScopes?: string[];
};
type Action =
    | { type: "SET_IS_LOGIN_REQUIRED"; isLoginRequired: boolean }
    | { type: "SET_IS_CSS_LOADED"; isCssLoaded: boolean }
    | { type: "SET_USER_DATA"; userData: UserInfos }
    | { type: "SET_ACTIVE_CLUB"; activeClub: string }
    | {
    type: "SET_LOGIN_CONTEXT";
    isLoginRequired: boolean;
    user: string;
    availableClubs: { [scope: string]: string[] };
    activeClub: string;
}
    | {
    type: "SET_CLIENT_CONTEXT";
    clientCode: string;
    language: Language;
    modulesPermissions: IModulesPermissions;
    smsChannelAuthorizedScopes: string[];
    openAiServiceIsEnabled: boolean;
    enabledTemplateWithActito: boolean;
}
    | { type: "LOGOUT" };

export type LoginContextIdentity = {
    isLoginRequired: boolean;
    isCssLoaded: boolean;
    language: Language;
    user?: string;
    userInfos?: UserInfos;
    clientCode?: string;
    activeClub?: string;
    availableClubs?: { [scope: string]: string[] };
    modulesPermissions: IModulesPermissions;
    smsChannelAuthorizedScopes?: string[];
    handleLogout: () => void;
    setActiveClub: (activeClub: string) => void;
};

export const loginContextReducer = (state: LoginContextState, action: Action) => {
    switch (action.type) {
        case "SET_IS_LOGIN_REQUIRED":
            return {
                ...state,
                isLoginRequired: action.isLoginRequired,
            };
        case "SET_IS_CSS_LOADED":
            return {
                ...state,
                isCssLoaded: action.isCssLoaded,
            };

        case "SET_USER_DATA":
            return {
                ...state,
                userInfos: action.userData,
            };
        case "SET_ACTIVE_CLUB":
            return {
                ...state,
                activeClub: action.activeClub,
            };
        case "SET_CLIENT_CONTEXT":
            return {
                ...state,
                clientCode: action.clientCode,
                language: action.language,
                modulesPermissions: action.modulesPermissions,
                smsChannelAuthorizedScopes: action.smsChannelAuthorizedScopes,
                openAiServiceIsEnabled:action.openAiServiceIsEnabled,
                enabledTemplateWithActito: action.enabledTemplateWithActito,
            };
        case "SET_LOGIN_CONTEXT":
            return {
                ...state,
                isLoginRequired: action.isLoginRequired,
                user: action.user,
                availableClubs: action.availableClubs,
                activeClub: action.activeClub,
            };
        case "LOGOUT":
            return {
                ...state,
                user: undefined,
                activeClub: undefined,
            };
        default:
            return state;
    }
};

export const loginContextInitialState = {
    isLoginRequired: !isTokenValid(),
    isCssLoaded: false,
    language: "en",
    user: undefined,
    userInfos: undefined,
    clientCode: undefined,
    activeClub: undefined,
    availableClubs: undefined,
    modulesPermissions: {
        EMAIL: {enabled: false},
        SMS: {enabled: false},
    },
    smsChannelAuthorizedScopes: undefined,
};

export const LoginContext = createContext<LoginContextIdentity>({
    clientCode: undefined,
    activeClub: undefined,
    user: undefined,
    language: "en",
    userInfos: undefined,
    availableClubs: undefined,
    handleLogout: () => {
    },
    setActiveClub: () => {
    },
    modulesPermissions: {EMAIL: {enabled: false}, SMS: {enabled: false}},
    isLoginRequired: true,
    isCssLoaded: false,

});
