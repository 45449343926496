import * as yup from "yup";

export type ExistingFieldsValues = { field: string; value: string }[];

export type TemplateFieldsConfiguration = {
  sections: Section[];
};
export interface Section {
  label: string;
  fields: Field[];
}
export interface Field {
  fieldName: string;
  label: string;
  type: "linkUrl" | "imageUrl" | "url" | "shortText" | "longText" | "email";
  placeholder: string;
  defaultValue: string;
  isRequired: boolean;
  validation?: yup.StringSchema;
}

export const generateDefaultValues = (config: TemplateFieldsConfiguration) => {
  let defaultValues: { [key: string]: string } = {};
  config.sections.forEach((section) => {
    section.fields.forEach((field) => {
      if (field.defaultValue === undefined) return undefined;
      else {
        let value = `${field.fieldName}: ${field.defaultValue}`;
        defaultValues[`${field.fieldName}`] = `${field.defaultValue}`;
        return value;
      }
    });
    return section;
  });
  return defaultValues;
};

export const generateExistingValues = (config?: ExistingFieldsValues) => {
  if (config === undefined) return;
  let existingValues: { [key: string]: string } = {};
  config.forEach((customization) => {
    let value = `${customization.field}: ${customization.value}`;
    existingValues[`${customization.field}`] = `${customization.value}`;
    return value;
  });
  return existingValues;
};

export const generateVariableNames = (config: TemplateFieldsConfiguration) => {
  let variableNames: string[] = [];
  config.sections.forEach((section) => {
    section.fields.forEach((field) => {
      return variableNames.push(field.fieldName);
    });
    return section;
  });
  return variableNames;
};

export function addValidations(config: TemplateFieldsConfiguration) {

  const urlRegexPattern = /[^$&+, :;=?@<>#%{}|\\^~[\]]/
  config.sections.forEach((section) => {
    section.fields.forEach((field) => {

      const isValidationNeeded = field.isRequired || field.type === "linkUrl";
      if (isValidationNeeded) {
        let validation = yup.string();

        if (field.isRequired) {
          validation = validation.required("This field is required");
        }
        if (field.type === "linkUrl") {
          validation = validation.url("URL format is not correct !");
        }

        if(field.type === "imageUrl"){
          validation = validation.matches(urlRegexPattern, `Invalid image URL${field.defaultValue}`)
        }
        if (field.type === "email") {
          validation = validation.email("Email format is not correct !");
        }
        field.validation = validation;
      }
      return field;
    });
    return section;
  });
  return config;
}
