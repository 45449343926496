import { useTranslation } from "./use-translation";

export const useOperators = () => {
  const { translate } = useTranslation();
  const operators: Record<string, string> = {
    EQ: translate("operator.EQ"),
    NE: translate("operator.NE"),
    GT: translate("operator.GT"),
    GTE: translate("operator.GTE"),
    LT: translate("operator.LT"),
    LTE: translate("operator.LTE"),
    IN: translate("operator.IN"),
    NOT_IN: translate("operator.NOT_IN"),
    IS_NULL: translate("operator.IS_NULL"),
    IS_NOT_NULL: translate("operator.IS_NOT_NULL"),
  };
  return operators;
};
