import React, { Fragment, Key, useCallback, useMemo } from "react";
import {
  useTable,
  useSortBy,
  Column,
  usePagination,
  useFilters,
  useExpanded,
  Row,
  ColumnInstance,
  useGlobalFilter,
  TableRowProps,
} from "react-table";

import { DefaultColumnFilter } from "../helpers/dashboard-helpers/default-column-filter";
import { Pagination } from "./pagination";
import { Campaign } from "../types/campaigns";
import { useEvents } from "../hooks/use-event";
import { Channel, DMAEvent, FlattenedReport } from "../types";
import { Loader } from "./loader";
import { parseISO } from "date-fns";
import { useTranslation } from "../hooks/use-translation";
import { generateSortingIndicator } from "../helpers/dashboard-helpers/generate-sorting-indicator";
import { GlobalFilter } from "../helpers/dashboard-helpers/global-filter";
import styles from "./dashboard.module.scss";

//NB: requires extending the react-table default types with the plugins that we actually use
// see the doc at https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/react-table for more information

type IDashboard = {
  columns: Column<Campaign>[];
  data: Campaign[];
  dashboardType: DashboardType;
  commonDashboardFilter?: Channel;
  setCommonDashboardFilter: (string?: Channel) => void;
};
type DashboardType = "pending" | "scheduled" | "sent";
export type IColumn = {
  column: IColumnVariables;
};
export type IColumnVariables = {
  filterValue: string;
  preFilteredRows: Array<Row>;
  filteredRows: Array<Row>;
  setFilter: (filterValue: string | undefined) => void;
};

export const Dashboard: React.FC<IDashboard> = ({
  columns,
  data,
  dashboardType,
  commonDashboardFilter,
  setCommonDashboardFilter,
}) => {
  const { translate } = useTranslation();
  const SubRowAsync = ({ row, visibleColumns }: { row: Row<Campaign>; visibleColumns: ColumnInstance<Campaign>[] }) => {
    const { campaignId } = row.original;
    const { events, isLoading } = useEvents(campaignId);
    let lastEvent = undefined;

    if (events.length !== 0) {
      lastEvent = events[events.length - 1];
    }

    return <SubRows {...{ visibleColumns, lastEvent, isLoading }} />;
  };

  const SubRows = ({ lastEvent, isLoading }: { lastEvent?: DMAEvent; isLoading: boolean }) => {
    if (isLoading) {
      return <Loader />;
    }

    return (
      <>
        {lastEvent ? (
          <div>
            <h3 className="p-1">
              <strong>{translate("events.date")}</strong> / <strong>{translate("events.action")}</strong> /{" "}
              <strong>{translate("events.author")}</strong> / <strong>{translate("events.details")}</strong>
            </h3>
            {[lastEvent].map(({ createdAt, user, action, comment }, index) => {
              const formatedDate = parseISO(createdAt).toLocaleDateString();
              return (
                <article className="message is-primary is-small" key={index}>
                  <div className="message-body">
                    <strong>{formatedDate}</strong> / <strong>{action}</strong> / <strong>{user}</strong>
                    {comment && ` / ${comment}`}
                  </div>
                </article>
              );
            })}
          </div>
        ) : (
          <article className="message is-small">
            <div className="message-body">
              <h3 className="p-1">{translate("dashboard.noEvent")}</h3>
            </div>
          </article>
        )}
      </>
    );
  };
  const renderRowSubComponent = useCallback(
    ({
      row,
      rowProps,
      visibleColumns,
    }: {
      row: Row<Campaign>;
      rowProps: TableRowProps;
      visibleColumns: ColumnInstance<Campaign>[];
    }) => <SubRowAsync {...{ row, rowProps, visibleColumns }} />,
    []
  );

  const renderRowSubReporting = useCallback(
    ({ row }: { row: Row<Campaign> }) => {
      if (!row.original.actitoCampaignData) {
        return <h4>No data</h4>;
      }

      const { report } = row.original.actitoCampaignData;
      const { bouncesDetails, ...reportBase } = report;

      const flattenedReport: FlattenedReport = { ...reportBase, ...bouncesDetails };

      const Tag: React.FC<{ data: string }> = ({ data }) => (
        <span className="tag is-rounded">
          {translate(`reporting.tag.${data}`)} :&nbsp;
          <strong> {flattenedReport[data as keyof FlattenedReport]}</strong>
        </span>
      );

      return (
        <div className="tags">
          {Object.keys(flattenedReport).map((data, index) => {
            return <Tag key={index} {...{ data }} />;
          })}
        </div>
      );
    },
    [translate]
  );

  const filterTypes = useMemo(
    () => ({
      text: (rows: any[], id: Key, filterValue: any) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable<Campaign>(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: ["id"],
        sortBy: [
          {
            id: "createdAt",
            desc: true,
          },
        ],
      },
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  return (
    <>
      <GlobalFilter
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
        {...{ commonDashboardFilter, setCommonDashboardFilter }}
      />

      <table {...getTableProps()} className={`table is-hoverable is-fullwidth mt-5`}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps({
                    style: (column as any).style,
                  })}
                >
                  <span {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                  </span>
                  {column.canFilter ? column.render("Filter") : null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className={styles.fade}>
          {page.map((row) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            return (
              <Fragment key={rowProps.key}>
                <tr {...rowProps} className={styles.tableRowCampaign}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          style: { ...(cell.column as any).style, ...(cell.column as any).cellStyle },
                        })}
                        className={styles.tableCell}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded && (
                  <tr>
                    <td colSpan={visibleColumns.length}>
                      {dashboardType === "sent"
                        ? renderRowSubReporting({ row })
                        : renderRowSubComponent({ row, rowProps, visibleColumns })}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
      <Pagination
        pageSize={pageSize}
        setPageSize={setPageSize}
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        nextPage={nextPage}
        canNextPage={canNextPage}
        pageIndex={pageIndex}
      />
    </>
  );
};
