import React, { useState } from "react";
import { IColumn } from "../../components/dashboard";
import { FontAwesome } from "../../components/icons/font-awesome";
import { useTranslation } from "../../hooks/use-translation";
export function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }: IColumn) {
  const { translate } = useTranslation();
  const [isInputExpanded, setIsInputExpanded] = useState<boolean>(false);

  const handleInputExpand = () => {
    setFilter("");
    setIsInputExpanded((prevValue) => !prevValue);
  };

  return (
    <>
      {isInputExpanded ? (
        <div className="field has-addons">
          <div className="control is-expanded">
            <input
              value={filterValue || ""}
              onChange={(e) => {
                setFilter(e.target.value || undefined);
              }}
              placeholder={translate("filter")}
              className="input is-small"
            />
          </div>
          <div className="control">
            <button onClick={handleInputExpand} className="button is-small">
              <FontAwesome name="times" />
            </button>
          </div>
        </div>
      ) : (
        <span onClick={handleInputExpand}>
          <FontAwesome name="search" stylingClass="has-text-grey-light" />
        </span>
      )}
    </>
  );
}
