import { useQuery } from "react-query";
import {
  TemplateFieldsConfiguration,
  generateDefaultValues,
  generateVariableNames,
} from "../helpers/generate-default-values";
import { Substitutions } from "../types";
import { useTemplate } from "./use-templates";
import {apiGet} from "../api/api-calls";

type TemplateConfiguration = {
  templateCustomizations?: TemplateFieldsConfiguration;
  substitutions: Substitutions;
  variableNames: string[];
  isLoading: boolean;
};

const emptyTemplateConfig: TemplateConfiguration = {
  substitutions: {},
  variableNames: [],
  isLoading: true,
};

export function useTemplateFormConfiguration(campaignId?: string, templateId?: string,channel?: string,enabledTemplateWithActito?:boolean): TemplateConfiguration {
  const { template, isLoading } = useTemplate(templateId,campaignId);

  const { data } = useQuery(["templateCustomizations", campaignId], templateFormConfigFetcher, {
    enabled: channel === "EMAIL" && templateId && enabledTemplateWithActito,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
  if (!template || !data) {
    return emptyTemplateConfig;
  } else {
    return { ...data, isLoading };
  }

  async function templateFormConfigFetcher(_: string, campaignId: string) {
    const templateCustomizations = await apiGet<TemplateFieldsConfiguration>(`/api/template/actito/${campaignId}/form`);
    return {
      templateCustomizations,
      substitutions: {...generateDefaultValues(templateCustomizations) },
      variableNames: generateVariableNames(templateCustomizations),
    };
  }

}
