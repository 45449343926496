import React from "react";
import styles from "./font-awesome.module.scss";
import cx from "classnames";

type FontAwesomeType = {
  name: string;
  hexColor?: string;
  stylingClass?: string;
  fontAwesomeVariant?: string;
  fontAwesomeSize?: string;
  pulse?: boolean;
};

export const FontAwesome: React.FC<FontAwesomeType> = ({
  name,
  hexColor,
  stylingClass = "",
  fontAwesomeVariant = "fas",
  fontAwesomeSize,
  pulse = false,
}) => {
  return (
    <span
      className={cx(styles.iconContainer, stylingClass, fontAwesomeSize)}
      style={{ color: hexColor ? hexColor : "" }}
    >
      <i className={cx(fontAwesomeVariant, `fa-${name}`, pulse && "fa-pulse")}></i>
    </span>
  );
};
