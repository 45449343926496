import React from "react";
import { AttributeCriterion } from "../../types";
import {useTranslation} from "../../hooks/use-translation";

type Props = {
  value: AttributeCriterion;
  handleInputChange: (event: {
    target: {
      name: string;
      value: string;
    };
  }) => void;
};

export const BooleanInput: React.FC<Props> = ({ value, handleInputChange }) => {
  const acceptedValues = ["true", "false"];
  const { translate } = useTranslation();
  return (
    <div className="select is-fullwidth">
      <select name="value" value={value.value} onChange={handleInputChange} className="is-text-overflow">
        {!value.value && <option>---</option>}
        {acceptedValues?.map((value, index) => (
          <option key={index} value={value}>
            {translate(`attribute.value.${value}`)}
          </option>
        ))}
      </select>
    </div>
  );
};
