import React from "react";

const SpellCheckIcon = () => {
  return (

      <svg width="48" height="36" viewBox="0 0 48 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="48" height="36" fill="white"/>
          <path d="M14.167 22.333C14.0572 22.3345 13.9481 22.3139 13.8463 22.2726C13.7445 22.2313 13.6521 22.17 13.5743 22.0924C13.4966 22.0147 13.4352 21.9223 13.3938 21.8206C13.3523 21.7189 13.3317 21.6098 13.333 21.5V9.833C13.333 9.417 13.698 9 14.167 9H18.073C20.26 9 22.24 10.615 22.448 12.802V13.219C22.448 13.896 22.292 14.573 21.979 15.146C22.813 15.927 23.333 16.969 23.333 18.166C23.333 18.271 23.281 18.375 23.281 18.427C23.177 20.667 21.146 22.333 18.906 22.333H14.167ZM16.25 11.917V14.417H18.333C19.01 14.417 19.583 13.896 19.583 13.167C19.583 12.49 19.01 11.917 18.333 11.917H16.25ZM16.25 16.917V19.417H19.167C19.844 19.417 20.417 18.896 20.417 18.167C20.417 17.49 19.844 16.917 19.167 16.917H16.25ZM8.073 10.197L11.615 21.292C11.615 21.396 11.667 21.448 11.667 21.5C11.667 21.969 11.25 22.333 10.833 22.333H9.531C9.115 22.333 8.802 22.125 8.698 21.76L8.073 19.833H3.542L2.917 21.76C2.813 22.125 2.5 22.333 2.083 22.333H0.833C0.365 22.333 0 21.969 0 21.553V21.292L3.542 10.198C3.802 9.52 4.427 9 5.156 9H6.458C7.188 9 7.812 9.519 8.073 10.197ZM4.635 16.5H6.98L5.833 12.958L4.635 16.5ZM29.74 23.427C29.896 23.583 30 23.792 30 24C30 24.26 29.896 24.469 29.74 24.625L18.906 35.458C18.7561 35.616 18.5506 35.7093 18.333 35.718C18.2167 35.7191 18.1013 35.6966 17.9939 35.6519C17.8865 35.6072 17.7892 35.5413 17.708 35.458L11.875 29.625C11.719 29.469 11.667 29.26 11.667 29C11.667 28.792 11.719 28.583 11.875 28.427L14.271 26.083C14.375 25.927 14.583 25.823 14.844 25.823C15.052 25.823 15.26 25.927 15.417 26.083L18.333 28.948L26.198 21.083C26.3479 20.9251 26.5535 20.8318 26.771 20.823C27.031 20.823 27.24 20.927 27.396 21.083L29.74 23.427Z" fill="#333333"/>
          <rect x="19" width="29" height="13" rx="3" fill="#2E82C0"/>
          <path d="M41.6174 4.65358C42.3218 4.65358 42.8535 4.80956 43.2124 5.12153C43.5747 5.4335 43.7559 5.9199 43.7559 6.58073V10.3294H42.9156L42.6891 9.53939H42.6489C42.4912 9.74065 42.3285 9.9067 42.1608 10.0375C41.9931 10.1684 41.7985 10.2656 41.5771 10.3294C41.3591 10.3965 41.0924 10.43 40.7771 10.43C40.445 10.43 40.1481 10.3696 39.8865 10.2489C39.6248 10.1247 39.4185 9.93689 39.2676 9.68531C39.1166 9.43372 39.0411 9.11504 39.0411 8.72928C39.0411 8.15566 39.2541 7.72461 39.6802 7.43612C40.1095 7.14764 40.757 6.9883 41.6224 6.95811L42.5885 6.92289V6.63105C42.5885 6.24528 42.4979 5.97021 42.3168 5.80584C42.139 5.64148 41.8874 5.55929 41.562 5.55929C41.2836 5.55929 41.0136 5.59954 40.7519 5.68005C40.4903 5.76056 40.2353 5.85952 39.9871 5.97692L39.6047 5.14166C39.8764 4.99741 40.185 4.88001 40.5305 4.78944C40.8794 4.69887 41.2417 4.65358 41.6174 4.65358ZM42.5835 7.66758L41.8639 7.69274C41.2735 7.71287 40.8593 7.8135 40.6211 7.99465C40.3829 8.17579 40.2638 8.42402 40.2638 8.73934C40.2638 9.01441 40.346 9.21568 40.5104 9.34315C40.6748 9.46726 40.8911 9.52932 41.1595 9.52932C41.5687 9.52932 41.9075 9.41359 42.1759 9.18213C42.4476 8.94732 42.5835 8.60348 42.5835 8.15063V7.66758Z" fill="white"/>
          <path d="M37.431 9.47397C37.5853 9.47397 37.7379 9.46055 37.8889 9.43372C38.0398 9.40353 38.1774 9.3683 38.3015 9.32805V10.2237C38.1707 10.2807 38.0013 10.3294 37.7933 10.3696C37.5853 10.4099 37.3689 10.43 37.1442 10.43C36.8289 10.43 36.5454 10.378 36.2938 10.274C36.0422 10.1667 35.8427 9.98385 35.6951 9.72556C35.5475 9.46726 35.4737 9.11001 35.4737 8.6538V5.65992H34.7139V5.13159L35.529 4.71396L35.9164 3.52144H36.6611V4.75924H38.2562V5.65992H36.6611V8.6387C36.6611 8.92048 36.7316 9.13014 36.8725 9.26767C37.0134 9.4052 37.1995 9.47397 37.431 9.47397Z" fill="white"/>
          <path d="M31.6395 4.65358C32.1359 4.65358 32.562 4.75589 32.9175 4.96051C33.2731 5.16514 33.5465 5.4553 33.7377 5.831C33.9289 6.20671 34.0245 6.65621 34.0245 7.17951V7.8135H30.3061C30.3195 8.35358 30.4637 8.76953 30.7388 9.06137C31.0172 9.35321 31.4063 9.49913 31.9061 9.49913C32.2617 9.49913 32.5804 9.46559 32.8622 9.3985C33.1473 9.32805 33.4408 9.22574 33.7427 9.09156V10.0526C33.4643 10.1834 33.1809 10.279 32.8924 10.3394C32.6039 10.3998 32.2584 10.43 31.8558 10.43C31.3091 10.43 30.8277 10.3243 30.4117 10.113C29.9991 9.89832 29.6754 9.57964 29.4406 9.15697C29.2091 8.73431 29.0934 8.20933 29.0934 7.58204C29.0934 6.95811 29.1991 6.4281 29.4104 5.99202C29.6217 5.55594 29.9186 5.22384 30.301 4.99574C30.6834 4.76763 31.1296 4.65358 31.6395 4.65358ZM31.6395 5.5442C31.2671 5.5442 30.9652 5.66496 30.7338 5.90648C30.5057 6.148 30.3715 6.5019 30.3312 6.96817H32.8672C32.8639 6.68975 32.8169 6.4432 32.7263 6.22851C32.6391 6.01382 32.5049 5.8461 32.3238 5.72534C32.146 5.60458 31.9179 5.5442 31.6395 5.5442Z" fill="white"/>
          <path d="M24.1825 2.5V4.3869C24.1825 4.60494 24.1757 4.81963 24.1623 5.03096C24.1523 5.23894 24.1422 5.40163 24.1321 5.51904H24.1825C24.3334 5.27751 24.5431 5.07457 24.8114 4.9102C25.0831 4.74247 25.4303 4.65861 25.853 4.65861C26.5138 4.65861 27.0455 4.90181 27.448 5.38821C27.8506 5.87126 28.0519 6.58744 28.0519 7.53676C28.0519 8.1674 27.9596 8.69741 27.7751 9.12678C27.5906 9.55616 27.3306 9.88154 26.9952 10.1029C26.6631 10.321 26.2723 10.43 25.8228 10.43C25.4001 10.43 25.058 10.3528 24.7963 10.1985C24.538 10.0409 24.3351 9.85806 24.1875 9.65008H24.1019L23.8856 10.3294H23V2.5H24.1825ZM25.541 5.61967C25.2056 5.61967 24.9389 5.68676 24.741 5.82094C24.5464 5.95512 24.4055 6.15639 24.3183 6.42475C24.2311 6.68975 24.1858 7.02688 24.1825 7.43612V7.54179C24.1825 8.16237 24.2797 8.63703 24.4743 8.96577C24.6722 9.29451 25.0311 9.45888 25.5511 9.45888C25.9637 9.45888 26.2807 9.29283 26.5021 8.96074C26.7235 8.62529 26.8342 8.1456 26.8342 7.52166C26.8342 6.89102 26.7235 6.41636 26.5021 6.09768C26.284 5.77901 25.9637 5.61967 25.541 5.61967Z" fill="white"/>
      </svg>
  );
};

SpellCheckIcon.displayName = "SpellCheckIcon";
export default SpellCheckIcon;
