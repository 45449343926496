import { useQuery } from "react-query";
import { apiGet } from "../api/api-calls";

export type BynderCredentials = {
  bynder_domain: string;
  bynder_api_key: string;
  bynder_token: string;
  timestamp: number;
  signature: string;
};

type BynderPortal = {
  url: string;
}

type BynderAuthentication = {
  getAccessToken(): string;
  hideLogout: boolean;
}
type BynderTheme = {
  colorButtonPrimary: string;
}

export type BynderParameters = {
  language: string,
  portal: BynderPortal;
  assetTypes: Array<string>;
  authentication: BynderAuthentication;
  theme: BynderTheme;
  mode: string;
  onSuccess: Function;
}

export function useBynderCredentials() {
  const fifteenMinutesInMS = 1000 * 60 * 15;
  const { data } = useQuery(["bynder"], fetchBynderCredentials, {
    staleTime: fifteenMinutesInMS,
    refetchOnWindowFocus: false,
  });
  return data;
}

const fetchBynderCredentials = () =>
  apiGet<{ credentials: BynderCredentials }>(`/api/bynder/credentials`);
