import React from "react";
import { ColumnInstance } from "react-table";
import { FontAwesome } from "../../components/icons/font-awesome";

export const generateSortingIndicator = (column: ColumnInstance<any>) => {
  return column.canSort ? (
    column.isSorted ? (
      column.isSortedDesc ? (
        <FontAwesome name="sort-up" />
      ) : (
        <FontAwesome name="sort-down" />
      )
    ) : (
      <FontAwesome name="sort" stylingClass="has-text-grey-light" />
    )
  ) : (
    ""
  );
};
