import React from "react";
import { AttributeCriterion } from "../../types";
import { Attributes } from "../criteria-list-editor";

type Props = {
  value: AttributeCriterion;
  handleAttributeChange: (event: { target: { name: string; value: string } }) => void;
  attributes: Attributes[];
};

export const AttributeField: React.FC<Props> = ({ value, handleAttributeChange, attributes }) => {
  return (
    <select
      name="attributeName"
      value={value.attributeName}
      onChange={handleAttributeChange}
      className="is-text-overflow"
    >
      {!value.attributeName && <option>---</option>}
      {attributes?.map(({ technicalName, displayName }) => (
        <option key={technicalName} value={technicalName}>
          {displayName}
        </option>
      ))}
    </select>
  );
};
