import { useContext } from "react";
import { useQuery } from "react-query";
import { apiGet } from "../api/api-calls";
import { WizardContext } from "../context/wizard-context";
import {Channel, LicenceConstants} from "../types";
import {useClientContext} from "./use-client-context";

type Template = {
  templateId: string;
  technicalName: string;
  version: number;
  activated: boolean;
  displayName: string;
  displayOrder: number;
  description: string;
  acceptedSubscriptions: string[];
  isSubjectPresent: boolean;
  previewImageUrl: string;
  customizationsUrl: string;
  zipUrl: string;
  body?: string;
  subject: string;
  replyTo: string;
  sender: string;
  linkedDraftId?: string;
};

type FetchedTemplates = {
  templates: Template[];
};

type TemplatesReturn = {
  fetchedTemplates: FetchedTemplates;
  isLoading: boolean;
};

const EmptyAttributes: TemplatesReturn = {
  fetchedTemplates: { templates: [] },
  isLoading: true,
};

export const useTemplates = (channel: "EMAIL" | "SMS" | "IN-DEFINITION"): TemplatesReturn => {
  const {clientContext} =useClientContext();
  const { data, isLoading } = useQuery(["templates", channel,clientContext], templatesFetcher, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });
  const { fetchedTemplates } = data || EmptyAttributes;
  return { isLoading, fetchedTemplates };
};

async function templatesFetcher(_: string, channel: Channel,clientContext:LicenceConstants) {
  let fetchedTemplates: FetchedTemplates;
  const enabledTemplateWithActito = clientContext?.enabledTemplateWithActito;
  if(enabledTemplateWithActito && channel === "EMAIL") {
    fetchedTemplates = await apiGet<FetchedTemplates>(`/api/templates/actito/${channel}`);
  }
  else{
    fetchedTemplates = await apiGet<FetchedTemplates>(`/api/templates/${channel}`);
  }
  return { fetchedTemplates };
}

export const useTemplate = (
  templateId?: string,
  campaignId?: string
): {
  template?: Template;
  isLoading: boolean;
} => {
  const {channel} = useContext(WizardContext);
  const {clientContext} =useClientContext();
  const { data, isLoading } = useQuery(["templates", templateId,campaignId,clientContext,channel], templateFetcher, {
    staleTime: 5 * 60 * 1000,
    enabled: !!templateId,
    refetchOnWindowFocus: false,
  });
  return { isLoading, template: data };
};

async function templateFetcher(queryKey: string, templateId: string,campaignId: string,clientContext:LicenceConstants,channel:string) {
  let template: Template;

  const enabledTemplateWithActito = clientContext?.enabledTemplateWithActito;
  if(enabledTemplateWithActito && channel === "EMAIL") {
    template = await apiGet<Template>(`/api/template/actito/${templateId}/campaign/${campaignId}`);
  }
  else{
    template = await apiGet<Template>(`/api/template/${templateId}`);
  }
  return template;
}
