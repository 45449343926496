import React, {ChangeEvent} from "react";
import { FieldError } from "react-hook-form";
import cx from "classnames";
import { CloudinaryButton } from "./cloudinary-button";

import styles from "./input-field.module.scss";
import { GenericMessage } from "./generic-message";
import { BynderButton } from "./bynder-button";
import {Substitutions} from "../types";

let labelForId = 1;
export function useInputId(name: string): string {
  const labelId = React.useRef(labelForId++);
  return `${name}${labelId.current}`;
}

export type FieldProps = {
  name: string;
  register: (ref: Element | null) => void;
  disabled?: boolean;
  label?: string;
  type?: string;
  errors?: { [key: string]: FieldError | undefined };
  className?: string;
  placeholder?: string;
  customType?: string;
  urlValue?: string;
  value?: string;
  autoComplete?: string;
  handleClick?: React.MouseEventHandler<HTMLElement>;
  buttonID?: number | undefined;
  defaultValues?: string | object;
  displayCloudinaryButton?: boolean;
  displayBynderButton?: boolean;
  setDmaName?: (name: string) => void;
  isButtonDisabled?: boolean;
  isCorrected?: boolean;
  setIsGrammarCorrected? :  (value: (((prevState: Array<string>) => Array<string>) | Array<string>)) => void;
  setInputValue?:(value: (((prevState: Substitutions) => Substitutions) | Substitutions)) => void
  inputValue?: Substitutions
};

export const InputField: React.FC<FieldProps> = ({
  name,
  label,
  errors,
  type = "text",
  register,
  disabled,
  className,
  children,
  placeholder,
  customType,
  autoComplete,
  displayCloudinaryButton,
  displayBynderButton,
  setDmaName,
  isCorrected,
   setIsGrammarCorrected,
    setInputValue,
    inputValue
}) => {
  const error = errors ? errors[name] : undefined;
  const inputId = useInputId(name);

  const handleChange = (event:  ChangeEvent<{ value: string }>) => {

    const newSubstitutions = { ...inputValue };
    if(inputValue !==undefined)
    for (const [field] of Object.entries(inputValue)) {
      if(field===name){
        const value = event.target.value;
        if(value === ''){
          newSubstitutions[field]= '';
        }
        else{
          newSubstitutions[field]= value;
        }
      }
    }
    setInputValue?.(newSubstitutions);
    if(isCorrected) {
      setIsGrammarCorrected?.(prev => prev.filter(x => x !== name));
    }

  }

  return (
    <>
      <div className={cx(className, "field is-horizontal")}>
        <div className="field-label is-normal">
          <label htmlFor={inputId} className={cx("label", { "has-text-grey-light": disabled })}>
            {label || name}
          </label>
        </div>
        <div className="field-body ">
          <div className={cx(`field is-expanded ${customType === "imageUrl" && "has-addons"} `)}>
            <div className={cx(`control ${customType === "imageUrl" && "is-expanded"}`)}>
              {(() => {
                switch (customType) {
                  case "longText":
                    return (
                        <div
                             style={{padding:'10px'}}>
                      <textarea
                          onChange={handleChange}
                        id={inputId}
                        ref={register}
                        spellCheck="true"
                        {...{ name, type, disabled, placeholder, autoComplete }}
                        className={cx("textarea is-normal", {
                          "is-danger": !!error,
                        })}
                      />
                        </div>
                    );
                  case "shortText":
                    return (
                        <div
                          style={{padding:'10px'}}>
                      <input
                          onChange={handleChange}
                          id={inputId}
                          ref={register}
                          spellCheck="true"
                          {...{ name, type, disabled, placeholder, autoComplete }}
                          className={cx("input is-normal", {
                            "is-danger": !!error,
                          })}
                      />
                        </div>
                    );
                  case "imageUrl":
                    return (
                        <div className={cx(className, "field is-normal")}>
                      <input
                        id={inputId}
                        ref={register}
                        {...{ name, type, disabled, placeholder, autoComplete }}
                        className={cx("input is-normal is-expendable", {
                          "is-danger": !!error,
                        })}
                      />
                          </div>
                    );
                  default:
                    return (
                      <input
                        id={inputId}
                        ref={register}
                        spellCheck="true"
                        {...{ name, type, disabled, placeholder, autoComplete }}
                        className={cx("input is-normal", {
                          "is-danger": !!error,
                        })}
                      />
                    );
                }
              })()}
            </div>
            {displayCloudinaryButton ?
              <>
                {children && <div className="control">{children}</div>}
                {customType === "imageUrl" && <CloudinaryButton {...{ setDmaName, name }} />}
              </>
              : null }
            {displayBynderButton ?
              <>
                {children && <div className="control">{children}</div>}
                {customType === "imageUrl" && <BynderButton {...{ setDmaName, name }} />}
              </>
              : null}
          </div>
        </div>
      </div>
      {error !== undefined ? (
        error.type === "imageWeight" || error.type === "imageNoWeight" ? (
          <GenericMessage
            translationKey={CLOUDINARY_ERRORS[error.type][0]}
            iconName={CLOUDINARY_ERRORS[error.type][1]}
            themeColor={CLOUDINARY_ERRORS[error.type][2]}
            messageSize="is-small"
          />
        ) : (
          <div className="field">
            <p className="help is-danger ">{error.message}</p>
          </div>
        )
      ) : null}
    </>
  );
};

const CLOUDINARY_ERRORS: Record<string, string[]> = {
  imageWeight: ["customize.cloudinary.image.weight.error", "exclamation-triangle", "is-danger"],
  imageNoWeight: ["customize.cloudinary.image.weight.unknown", "exclamation", "is-warning"],
};

export const Checkbox: React.FC<FieldProps> = ({ name, errors, register, disabled, className, children }) => {
  const error = errors ? errors[name] : undefined;
  const inputId = useInputId(name);

  return (
    <div className={cx(className, "field")}>
      <div className="control">
        <label className={"checkbox " + styles.checkbox} htmlFor={inputId}>
          <input
            ref={register}
            id={inputId}
            {...{ name, type: "checkbox", disabled }}
            className={cx("checkbox", { "is-danger": !!error })}
          />

          {children}
        </label>
      </div>
      {error !== undefined ? <p className="help is-danger">{error.message}</p> : null}
    </div>
  );
};

type SelectProps = FieldProps & {
  entries: { label?: string; value: string }[];
  emptyLabel?: string;
};

export const Select: React.FC<SelectProps> = ({
  name,
  label,
  errors,
  register,
  disabled,
  className,
  entries,
  emptyLabel,
  children,
}) => {
  const error = errors ? errors[name] : undefined;
  const inputId = useInputId(name);

  return (
    <div className={cx(className, "field is-horizontal")}>
      <div className="field-label is-normal">
        <label className={cx("label", { "has-text-grey-light": disabled })} htmlFor={inputId}>
          {label || name}
        </label>
      </div>
      <div className="field-body">
        <div className={cx("field is-expanded")}>
          <div className="control">
            <label className={"checkbox " + styles.checkbox}>
              <div className="select">
                <select name={name} id={inputId} ref={register} disabled={disabled}>
                  {emptyLabel && <option value="">{emptyLabel}</option>}
                  {entries.map(({ value, label: optionLabel }) => (
                    <option key={value} value={value}>
                      {optionLabel || value}
                    </option>
                  ))}
                </select>
              </div>
              {children}
            </label>
          </div>
          {children && <div className="control">{children}</div>}
          {error !== undefined ? <p className="help is-danger">{error.message}</p> : null}
        </div>
      </div>
    </div>
  );
};
