import React from 'react';
import SpellCheckIcon from "./icons/spell-check-icon";
import {Tooltip} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import SpellCheckIconCorrected from "./icons/spell-check-icon-corrected";
import SpellCheckIconDisabled from "./icons/spell-check-icon-disabled";
import {useTranslation} from "../hooks/use-translation";

interface SpellCheckProps {
  handleClick?: React.MouseEventHandler<HTMLElement>;
  isLoading?: boolean;
    isCorrected?: boolean;
  disclaimer?: boolean;
  setDisclaimer?: (v:boolean)=>void;
    hasAcceptedPolicy? :boolean,
    setHasAcceptedPolicy? :(v:boolean )=> void
    contactOpenAi?: (fieldName: string) => void ;
    fieldName: string;
    handleDisclaimer:(fieldName:string)=>void;
    anyFieldIsCorrecting: boolean;
    isEmptyValue: boolean;

}
function SpellCheck({ handleClick, isLoading ,isCorrected,anyFieldIsCorrecting,isEmptyValue}:SpellCheckProps) {
  const { translate } = useTranslation();
  const loading = translate("customize.spellcheck.loading");
  const title = translate("customize.spellcheck.title");
  const warningText = "Warning empty text ";
  return (
      <div style={{width:"54px",display:"grid",placeContent:"start center",paddingTop:"10px"}} >

        <Tooltip title={isLoading?String(loading) : isEmptyValue?String(warningText) : String(title)}>

          <IconButton
              size="small"
              disabled={isLoading || anyFieldIsCorrecting || isEmptyValue}
              onClick={handleClick}
          >
            {isLoading ? (
                <CircularProgress size={24} />
            ) : (
                isCorrected && anyFieldIsCorrecting || !isCorrected && anyFieldIsCorrecting || isEmptyValue?<SpellCheckIconDisabled/>
                    :
                (
                    !isCorrected? <SpellCheckIcon/>: <SpellCheckIconCorrected/>
              )
            )
            }
          </IconButton>
        </Tooltip>

      </div>
  );
}

export default SpellCheck;