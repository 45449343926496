// This picker is a PITA, as it always send the selected date in the local timezone, thus saving the saved date minus two hour. this will fix it.

export const toISOIgnoreTimezone = (inputDate: Date) => {
  return (
    inputDate.getFullYear() +
    "-" +
    ("0" + (inputDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + inputDate.getDate()).slice(-2) +
    "T00:00:00.000Z"
  );
};
