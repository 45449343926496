import React, {useContext, useEffect, useState} from "react";
import styles from "./card.module.scss";
import cx from "classnames";
import { apiGetImage} from "../api/api-calls";
import {useClientContext} from "../hooks/use-client-context";
import {WizardContext} from "../context/wizard-context";
import {Loader} from "./loader";
import {useTranslation} from "../hooks/use-translation";

type Props = {
    templateId: string;
    displayName?: string;
    description: string;
    onSelect: (templateName: string) => void;
    selected: boolean;
    previewImageUrl: string;
    activated?: boolean;
    disabled?: boolean;
    isSubjectPresent?: boolean;
    acceptedSubscriptions?: string[];
    isSelectionDisabled?: boolean,
    setIsSelectionDisabled: (previousValue: boolean) => void;
};

const TemplateCard: React.FC<Props> = ({
                                           templateId,
                                           displayName,
                                           description,
                                           onSelect,
                                           selected,
                                           previewImageUrl,
                                           activated,
                                           acceptedSubscriptions,
                                           isSelectionDisabled,
                                           setIsSelectionDisabled,
                                           isSubjectPresent,
                                       }) => {
    const {clientContext} = useClientContext();
    const isEnabledTemplateWithActito = clientContext?.enabledTemplateWithActito;
    const { channel } = useContext(WizardContext);
    const [isLoading,setIsLoading] = useState<boolean>(true);
    const { translate } = useTranslation();
    const handleClick = () => {
        onSelect(templateId);
        setIsSelectionDisabled(!isSelectionDisabled);
    };
    const [img, setImg] = useState<String>("");

    if(isEnabledTemplateWithActito  && channel === "EMAIL") {
        previewImageUrl = `/api/template/actito/${templateId}/image`
    }
    const fetchImage = async () => {
        const res = await apiGetImage<Response>(previewImageUrl);
        setIsLoading(false);
        setImg(res);
    };

    useEffect(() => {
        if(isEnabledTemplateWithActito && channel === "EMAIL") {
            fetchImage();
        }
        else{
            setIsLoading(false);
        }
    }, [isEnabledTemplateWithActito, channel]);

    if (!activated) {
        return null;
    }

    return (
        <div
            className={cx("card", `${styles.card}`, {
                [styles.selected]: selected,
            })}
            onClick={!isSubjectPresent && channel === "EMAIL" && isEnabledTemplateWithActito ?
                undefined : isSelectionDisabled ? undefined : handleClick}
        >
            <div className="card-image">
                <figure className="image is-4by5">
                    {!isLoading?
                        isEnabledTemplateWithActito && channel === "EMAIL"?
                        img &&  <img src={`data:image/png;base64,${img}`} alt={displayName}/>
                            :
                            <img src={previewImageUrl} alt={displayName} />
                        :<Loader/>
                    }
                </figure>
            </div>
            <div className="card-content">
                <p className="subtitle is-4">{displayName}</p>
                <p className="content">{description}</p>
            </div>
            <div className="box is-shadowless">
                <div className="tags">
                    {acceptedSubscriptions?.map((subscription, index) => {
                        return (
                            <span key={index} className="tag">
                {subscription}
              </span>
                        );
                    })}
                </div>
            </div>
            {!isSubjectPresent && channel === "EMAIL" && isEnabledTemplateWithActito &&
              <div className={styles.disabledOverlay}>
                <span>{translate("wizard.template.missing.subject")}</span>
              </div>
            }
        </div>
    );
};

export default TemplateCard;
