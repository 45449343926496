import React from "react";
import { useNotification } from "../../hooks/use-notification";
import { useEffect } from "react";
import { ToastContainer, toast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cx from "classnames";
import styles from "./notification.module.scss";
import { FontAwesome } from "../icons/font-awesome";

const toastConfiguration: ToastOptions = {
  position: "top-right",
  autoClose: 3500,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export type Status = "success" | "warning" | "error" | "idle";
const notificationTheme: Record<Status, string> = {
  success: "is-primary",
  warning: "is-warning",
  error: "is-danger",
  idle: "",
};

export const Notification: React.FC = () => {
  const { notification, removeNotification } = useNotification();

  const { message, props = { status: "idle" } } = notification;

  useEffect(() => {
    if (message) {
      toast(message, {
        ...toastConfiguration,
        ...{
          className: cx("message", styles.messageWrapper, {
            [notificationTheme[props.status]]: props.status,
          }),
          bodyClassName: cx("message-body", styles.messageBody),
        },
      });
      removeNotification();
    }
  }, [message, props.status, removeNotification]);

  return <ToastContainer closeButton={CloseButton} />;
};

const CloseButton = () => {
  const { removeNotification } = useNotification();
  return (
    <div onClick={removeNotification} style={{ margin: "0.5rem 1rem" }}>
      <FontAwesome name="times" />
    </div>
  );
};
