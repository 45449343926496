import React, { useState } from "react";
import { Link, RouteComponentProps } from "@reach/router";
import { useForm } from "react-hook-form";
import { unsafeApiPost } from "../api/api-calls";
import { useTranslation } from "../hooks/use-translation";
import { GenericMessage } from "../components/generic-message";

type ResetPasswordResponse = {
  resetUrl: string;
};

export const SendEmailPassword: React.FC<RouteComponentProps> = () => {
  const { translate } = useTranslation();
  const { register, handleSubmit } = useForm();

  const [feedbackState, setFeedbackState] = useState<string>("");

  const onSubmit = async (data: any) => {
    const { user } = data;
    try {
      await unsafeApiPost(`api/generate-new-password/${user}`);
      setFeedbackState("success");
    } catch (e) {
      setFeedbackState("error");
    }
  };
  return (
    <div className="column is-half-desktop is-three-quarters mx-auto">
      <div className="panel is-primary">
        <p className="panel-heading ">{translate("resetPassword.title")}</p>
        <div>
          <form className="box is-shadowless" onSubmit={handleSubmit(onSubmit)}>
            <div className="field">
              <label className="label" htmlFor="send">
                {translate("resetPassword.username")} :
              </label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  name="user"
                  id="send"
                  ref={register}
                  placeholder={translate("resetPassword.username")}
                />
              </div>
            </div>
            <div className="field">
              <div className="control has-text-right">
                <button type="submit" className="button is-primary" onClick={handleSubmit(onSubmit)}>
                  {translate("submit")}
                </button>
              </div>
            </div>
            {feedbackState === "success" && (
              <>
                <p className="is-size-6 has-text-centered has-text-weight-bold">{translate("resetPassword.thanks")}.</p>
                <p className="is-size-6 has-text-centered has-text-weight-bold">
                  <Link to="/">{translate("general.backToHomepage")}.</Link>
                </p>
              </>
            )}
            {feedbackState === "error" && <GenericMessage translationKey="general.error" />}
          </form>
        </div>
      </div>
    </div>
  );
};
