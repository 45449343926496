import {useQuery} from "react-query";
import {apiGet} from "../api/api-calls";
import {Channel} from "../types";

type CampaignTemplateInformation = {
  templateId?: string;
  customizations?: { field: string; value: string }[];
  isLoading: boolean;
  refetch: () => void;
};

type FetchedCampaignTemplateInformation = {
  templateId: string;
  customizations: { field: string; value: string }[];
};

const emptyCampaignTemplateInformation = {
  templateId: undefined,
  customizations: undefined,
  isLoading: true,
};

export function useCampaignTemplateInformation(campaignId?: string, channel?: Channel): CampaignTemplateInformation {
  const { data, isLoading, refetch } = useQuery(
    ["CampaignTemplateInformation", campaignId, channel],
    campaignTemplateInformationFetcher,
    {
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
    }
  );
  const { templateId, customizations } = data || emptyCampaignTemplateInformation;
  return { templateId, customizations, isLoading, refetch };
}

async function campaignTemplateInformationFetcher(_: string, campaignId?: string, channel?: Channel) {
  if (!campaignId)
    return {
      templateId: undefined,
      customizations: undefined,
    };
    return await apiGet<FetchedCampaignTemplateInformation>(`/api/campaigns/${campaignId}/template?channel=${channel}`);
}
