import * as yup from "yup";
import { addValidations, TemplateFieldsConfiguration } from "./generate-default-values";

export const generateYupSchema = (config: TemplateFieldsConfiguration) => {
  let validations: Record<string, yup.StringSchema> = {};
  const validatedConfig = addValidations(config);
  validatedConfig.sections.map((section) => {
    section.fields.map((field) => {
      if (field.validation) {
        validations[field.fieldName] = field.validation;
      }
      return field;
    });
    return section;
  });
  return yup.object().shape(validations);
};
