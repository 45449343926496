import React, {useCallback, useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {InputField} from "../components/input-field";
import {yupResolver} from "@hookform/resolvers";
import {Substitutions} from "../types";
import {generateYupSchema} from "../helpers/yup-schema";
import {useTemplateConfiguration} from "../hooks/use-template-config";
import {Loader} from "../components/loader";
import {useTranslation} from "../hooks/use-translation";
import {CloudinaryCredentials, useCloudinaryCredentials,} from "../hooks/use-cloudinary-credentials";
import styles from "./customize.module.scss";
import emojiRegex from "emoji-regex";
import {WizardContext} from "../context/wizard-context";
import {BynderParameters, useBynderCredentials,} from "../hooks/use-bynder-credentials";
import SpellCheck from "../components/spellCheck";
import {Modal} from "../components/modal/modal";
import Cookies from 'js-cookie';
import {ButtonsModalFooterSpellCheck} from "../components/modal/footer-buttons-modal-speelCheck";
import {useTemplateFormConfiguration} from "../hooks/use-template-form-config";
import {useTemplateFormConfigurationForSMS} from "../hooks/use-template-form-config-for-sms";
import {TemplateFieldsConfiguration} from "../helpers/generate-default-values";
import {useClientContext} from "../hooks/use-client-context";
import {EmailInformation} from "../components/customization-step/email-information";

const LIMIT_IMAGE_SIZE_IN_BYTES = 1258291;

type CloudinaryImageData = {
    assets: {
        bytes: number;
        context: Object[];
        created_at: string;
        derived?: { secure_url: string }[];
        format: string;
        height: number;
        public_id: string;
        resource_type: string;
        secure_url: string;
        tags: string[];
        type: "upload";
        url: string;
        version: number;
        width: number;
    }[];
};

type CloudinaryMediaLibrary = {
    show: (option?: { folder: { path: string; ressource_type: string } }) => void;
    hide: () => void;
};

type BynderMediaLibrary = {
    open: () => void;
};

declare var cloudinary: {
    createMediaLibrary: (
        credentails: CloudinaryCredentials & { multiple: boolean },
        callback: { insertHandler: (data: CloudinaryImageData) => void }
    ) => CloudinaryMediaLibrary;
};

declare global {
    interface Window {
        ml?: CloudinaryMediaLibrary;
        BynderCompactView?: BynderMediaLibrary;
        BynderCompactViewConfig?: BynderParameters;
    }
}

type Props = {
    handleNewOpenAiSuggest?: (substitutions: Substitutions) => any;
    handleNewSubstitions: (substitutions: Substitutions) => any;
    templateId?: string;
    campaignId?: string
    substitutions: Substitutions;
    setSubstitutions: (subs:Substitutions) => void,
    isOpenAiActivated?: boolean;
    setIsButtonDisabled? :(v:boolean )=> void
    isButtonDisabled?: boolean
};
const emojiRegexp = emojiRegex();

function removeHtmlTags(substitution: string) {
    return substitution.replace(/<[^>]+>/g, "");
}

function removeEmojis(substitution: string) {
    return substitution.replace(emojiRegexp, "");
}

export const Customize: React.FC<Props> = ({
                                               handleNewSubstitions,
                                               handleNewOpenAiSuggest,
                                               templateId,
                                               campaignId,
                                               substitutions,
                                               isOpenAiActivated,
                                               setIsButtonDisabled,
                                               isButtonDisabled
                                           }) => {
    const {translate} = useTranslation();
    const {channel} = useContext(WizardContext);
    const {clientContext} = useClientContext();
    const {credentials: cloudinaryCredentials} = useCloudinaryCredentials() || {};
    const {credentials: bynderCredentials} = useBynderCredentials() || {};

    // states
    const [imageUrl, setImageUrl] = useState<undefined | string>("");
    const [insertedImageProperties, setInsertedImageProperties] = useState<CloudinaryImageData>();
    const [dmaName, setDmaName] = useState<string>("");
    const [displayCloudinaryButton, setDisplayCloudinaryButton] = useState<boolean>(false);
    const [displayBynderButton, setDisplayBynderButton] = useState<boolean>(false);
    const [isContactingOpenAi, setIsContactingOpenAi] = React.useState<Array<string>>([]);
    const [isGrammarCorrected, setIsGrammarCorrected] = React.useState<Array<string>>([]);
    const [hasAcceptedPolicy, setHasAcceptedPolicy] = useState(false);
    const [disclaimer, setDisclaimer] = useState(false);
    const [inputValue, setInputValue] =  useState<Substitutions>({});
    const [fieldNameInEdition, setFieldNameInEdition] = React.useState<null | string>(null)

    const { templateCustomizations:templateCustomizationsForSMS} = useTemplateFormConfigurationForSMS(templateId,channel);
    const { templateCustomizations:templateCustomizationsForEMAIL} = useTemplateFormConfiguration(campaignId, templateId, channel);
    const { templateCustomizations:templateCustomizationsForEMAILWithoutActito}   = useTemplateConfiguration(templateId)
    const templateCustomizations: TemplateFieldsConfiguration | undefined =
        !clientContext?.enabledTemplateWithActito && channel === "EMAIL"
            ? templateCustomizationsForEMAILWithoutActito
            : channel === "EMAIL"
                ? templateCustomizationsForEMAIL :templateCustomizationsForSMS;
    const {
        register,
        handleSubmit,
        getValues,
        errors,
        formState,
        reset,
        setValue,
        setError,
    } = useForm<Substitutions>({
        resolver: templateCustomizations
            ? yupResolver(generateYupSchema(templateCustomizations))
            : undefined,
    });
    const fieldProps = {register, errors};

    const cleanInputs = useCallback(
        (substitution: string) => {
            let cleanedInput = removeHtmlTags(substitution);
            console.log("inputCleaned",cleanedInput);
            if (channel === "SMS") {
                cleanedInput = removeEmojis(cleanedInput);
            }
            return cleanedInput;
        },
        [channel]
    );

    // useEffect
    useEffect(() => {
        if (substitutions === undefined) return;
        setInputValue(substitutions);
        reset(substitutions);
    }, [reset, substitutions]);

    useEffect(() => {
        if (channel === "SMS") return;
        if (!cloudinaryCredentials) return;
        if (window.ml !== undefined) return;
        if (
            cloudinaryCredentials.cloud_name &&
            cloudinaryCredentials.username &&
            cloudinaryCredentials.api_key
        ) {
            setDisplayCloudinaryButton(true);
        }
        window.ml = cloudinary.createMediaLibrary(
            {...cloudinaryCredentials, multiple: false},
            {
                insertHandler: function (data: CloudinaryImageData) {
                    setInsertedImageProperties(data);
                    setImageUrl(
                        data.assets[0].derived
                            ? data.assets[0].derived[0].secure_url
                            : data.assets[0].secure_url
                    );
                },
            }
        );
        return () => {
            window.ml = undefined;
        };
    }, [channel, cloudinaryCredentials]);

    useEffect(() => {
        if (channel === "SMS") return;
        if (!bynderCredentials) return;
        if (window.BynderCompactViewConfig !== undefined) return;

        if (
            bynderCredentials.bynder_domain &&
            (bynderCredentials.bynder_token || bynderCredentials.bynder_api_key)
        ) {
            window.BynderCompactViewConfig = {
                language: "en_US",
                portal: {
                    url: bynderCredentials?.bynder_domain,
                },
                assetTypes: ["image"],
                authentication: {
                    getAccessToken: () => bynderCredentials?.bynder_token,
                    hideLogout: true,
                },
                theme: {
                    // colorButtonPrimary: "#3380FF"
                    colorButtonPrimary: "#a52982",
                },
                mode: "SingleSelectFile",

                onSuccess: (
                    assets: any,
                    additionalInfo: { selectedFile: { url: any } }
                ) => {
                    const data = {
                        assets,
                    };
                    setInsertedImageProperties(data);
                    setImageUrl(additionalInfo.selectedFile.url);
                },
            };
            setDisplayBynderButton(true);
        }
        return () => {
            window.BynderCompactViewConfig = undefined;
        };
    }, [channel, bynderCredentials]);

    useEffect(() => {
        if (insertedImageProperties) {
            if (insertedImageProperties.assets[0].bytes > LIMIT_IMAGE_SIZE_IN_BYTES) {
                setError(dmaName, {
                    type: "imageWeight",
                });
                setValue(dmaName, "", {shouldDirty: true});
                return;
            }
            if (insertedImageProperties.assets[0].derived) {
                setError(dmaName, {
                    type: "imageNoWeight",
                });
                setValue(dmaName, "", {shouldDirty: true});
                return;
            }
            setValue(dmaName, imageUrl, {shouldDirty: true});
        }
    }, [
        dmaName,
        imageUrl,
        insertedImageProperties,
        setError,
        setValue,
        translate,
    ]);

    const handlePreview = useCallback(async () => {
        const values = getValues();
        let newSubstitutions: Substitutions = {};
        for (let [key, value] of Object.entries(values)) {
            value = cleanInputs(value);
            if (value.trim()) {
                newSubstitutions[key] = value;
            }
        }
        await handleNewSubstitions(newSubstitutions);
        setIsGrammarCorrected((prevState) => []);
        reset(newSubstitutions);
    }, [cleanInputs, getValues, handleNewSubstitions, reset]);

    const contactOpenAi = useCallback (async (fieldName: string) => {
        setIsContactingOpenAi((prevState) => [...prevState, fieldName]);
        const values = getValues();
        const updatedSubstitution = await handleNewOpenAiSuggest?.({[fieldName]: values[fieldName]});
          let localeSubstitutions: Substitutions = {};
        setTimeout(() => {
          setIsContactingOpenAi((prevState) =>
              prevState.filter((name) => name !== fieldName)
          );

            for (let [key, value] of Object.entries(values)) {
                if(key===fieldName){
                    localeSubstitutions[key] = cleanInputs(updatedSubstitution[key]);
                }
                else {
                    value = cleanInputs(value);
                    if (value.trim())
                        localeSubstitutions[key] = value;
                }
            }

            reset(localeSubstitutions);
        }, 1000);

      setIsGrammarCorrected((prevState) => [...prevState, fieldName]);
    },[cleanInputs, getValues, reset, handleNewOpenAiSuggest]);

    const handleOpenAiButtonClick = useCallback(
         (fieldName) => {
                setFieldNameInEdition(fieldName)
             if(Cookies.get("OpenAiCookie")){
                 contactOpenAi(fieldName);
                 setIsButtonDisabled?.(false);
             }
             else{
                 if (!hasAcceptedPolicy) {
                     setDisclaimer(true);
                 }
                 else {
                     contactOpenAi(fieldName);
                     setIsButtonDisabled?.(false);
                 }
             }

        }, [hasAcceptedPolicy, contactOpenAi, setIsButtonDisabled]);

    const handleDisclaimer =  (fieldName:string|null) => {
        if(!fieldName) return;
        Cookies.set('OpenAiCookie', "openai",{expires:91});
         contactOpenAi?.(fieldName || '');
        setDisclaimer?.(false);
        setHasAcceptedPolicy?.(true)
    };

    const handleModalClose = () => {
        setDisclaimer?.(false);
        setHasAcceptedPolicy?.(false)
    };

    if (templateCustomizations === undefined) {
        return <Loader/>;
    }

    const getIsButtonDisabled = () => {
        if(isGrammarCorrected.length){
            return false
        }
        return !formState.isDirty;

    }

    const getValueByKey = (keyToFind: string): string | undefined => {
        return inputValue[keyToFind];
    };

    return (
        <>
            <div className={`${styles.previewHeader} level is-sticky`}>
                <div className="level-left">
                    <div className="level-item">
                        <h2 className="title is-4">
                            {translate("wizard.customize.title")}
                        </h2>
                    </div>
                </div>
                <div className="level-right">
                    <div className="level-item">
                        <div className="field is-grouped is-grouped-right">
                            <p className="control">

                                <button
                                    className="button is-primary"
                                    type="button"
                                    disabled={getIsButtonDisabled()}
                                    onClick={handleSubmit(handlePreview)}
                                >
                                    {translate("customize.button")}
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {clientContext?.enabledTemplateWithActito && channel === "EMAIL" &&
              <EmailInformation
                isGrammarCorrected={isGrammarCorrected}
                inputValue={inputValue}
                register={register}
                channel={channel}
                handleOpenAiButtonClick={handleOpenAiButtonClick}
                isOpenAiActivated={isOpenAiActivated}
                isContactingOpenAi={isContactingOpenAi}
                getValueByKey={getValueByKey}
                contactOpenAi={contactOpenAi}
                handleDisclaimer={handleDisclaimer}
                setInputValue={setInputValue}
              />
            }

            {templateCustomizations.sections.map(
                ({label: sectionLabel, fields}) => {
                    return (
                        <div
                            key={sectionLabel}
                            className="box is-shadowless is-paddingless">
                            <h2 className="title is-5">{sectionLabel}</h2>
                            {fields.map(({fieldName, label, placeholder, type}, index) => (
                                <React.Fragment key={`${sectionLabel}_${fieldName}`}>
                                    <div className={"field is-horizontal"}>
                                        <div className="field-body">
                                            <InputField
                                                buttonID={index}
                                                name={fieldName}
                                                urlValue={imageUrl}
                                                customType={type}
                                                isButtonDisabled={isButtonDisabled}
                                                isCorrected={isGrammarCorrected.includes(fieldName)}
                                                inputValue={inputValue}
                                                setInputValue={setInputValue}
                                                {...fieldProps}
                                                {...{
                                                    label,
                                                    placeholder,
                                                    displayCloudinaryButton,
                                                    displayBynderButton,
                                                    setDmaName,
                                                    setIsGrammarCorrected,
                                                }}
                                            />
                                            {["longText", "shortText"].includes(type) &&
                                                isOpenAiActivated && channel.includes("EMAIL") && (
                                                    <SpellCheck
                                                        handleClick={() => handleOpenAiButtonClick(fieldName)}
                                                        anyFieldIsCorrecting={!!isContactingOpenAi.length}
                                                        isCorrected={isGrammarCorrected.includes(fieldName)}
                                                        isLoading={isContactingOpenAi.includes(fieldName)}
                                                        isEmptyValue={getValueByKey(fieldName)?.trim() === ''}
                                                        contactOpenAi={contactOpenAi}
                                                        handleDisclaimer={handleDisclaimer}
                                                        {...{
                                                            hasAcceptedPolicy,
                                                            setHasAcceptedPolicy,
                                                            disclaimer,
                                                            setDisclaimer,
                                                            fieldName
                                                        }}
                                                    />
                                                )}

                                        </div>
                                    </div>

                                </React.Fragment>
                            ))}
                        </div>
                    );
                }
            )}

            <Modal
                isShown={!hasAcceptedPolicy && !!disclaimer}
                hide={handleModalClose}
                headerText={translate("modal.disclaimer.message.header")}
                modalContent={
                    <>
                        This feature is a beta version. It relies on OpenAI technology to provide relevant answers.
                        While efforts have been made to maximize the accuracy of the responses, there may be
                        inaccuracies as we are dependent on OpenAI nondeterministic processing. Additionally, Actito DMA
                        is not responsible for the information provided to OpenAI and, as such, cannot offer any
                        guarantee on data privacy or localization. No personal data stored in the profiles and custom databases are sent to OpenAI. Nonetheless, the user is responsible for the data that are sent to OpenAI.

                        The feature can be disabled at the license level in the configuration portal by an admin user.
                    </>
                }
                modalFooter={
                    <ButtonsModalFooterSpellCheck onConfirm={() => handleDisclaimer(fieldNameInEdition)} />
                }
            />
        </>
    );
};
