import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parseISO } from "date-fns";
import { toISOIgnoreTimezone } from "../../helpers/utils";

type DisplayNestedInputProps = {
  handleNestedInputChange: (inputValue: string, index: number) => void;
  acceptedValues?: string[];
  type?: string;
  value: string;
  index: number;
};

export const DisplayNestedInput: React.FC<DisplayNestedInputProps> = ({
  handleNestedInputChange,
  acceptedValues,
  value,
  type,
  index,
}) => {
  switch (type) {
    case "STRING":
      if (acceptedValues !== undefined) {
        return <NestedSelect {...{ acceptedValues, handleNestedInputChange, value, index }} />;
      } else {
        return <SimpleInput {...{ value, index, handleNestedInputChange }} />;
      }

    case "DATETIME":
    case "DATE":
      return (
        <div className="field">
          <div className="control has-icons-right">
            <DatePicker {...{ value, handleNestedInputChange, index }} />
            <span className="icon is-small is-right">
              <i className="fas fa-calendar"></i>
            </span>
          </div>
        </div>
      );

    case "BOOLEAN":
      return <NestedSelect acceptedValues={["true", "false"]} {...{ handleNestedInputChange, value, index }} />;

    case "INTEGER":
      return <SimpleInput {...{ value, handleNestedInputChange, index }} inputType="number" />;

    default:
      return <SimpleInput {...{ value, index, handleNestedInputChange }} />;
  }
};

type DatePickerProps = {
  value?: string;
  handleNestedInputChange: (inputValue: string, index: number) => void;
  index: number;
};

const DatePicker: React.FC<DatePickerProps> = ({ value, handleNestedInputChange, index }) => {
  const [date, setDate] = useState(value ? parseISO(value) : undefined);

  React.useEffect(() => {
    // If we change operator, we enforce the date to be undefined
    if (value === "") setDate(undefined);
  }, [value]);

  const handleNewDate = (dateValue: Date) => {
    setDate(dateValue);
    const newDate = dateValue ? toISOIgnoreTimezone(dateValue) : undefined;
    handleNestedInputChange(newDate || "", index);
  };

  return (
    <ReactDatePicker
      wrapperClassName="is-flex"
      className="input"
      selected={date}
      onChange={handleNewDate}
      dateFormat="dd/MM/yyyy"
    />
  );
};

type SimpleInputProps = {
  value: string;
  handleNestedInputChange: (inputValue: string, index: number) => void;
  inputType?: string;
  index: number;
};

export const SimpleInput: React.FC<SimpleInputProps> = ({
  value,
  handleNestedInputChange,
  inputType = "text",
  index,
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleNestedInputChange(event.target.value, index);
  };

  return (
    <input
      name="value"
      className="input is-fullwidth is-text-overflow"
      value={value}
      onChange={handleInputChange}
      type={inputType}
    />
  );
};

type Props = {
  value: string;
  handleNestedInputChange: (inputValue: string, index: number) => void;
  index: number;
  acceptedValues?: string[];
};

export const NestedSelect: React.FC<Props> = ({ value, handleNestedInputChange, acceptedValues = [""], index }) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    handleNestedInputChange(event.target.value, index);
  };
  return (
    <div className="select is-fullwidth">
      <select name="value" value={value ? value : ""} onChange={handleInputChange} className="is-text-overflow">
        {!value && <option>---</option>}
        {acceptedValues?.map((value, index) => (
          <option key={index} value={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
};
