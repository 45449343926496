import { useQuery } from "react-query";
import { apiGet } from "../api/api-calls";
import { SegmentStore } from "../types";

export function useSegments() {
  const { data, isLoading, refetch } = useQuery(["segments"], segmentFetcher, {
    staleTime: 0,
    refetchOnWindowFocus: false,
  });
  return { segmentStore: data, isLoading, refetchSegments: refetch };
}

async function segmentFetcher() {
  return await apiGet<SegmentStore>(`/api/segments`);
}
