import React from "react";
import { useCloudinaryCredentials } from "../hooks/use-cloudinary-credentials";

type Props = {
  setDmaName?: (name: string) => void;
  name: string;
};

export const CloudinaryButton: React.FC<Props> = ({ setDmaName, name }) => {
  const { baseFolder } = useCloudinaryCredentials() || {};
  const handleCloudinaryClick = () => {
    if (!setDmaName) {
      throw new Error("Unexpected error : setDmaName should be defined for a cloudinary input");
    }
    if (!window.ml) return;
    window.ml.show(baseFolder ? { folder: { path: baseFolder, ressource_type: "image" } } : undefined);

    if (!name) return;
    setDmaName(name);
  };
  return (
    <div className="control">
      <button className="button is-primary" onClick={handleCloudinaryClick}>
        <svg width="32" height="23" style={{ pointerEvents: "none" }} xmlns="http://www.w3.org/2000/svg">
          <path
            d="M26.88 9.73A4.801 4.801 0 0022.4 3.2c-.985 0-1.905.3-2.665.81A7.993 7.993 0 0012.8 0a7.998 7.998 0 00-7.99 8.405A7.202 7.202 0 007.2 22.4h18.4a6.4 6.4 0 001.28-12.67zm-6.645 4.435l-5.27 5.27c-.31.31-.82.31-1.13 0l-5.27-5.27C8.06 13.66 8.42 12.8 9.13 12.8h3.27V7.2c0-.44.36-.8.8-.8h2.4c.44 0 .8.36.8.8v5.6h3.27c.71 0 1.07.86.565 1.365z"
            fill="#FFF"
            fillRule="nonzero"
          />
        </svg>
      </button>
    </div>
  );
};
