import jwt from "jsonwebtoken";
import config from "../config.json";

const { resetTokenIfExpiresInLessThanThisManySeconds, tokenInvalidIfExpiresInLessThanThisManySeconds } = config;

let token: string;
let tokenExpiryDateTime: Date = new Date();
type AsyncCallback = () => Promise<void>;

// Exposed authenticated call management
/**
 * Logs the user in, saving the token(implementation detail) to authenticate future calls
 * as long as the token has not expired.
 *
 * Returns the username and the user's roles if the login was successful
 * Returns undefined if the user could not be logged in.
 *
 * Writes error messages to console on unexpected errors to help the developer handle unwanted cases
 *
 * @export
 * @param {string} inputLogin
 * @param {string} password
 * @returns {(Promise<userManagement.LoginInfo | undefined>)}
 */
export async function login(inputLogin: string, password: string): Promise<userManagement.LoginInfo | undefined> {
  try {
    const { token: newToken, roles } = await unsafeApiPost<{ token: string; roles: Record<string, string[]> }>(
      "/api/login",
      { login: inputLogin, password }
    );
    storeToken(newToken);
    const { userName } = jwt.decode(newToken) as userManagement.TokenPayload;
    return { userName, roles };
  } catch (err) {
    if (err.name !== "APIError") {
      console.error("Unexpected error", err);
    } else {
      const apiError = err as APIError;
      if (apiError.response.status !== 401) {
        console.error("Unexpected error", err);
      }
    }
    return undefined;
  }
}

/**
 * Checks if the token is still valid, with some slack
 *
 * @export
 * @returns {boolean}
 */
export function isTokenValid(): boolean {
  const timeLeft = tokenExpiryDateTime.getTime() - Date.now();
  return timeLeft > tokenInvalidIfExpiresInLessThanThisManySeconds * 1000;
}

/**
 * Execute a safe post on the api:
 *  - renews the token if it expires shortly (see implementation of backgroundResetTokenIfNeeded)
 *  - requires a manual login if the token has expired (through the FixTokenExpirationCallback)
 *
 * @export
 * @param {string} path
 * @param {Record<string, unknown>} [body]
 * @returns
 */
export async function apiPost<
  Response extends Record<string, unknown>,
  Body extends Record<string, unknown> = Record<string, unknown>
>(path: string, body?: Body) {
  backgroundResetTokenIfNeeded();
  return unsafeApiPost<Response>(path, body);
}

/**
 * Execute a safe patch on the api:
 *  - renews the token if it expires shortly (see implementation of backgroundResetTokenIfNeeded)
 *  - requires a manual login if the token has expired (through the FixTokenExpirationCallback)
 *
 * @export
 * @param {string} path
 * @param {Record<string, unknown>} [body]
 * @returns
 */
export async function apiPatch<
  Response extends Record<string, unknown>,
  Body extends Record<string, unknown> = Record<string, unknown>
>(path: string, body?: Body) {
  backgroundResetTokenIfNeeded();
  return unsafeApiPatch<Response>(path, body);
}
export async function apiGetImage<
    Response >(path: string) {
  backgroundResetTokenIfNeeded();
  return unsafeApiGetImage<Response>(path);
}

export async function apiGetUrlImageAttachment<
    Response >(path: string) {
  backgroundResetTokenIfNeeded();
  return unsafeApiGetUrlImageAttachement<Response>(path);
}

/**
 * Execute a safe delete on the api:
 *  - renews the token if it expires shortly (see implementation of backgroundResetTokenIfNeeded)
 *  - requires a manual login if the token has expired (through the FixTokenExpirationCallback)
 *
 * @export
 * @param {string} path
 * @param {Record<string, unknown>} [body]
 * @returns
 */
export async function apiDelete<
  Response extends Record<string, unknown>,
  Body extends Record<string, unknown> = Record<string, unknown>
>(path: string, body?: Body) {
  backgroundResetTokenIfNeeded();
  return unsafeApiDelete<Response>(path, body);
}

/**
 * Execute a safe put on the api:
 *  - renews the token if it expires shortly (see implementation of backgroundResetTokenIfNeeded)
 *  - requires a manual login if the token has expired (through the FixTokenExpirationCallback)
 *
 * @export
 * @param {string} path
 * @param {Record<string, unknown>} [body]
 * @returns
 */
export async function apiPut<
  R extends Record<string, unknown>,
  B extends Record<string, unknown> = Record<string, unknown>
>(path: string, body: B) {
  backgroundResetTokenIfNeeded();
  return unsafeApiPut<R>(path, body);
}

/**
 * Execute a safe get on the api:
 *  - renews the token if it expires shortly (see implementation of backgroundResetTokenIfNeeded)
 *  - requires a manual login if the token has expired (through the FixTokenExpirationCallback)
 *
 * @export
 * @template Response
 * @param {string} path
 * @returns
 */
export async function apiGet<Response extends Record<string, unknown>>(path: string) {
  backgroundResetTokenIfNeeded();
  return await unsafeApiGet<Response>(path);
}

//--- internal functions

export class APIError extends Error {
  constructor(response: Response, additionalData?: Record<string, unknown>) {
    super(response.statusText);
    this.name = "APIError";
    this.response = response;
  }
  response: Response;
}

export async function unsafeApiGet<Response extends Record<string, unknown>>(path: string) {
  const headers = new Headers({ Authorization: `Bearer ${token}`, Accept: "application/json" });
  const requestInit: RequestInit = { method: "GET", headers };
  const response = await fetch(path, requestInit);
  if (!response.ok) {
    if (response.status < 500) throw new APIError(response, { path });
    throw new Error("unexpected error for path " + path);
  }
  return (await response.json()) as Response;
}
export async function unsafeApiGetImage<T>(
    path: string,
): Promise<string> {
  const headers = new Headers({Authorization: `Bearer ${token}`, Accept: "*/*"});
  const requestInit: RequestInit = {method: "GET", headers};
  const response = await fetch(path, requestInit);
  if (!response.ok) {
    if (response.status < 500) throw new APIError(response, {path});
    throw new Error("unexpected error for path " + path);
  }
  const buffer = await response.arrayBuffer();
  const stringifiedBuffer = Buffer.from(buffer).toString();
  return stringifiedBuffer;
}

export async function unsafeApiGetUrlImageAttachement<T>(
    path: string,
): Promise<string> {
  const headers = new Headers({Authorization: `Bearer ${token}`, Accept: "*/*"});
  const requestInit: RequestInit = {method: "GET", headers};
  const response = await fetch(path, requestInit);
  if (!response.ok) {
    if (response.status < 500) throw new APIError(response, {path});
    throw new Error("unexpected error for path " + path);
  }
  const buffer = await response.arrayBuffer();
  const stringifiedBuffer = Buffer.from(buffer).toString();
  return stringifiedBuffer;
}
export async function unsafeApiPost<T extends Record<string, unknown>>(
  path: string,
  body?: Record<string, unknown>
): Promise<T> {
  const headers = new Headers();
  const requestInit: RequestInit = { method: "POST", headers };

  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }

  if (body) {
    headers.append("Content-Type", "application/json");
    requestInit.body = JSON.stringify(body);
  }

  const response = await fetch(path, requestInit);

  if (!response.ok) {
    if (response.status < 500) throw new APIError(response, { path });
    throw new Error("unexpected error for path " + path);
  }
  return (await response.json()) as T;
}

export async function unsafeApiDelete<T extends Record<string, unknown>>(
  path: string,
  body?: Record<string, unknown>
): Promise<T> {
  const headers = new Headers();
  const requestInit: RequestInit = { method: "DELETE", headers };

  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }

  if (body) {
    headers.append("Content-Type", "application/json");
    requestInit.body = JSON.stringify(body);
  }

  const response = await fetch(path, requestInit);

  if (!response.ok) {
    if (response.status < 500) throw new APIError(response, { path });
    throw new Error("unexpected error for path " + path);
  }
  return (await response.json()) as T;
}

export async function unsafeApiPatch<T extends Record<string, unknown>>(
  path: string,
  body?: Record<string, unknown>
): Promise<T | void> {
  const headers = new Headers();
  const requestInit: RequestInit = { method: "PATCH", headers };

  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }

  if (body) {
    headers.append("Content-Type", "application/json");
    requestInit.body = JSON.stringify(body);
  }

  const response = await fetch(path, requestInit);

  if (!response.ok) {
    if (response.status < 500) throw new APIError(response, { path });
    throw new Error("unexpected error for path " + path);
  }
  if (response.status === 204) {
    return;
  }
  return (await response.json()) as T;
}

async function unsafeApiPut<Response extends Record<string, unknown>>(
  path: string,
  body: Record<string, unknown>
): Promise<Response> {
  const headers = new Headers({ "Content-Type": "application/json" });
  const requestInit: RequestInit = { method: "PUT", headers, body: JSON.stringify(body) };

  if (token) {
    headers.append("Authorization", `Bearer ${token}`);
  }

  const response = await fetch(path, requestInit);
  if (!response.ok) {
    if (response.status < 500) throw new APIError(response, { path });
    throw new Error("unexpected error for path " + path);
  }
  if (response.status === 204) {
    // no content returned
    return {} as Response;
  }
  return (await response.json()) as Response;
}

function storeToken(newToken: string) {
  token = newToken;
  const { exp, iat } = jwt.decode(token) as userManagement.TokenPayload;
  const lifetimeInMilliseconds = Math.floor((exp - iat) * 1000);
  tokenExpiryDateTime = new Date(Date.now() + lifetimeInMilliseconds);
}

export function backgroundResetTokenIfNeeded() {
  const secondsLeft = (tokenExpiryDateTime.getTime() - Date.now()) / 1000;
  if (secondsLeft > 0 && secondsLeft < resetTokenIfExpiresInLessThanThisManySeconds) {
    unsafeApiPost<{ token: string }>("/api/new-token", undefined).then(({ token: newToken }) => storeToken(newToken));
  }
}
