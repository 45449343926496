import React, { useContext } from "react";
import { useTranslation } from "../hooks/use-translation";
import cardStyles from "../components/card.module.scss";
import { TemplateList } from "../components/template-step/template-list";
import { WizardContext } from "../context/wizard-context";
import { Channel } from "../types";
import { useModulesPermissions } from "../hooks/use-modules-permissions";
import cx from "classnames";
import { FontAwesome } from "../components/icons/font-awesome";

type Props = {
  currentTemplateId?: string;
  onTemplateSelect: (templateName: string) => void;
  campaignName: string;
  setCampaignName: React.Dispatch<React.SetStateAction<string>>;
  isSelectionDisabled?: boolean;
  setIsSelectionDisabled: (value: boolean) => void;
};

export const TemplateSelector: React.FC<Props> = ({
  currentTemplateId,
  onTemplateSelect,
  campaignName,
  setCampaignName,
    isSelectionDisabled,
    setIsSelectionDisabled,
}) => {
  const { translate } = useTranslation();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setCampaignName(event.target.value);

  const { channel, setChannel } = useContext(WizardContext);
  //TODO NEED TO BE REMOVE WHEN GO TO PRODUCTION

   const { isSmsModuleActivated, isEmailModuleActivated } = useModulesPermissions();

  const handleChannelChange = (buttonType: Channel) => {
    setChannel(buttonType);
  };

  return (
    <div className="box is-shadowless">
      <h2 className="title is-4">{translate("wizard.template.campaignInfos.title")}</h2>
      <div
        className={cx("field", {
          isDisabled: currentTemplateId,
        })}
      >
        <div className="control">
          <input
            type="text"
            placeholder={translate("wizard.template.campaignInfos.name")}
            name="campaignName"
            id="campaignName"
            className="input"
            value={campaignName}
            onChange={handleChange}
          />
        </div>
      </div>
      <h2 className="title is-4 field">{translate("wizard.template.channel")}</h2>
      <div
        className={cx("field", {
          isDisabled: !campaignName,
        })}
      >
        <div className="control">
          <div className="buttons">
            <button
              className={cx("button", "is-primary", {
                isDisabled: !isEmailModuleActivated,
                "is-outlined": channel !== "EMAIL",
              })}
              onClick={() => handleChannelChange("EMAIL")}
            >
              <span className="icon is-small">
                <FontAwesome name="envelope" fontAwesomeVariant="far" />
              </span>
              <span>Email</span>
            </button>
            <button
              className={cx("button", "is-primary", {
                isDisabled: !isSmsModuleActivated,
                "is-outlined": channel !== "SMS",
              })}
              onClick={() => handleChannelChange("SMS")}
            >
              <span className="icon is-small">
                <FontAwesome name="mobile-alt" />
              </span>
              <span>SMS</span>
            </button>
          </div>
        </div>
      </div>
      {channel !== "IN-DEFINITION" && (
        <>
          <h2 className="title is-4">{translate("wizard.template.select")}</h2>
          <div
            className={cx("card-container", `${cardStyles.cardContainer}`, {
              isDisabled: !campaignName,
              [cardStyles.hasSelection]: currentTemplateId,
            })}
          >
            <TemplateList {...{ currentTemplateId, onTemplateSelect, channel, isSelectionDisabled, setIsSelectionDisabled }} />
          </div>
        </>
      )}
    </div>
  );
};
