import { RouteComponentProps } from "@reach/router";
import { useContext, useEffect } from "react";
import { LoginContext } from "../context/login-context/login-context";

export const Logout: React.FC<RouteComponentProps> = () => {
  const { handleLogout } = useContext(LoginContext);

  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return null;
};
