import { useState } from "react";

type ModaleConfiguration = {
  headerText?: string | JSX.Element;
  modalContent?: string | JSX.Element;
  modalFooter?: string | JSX.Element;
};

export const useModal = () => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [modalConfiguration, setModalConfiguration] = useState<ModaleConfiguration>();

  const toggleModal = () => setIsShown((isShown) => !isShown);

  return {
    isShown,
    modalConfiguration,
    setModalConfiguration,
    toggleModal,
  };
};
