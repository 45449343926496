import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Wizard } from "./wizard";
import { Channel } from "../types";
import { WizardContext } from "../context/wizard-context";
import { useCampaignTemplateInformation } from "../hooks/use-template-information";

type Props = {
  campaignId?: string;
  currentStep?: WizardStep;
  editChannel?: "edit-sms-campaign" | "edit-email-campaign";
};

export const campaignStatus: Record<string, string> = {
  DRAFT: "Draft",
  TESTED: "Tested",
  PENDING_APPROVAL: "Pending approval",
  REJECTED: "Rejected",
  APPROVED: "Approved",
  SCHEDULED: "Scheduled",
  CANCELLED: "Cancelled",
  SENDING: "Sending",
  SENT: "Sent",
  ARCHIVED: "Archived",
  DELETED: "Deleted",
};

export const steps = ["template", "customize", "target", "send"] as const;
export type WizardStep = typeof steps[number];

const channelSlugs: Record<string, Channel> = {
  "edit-EMAIL-campaign": "EMAIL",
  "edit-SMS-campaign": "SMS",
  "new-campaign": "IN-DEFINITION",
};

export const WizardWrapper: React.FC<RouteComponentProps & Props> = (props) => {
  const { campaignId, currentStep = "template", editChannel = "new-campaign" } = props;
  const [channel, setChannel] = useState<Channel>(channelSlugs[editChannel]);
  const { templateId } = useCampaignTemplateInformation(campaignId, channel);

  return (
    <WizardContext.Provider value={{ channel, setChannel, templateId }}>
      <Wizard {...{ currentStep, campaignId }} />
    </WizardContext.Provider>
  );
};
