import React, { useState, useCallback } from "react";
import { Status } from "../components/notification/notification";

type INotificationContext = {
  notification: Notification;
  addNotification: (notification: Notification) => void;
  removeNotification: () => void;
};

type Notification = {
  message: string;
  props?: Props;
};

type Props = {
  [key: string]: string;
  status: Status;
};

export const NotificationContext = React.createContext<INotificationContext>({
  notification: { message: "" },
  addNotification: (notification: Notification) => {},
  removeNotification: () => {},
});

export const NotificationProvider: React.FC = ({ children }) => {
  const [notification, setNotification] = useState<Notification>({ message: "" });

  const removeNotification = () => setNotification({ message: "" });

  const addNotification = (notification: Notification) => setNotification(notification);

  const contextValue = {
    notification,
    addNotification: useCallback((notification: Notification) => addNotification(notification), []),
    removeNotification: useCallback(() => removeNotification(), []),
  };

  return <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>;
};
