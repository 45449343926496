import React, { useEffect, useState } from "react";
import { AttributeField } from "./attribute-field";
import { OperatorField } from "./operator-field";
import { DisplaySpecificInput } from "../target-step/display-specific-input";
import { AttributeCriterion } from "../../types";
import { defaultValuesOperators, FetchedAttributes } from "../criteria-list-editor";
import { MultiValuesInputWrapper } from "../target-step/multi-values-input";
import styles from "./criterion-editor.module.scss";
import cx from "classnames";

type Props = {
  value: AttributeCriterion;
  onChange: (criterion: AttributeCriterion) => void;
  onRemove: () => void;
  fetchedAttributes?: FetchedAttributes;
};

export type SelectedAttribute = {
  technicalName: string;
  displayName: string;
  type: string;
  acceptedValues?: string[];
};

export const CriterionEditor: React.FC<Props> = ({ onRemove, onChange, value, fetchedAttributes }) => {
  const [selectedAttribute, setSelectedAttribute] = useState<SelectedAttribute>();
  const [acceptedValues, setAcceptedValues] = useState<string[] | undefined>(undefined);

  useEffect(() => {
    if (value !== undefined) {
      if (fetchedAttributes !== undefined) {
        const selectedObj = fetchedAttributes.attributes.find((x) => x.technicalName === value.attributeName);
        setSelectedAttribute(selectedObj);
        setAcceptedValues(selectedObj?.acceptedValues);
      }
    }
  }, [fetchedAttributes, value]);

  const handleInputChange = (event: { target: { name: string; value: string | string[] } }) => {
    const { name, value: fieldValue } = event.target;
    onChange({ ...value, [name]: fieldValue });
  };
  const handleInputReset = (event: { target: { name: string; value: string } }) => {
    const { name, value: fieldValue } = event.target;
    onChange({ [name]: fieldValue });
  };

  const handleAttributeChange = (event: { target: { name: string; value: string } }) => {
    handleInputReset(event);
  };
  const handleOperatorChange = (event: { target: { name: string; value: string } }) => {
    const { name, value: fieldValue } = event.target;
    const removeCriterionValue = "value";
    const { [removeCriterionValue]: remove, ...rest } = value;
    const emptyValueObj = { ...rest, value: "" };
    onChange({ ...emptyValueObj, [name]: fieldValue });
  };

  if (fetchedAttributes !== undefined) {
    const { attributes } = fetchedAttributes;
    const { type } = selectedAttribute || {};
    const defaultValueForOperator = value?.operator ? defaultValuesOperators[value.operator] : undefined;
    const multiplesValuesForOperator = value?.operator === "IN" || value?.operator === "NOT_IN" ? true : false;
    const showValuePicker = defaultValueForOperator === undefined;
    return (
      <>
        <div className="field is-horizontal">
          <div
            data-test="field-criterium"
            className={cx("field-body", {
              [styles.showNested]: multiplesValuesForOperator,
            })}
          >
            <div className="field attribute-field">
              <div className="control">
                <div className="select is-fullwidth">
                  <AttributeField {...{ value, handleAttributeChange, attributes }} />
                </div>
              </div>
            </div>
            <div className="field operator-field">
              <div className="control">
                <div className="select">
                  <OperatorField {...{ value, handleOperatorChange }} />
                </div>
              </div>
            </div>
            {showValuePicker && !multiplesValuesForOperator && (
              <div className={`field input-field`}>
                <div className="control">
                  <DisplaySpecificInput {...{ acceptedValues, handleInputChange, type, value }} />
                </div>
              </div>
            )}

            <div className="field delete-button">
              <div className="control">
                <button className="button is-danger is-outlined" type="button" onClick={onRemove}>
                  X
                </button>
              </div>
            </div>
          </div>
        </div>
        {multiplesValuesForOperator && (
          <div className="box">
            <div className="field is-horizontal">
              <div className="field-body">
                <div className={`field ${styles.changeOrder}`}>
                  <div className="control">
                    <MultiValuesInputWrapper {...{ acceptedValues, handleInputChange, type, value }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  } else {
    return <p>No attributes defined</p>;
  }
};
