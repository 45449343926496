import React, { FC } from "react";
import { useTranslation } from "../../hooks/use-translation";
interface ButtonsModalFooterProps {
  onConfirm: () => void;
  onCancel: () => void;
}
export const ButtonsModalFooter: FC<ButtonsModalFooterProps> = ({ onCancel, onConfirm }) => {
  const { translate } = useTranslation();

  return (
    <>
      <div className="buttons is-right">
        <button onClick={onConfirm} className="button is-success">
          {translate("dashboard.toolbar.button.confirm")}
        </button>
        <button onClick={onCancel} className="button is-danger">
          {translate("dashboard.toolbar.button.cancel")}
        </button>
      </div>
    </>
  );
};
