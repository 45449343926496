import React, { FC, useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import cx from "classnames";
import styles from "./modal.module.scss";

export interface ModalProps {
  isShown: boolean;
  hide: () => void;
  modalContent?: string | JSX.Element;
  modalFooter?: string | JSX.Element;
  headerText?: string | JSX.Element;
}
export const Modal: FC<ModalProps> = ({ isShown, hide, modalContent, headerText, modalFooter }) => {
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === 27 && isShown) {
        hide();
      }
    },
    [hide, isShown]
  );

  useEffect(() => {
    isShown ? (document.body.style.overflow = "hidden") : (document.body.style.overflow = "unset");
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, [isShown, onKeyDown]);

  const modal = (
    <>
      <div
        className={cx("modal", {
          "is-active": isShown,
        })}
      >
        <div className={cx("modal-background", styles.fadeIn)} aria-label="close" onClick={hide}></div>
        <div className={cx("modal-card", styles.fadeInBottom)} aria-modal tabIndex={-1} role="dialog">
          <header className="modal-card-head has-background-primary ">
            <p className="modal-card-title has-text-white">{headerText}</p>
            <button className="delete" aria-label="close" onClick={hide}></button>
          </header>
          <section className={cx("modal-card-body", styles.modalCardBody)}>{modalContent}</section>
          <footer className="modal-card-foot is-justify-content-flex-end has-background-primary-light">
            {modalFooter && <>{modalFooter}</>}
          </footer>
        </div>
      </div>
    </>
  );
  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};
