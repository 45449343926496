import { useMutation, useQuery, useQueryCache } from "react-query";
import { apiGet, apiPost } from "../api/api-calls";
import { DMAEvent } from "../types";

export type DMAEvents = {
  events: DMAEvent[];
};

export type NewEvent = Omit<DMAEvent, "eventId" | "createdAt">;

type EventReturn = {
  events: DMAEvent[];
  addEvent: (event: NewEvent) => Promise<void>;
  isLoading: boolean;
};

export function useEvents(campaignId?: string): EventReturn {
  const queryCache = useQueryCache();

  const { data, isLoading } = useQuery(["events", campaignId], eventsFetcher, {
    staleTime: 0,
    enabled: campaignId,
    refetchOnWindowFocus: false,
  });
  const events = data?.events || [];

  const [addEvent] = useMutation<void, Promise<void>, NewEvent>(postEvent, {
    onSuccess: () => {
      queryCache.invalidateQueries("events");
    },
  });

  return { events, addEvent, isLoading };
}

async function eventsFetcher(_key: string, campaignId?: string) {
  if (!campaignId)
    return {
      events: [],
    };
  const events = await apiGet<DMAEvents>(`/api/campaigns/${campaignId}/events`);
  return events;
}

async function postEvent(event: NewEvent) {
  await apiPost(`/api/campaigns/${event.campaignId}/events`, event);
}
