import React from "react";

const BynderIcon = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 37.4 28" width="32" height="23">
      <path
        fill="#FFFFFF"
        d="M28.5,0.1c3.5,0,6.7,2,8.1,5.2c1.4,3.1,0.9,6.8-1.4,9.4l-11.1,11c-1.4,1.4-3.3,2.2-5.4,2.2
	c-2,0-4-0.8-5.4-2.2l-0.8-0.8l3.8-3.7l0.8,0.8c0.4,0.4,1,0.7,1.6,0.7c0.6,0,1.2-0.2,1.6-0.7L31,11.5c0.6-0.6,0.9-1.5,0.9-2.3
	c0-0.9-0.4-1.9-1-2.5c-0.7-0.7-1.6-1.1-2.5-1.1c-0.9,0-1.7,0.3-2.4,0.9l-16,15.8l-7.7-7.6C0.8,13.1,0,11,0,8.9
	c0-3.5,2.1-6.7,5.4-8.1s7-0.8,9.6,1.6l1.1,1.1l-3.8,3.7c0,0-0.6-0.6-0.8-0.8C10.9,5.9,10,5.6,9.1,5.6c-1,0-1.9,0.4-2.6,1
	S5.5,8.2,5.5,9.1c0,0.9,0.3,1.7,0.9,2.4l3.4,3.4L21.8,3.1C23.5,1.2,25.9,0.1,28.5,0.1z"
      />
    </svg>
  );
};

BynderIcon.displayName = "BynderIcon";
export default BynderIcon;
