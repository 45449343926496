import { useCallback, useContext } from "react";

import frenchTranslation from "../i18n/fr.json";
import englishTranslation from "../i18n/en.json";
import { LoginContext } from "../context/login-context/login-context";

type Dictionary = {
  [key: string]: { [key: string]: string };
};

const dictionary: Dictionary = {
  en: englishTranslation,
  fr: frenchTranslation,
};
export const useTranslation = () => {
  const { language } = useContext(LoginContext);
  const languages = Object.keys(dictionary);
  const translate = useCallback((key: string) => dictionary?.[language]?.[key], [language]);
  return { translate, language, languages };
};
