import { Substitutions } from "../types";

export const replaceSmsVariablesWithSubstitutions = (
  baseText: string,
  variableNames: string[],
  mergedCustomizations: Substitutions
) => {
  const variableRegexpSource = `\\$\{(${variableNames.join("|")})}`;
  const variableRegexp = new RegExp(variableRegexpSource, "gm");

  const newText = baseText.replace(variableRegexp, (_match, variable) => {
    if (`${mergedCustomizations[variable]}` === "") {
      return `$\{${variable}}`;
    }
    return `${mergedCustomizations[variable]}`;
  });
  return newText;
};
