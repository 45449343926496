import { useTranslation } from "./use-translation";
import { useEvents } from "../hooks/use-event";
import { useContext } from "react";
import { LoginContext } from "../context/login-context/login-context";

const DELAY_BEFORE_NEW_EVENT_IN_MILLISECONDS = 600000;

export const usePostEventValidation = (campaignId?: string) => {
  const { translate } = useTranslation();
  const { events } = useEvents(campaignId);
  const { user } = useContext(LoginContext);

  const validatePostEvent = (translationKey: string) => {
    let shouldPostEvent = true;
    if (events.length !== 0) {
      const lastEvent = events[events.length - 1];
      if (lastEvent.comment === `${translate(translationKey)}` && lastEvent.user === user) {
        const lastEventDate = new Date(`${lastEvent.createdAt}+0000`);
        const currentDate = new Date();
        const lastUpdateInMilliseconds = currentDate.getTime() - lastEventDate.getTime();
        if (lastUpdateInMilliseconds < DELAY_BEFORE_NEW_EVENT_IN_MILLISECONDS) {
          shouldPostEvent = false;
        }
      }
    }
    return shouldPostEvent;
  };
  return { validatePostEvent };
};
