import { useQuery } from "react-query";
import { apiGet } from "../api/api-calls";
import { ISplashMessage } from "../types";

export const useSplashMessage = () => {
  const { data } = useQuery(["splashMessage"], getSplashMessage, {
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });
  const splashMessage = data || { paragraphs: [], checksum: undefined };

  return { splashMessage };
};

const getSplashMessage = () => apiGet<ISplashMessage>("/api/splash-message");
