import React, { useContext, useEffect, useState } from "react";
import { EventsTable } from "../components/events-table";
import { SendTestForm } from "../components/send-step/send-test-form";
import { SendCommentForm } from "../components/send-step/send-comment-form";
import { ReportingSummary } from "../components/send-step/reporting-summary";
import { Targeting } from "../types";
import { LoginContext } from "../context/login-context/login-context";
import { useEvents } from "../hooks/use-event";
import { Loader } from "../components/loader";
import { Substitutions } from "../types";
import { useTranslation } from "../hooks/use-translation";
import { useCampaign, useCampaignMutators } from "../hooks/use-campaign";
import { Campaign } from "../types/campaigns";
import cx from "classnames";
import { GenericMessage } from "../components/generic-message";
import { WizardContext } from "../context/wizard-context";
import { SmsContext } from "../context/sms-context";

type Props = {
  campaignId?: string;
  targeting?: Targeting;
  count?: "unknown" | number;
  substitutions?: Substitutions;
  templateId?: string;
};

type Status = Campaign["status"];

const canSendStatuses: Status[] = ["APPROVED", "TESTED", "PENDING_APPROVAL"];
const canTestStatuses: Status[] = ["DRAFT", "TESTED", "REJECTED", "PENDING_APPROVAL", "CANCELLED"];
const canSendRequestApproval: Status[] = ["TESTED"];
const canApproveStatuses: Status[] = ["PENDING_APPROVAL", "TESTED"];

export const Send: React.FC<Props> = ({ count, targeting, campaignId }) => {
  const { translate } = useTranslation();
  const { availableClubs, activeClub, user, smsChannelAuthorizedScopes } = useContext(LoginContext);
  const { channel } = useContext(WizardContext);
  const {
    smsCounterData: { length: smsLength, per_message },
  } = useContext(SmsContext);
  const [testingPromise, setTestingPromise] = useState<Promise<unknown>>();
  const [sendingPromise, setSendingPromise] = useState<Promise<unknown>>();
  const [cancelPromise, setCancelPromise] = useState<Promise<unknown>>();

  const { data: campaign } = useCampaign(campaignId, channel);
  const { events, isLoading } = useEvents(campaignId);
  const { test, send, cancel, isErrorOnTest, isErrorOnSend } = useCampaignMutators();

  const sms_char_alert = per_message - 20;

  useEffect(() => {
    let cancelled = false;
    testingPromise?.then(() => {
      if (!cancelled) {
        setTestingPromise(undefined);
      }
    });
    return () => {
      cancelled = true;
    };
  }, [testingPromise]);

  useEffect(() => {
    let cancelled = false;
    sendingPromise?.then(() => {
      if (!cancelled) setSendingPromise(undefined);
    });
    return () => {
      cancelled = true;
    };
  }, [sendingPromise]);

  useEffect(() => {
    let cancelled = false;
    cancelPromise?.then(() => {
      if (!cancelled) setCancelPromise(undefined);
    });
    return () => {
      cancelled = true;
    };
  }, [cancelPromise]);

  const userRole = activeClub ? availableClubs?.[activeClub][0] : "";

  const onTestSubmit = async (recipientList: string[]) => {
    if (!campaignId || recipientList.length === 0) return;

    setTestingPromise(test({ user: user || "", campaignId, recipientList, channel }));
  };

  const onSendCampaign = async (comment?: string, sendingDate?: string) => {
    let approver;
    if (userRole === "approver") {
      approver = user;
    }
    if (campaignId) setSendingPromise(send({ user: user || "", campaignId, comment, approver, sendingDate }));
  };

  const onCancelCampaign = async (comment?: string) => {
    if (campaignId) setCancelPromise(cancel({ user: user || "", campaignId, comment }));
  };

  const handleCancel = async () => {
    await onCancelCampaign();
  };

  if (!campaignId) {
    return null;
  }

  const statusCanTest = campaign?.status ? canTestStatuses.includes(campaign.status) : false;
  const statusCanSend = campaign?.status ? canSendStatuses.includes(campaign.status) : false;
  const statusCanRequestApproval = campaign?.status ? canSendRequestApproval.includes(campaign.status) : false;
  const statusCanApprove = userRole === "approver" && campaign?.status ? canApproveStatuses.includes(campaign.status) : false;
  const statusScopeCanSendSms =
    channel === "SMS"
      ? smsChannelAuthorizedScopes
        ? smsChannelAuthorizedScopes.includes(activeClub || "")
        : true
      : true;
  return (
    <>
      <h1 className="title is-4">{translate("wizard.send.title")}</h1>
      {campaign?.status === "SCHEDULED" && (
        <>
          <GenericMessage
            headerTranslationKey="send.scheduled"
            iconName="check-circle"
            themeColor="is-primary"
            fontAwesomeSize="fa-lg"
            headerIcon
            headerAdditionalContent={campaign.scheduledAt && new Date(campaign.scheduledAt).toLocaleString()}
          >
            <p>The campaign is scheduled.</p>
          </GenericMessage>
          <div className="level-right">
            <button onClick={handleCancel} className="button is-small is-outlined is-danger">
              Cancel it
            </button>
          </div>
          <hr />
        </>
      )}
      <h2 className="title is-5">{translate("wizard.send.eventsRecord")}</h2>
      {isLoading ? (
        <Loader />
      ) : events.length === 0 ? (
        <p>{translate("wizard.send.noEvent")}.</p>
      ) : (
        <EventsTable events={events} />
      )}

      <hr />

      {channel === "SMS" &&
        (smsLength >= sms_char_alert ? (
          <div className="box is-shadowless p-0">
            {smsLength > per_message ? (
              <GenericMessage translationKey="sms.error.tooLong" themeColor="is-danger" />
            ) : (
              smsLength > sms_char_alert && (
                <GenericMessage translationKey="sms.warning" themeColor="is-warning" iconName="exclamation" />
              )
            )}
          </div>
        ) : null)}

      {!statusScopeCanSendSms &&
        (statusCanRequestApproval ? <GenericMessage translationKey="send.scope.error" themeColor="is-danger" /> : null)}

      <div
        className={cx("box", "is-shadowless", "p-0", {
          isDisabled: !statusCanTest || smsLength > per_message || !statusScopeCanSendSms,
        })}
      >
        <h2 className="title is-5">{translate("wizard.send.test")}</h2>
        <SendTestForm isTesting={testingPromise !== undefined} {...{ isErrorOnTest, statusCanTest, onTestSubmit }} />
      </div>

      <div
        className={cx({
          isDisabled: !statusCanSend || smsLength > per_message,
        })}
      >
        <h2 className="title is-5">
          {count === "unknown"
            ? `${translate("wizard.send.noTargeting")} !`
            : `${count} ${translate("wizard.send.contactsTargeted")} :`}
        </h2>
        <ReportingSummary {...{ targeting }} />

        <h2
          className={cx("title is-5", {
            isDisabled: !statusScopeCanSendSms,
          })}
        >
          {userRole === "approver"
            ? `${translate("wizard.send.approverComment")}`
            : `${translate("wizard.send.approvalRequest")}`}
        </h2>

        <SendCommentForm
          isSending={sendingPromise !== undefined}
          formClass={cx({
            isDisabled: !statusScopeCanSendSms,
          })}
          channel={channel}
          {...{
            userRole,
            campaignId,
            user: user || "",
            onSendCampaign,
            isErrorOnSend,
            statusCanSend,
            statusCanApprove,
            statusCanRequestApproval,
          }}
        />
      </div>
    </>
  );
};
