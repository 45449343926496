import React, { useContext, useEffect, useState } from "react";
import styles from "./preview.module.scss";
import { Substitutions } from "../types";
import { useTemplate } from "../hooks/use-templates";
import { useTranslation } from "../hooks/use-translation";
import { useClientContext } from "../hooks/use-client-context";
import { apiGetUrlImageAttachment } from "../api/api-calls";
import { WizardContext } from "../context/wizard-context";
import { useNotification } from "../hooks/use-notification";
import { useTemplateFormConfiguration } from "../hooks/use-template-form-config";
import { Field, Section } from "../helpers/generate-default-values";

type Props = {
  templateId: string;
  variableNames: string[];
  substitutions: Substitutions;
  highlightsVisible: boolean;
  campaignId?: string;
};

const loadIframeCSS = (iframeHead?: HTMLHeadElement) => {
  if (iframeHead === undefined) return;
  iframeHead.insertAdjacentHTML(
    "beforeend",
    `<style>html{transform: scale(0.75); transform-origin: top center; }</style>`
  );
};

export const Preview: React.FC<Props> = ({
  templateId,
  variableNames,
  substitutions,
  highlightsVisible,
  campaignId,
}) => {
  const { translate, language } = useTranslation();
  const { template } = useTemplate(templateId,campaignId);
  const { templateCustomizations } = useTemplateFormConfiguration(
    campaignId,
    templateId,
    "EMAIL"
  );
  const { clientContext } = useClientContext();
  const { channel } = useContext(WizardContext);
  const [iframeWindow, setIframeWindow] = useState<Window>();
  const [isListening, setIsListening] = useState(false);
  const [imgSrcValueToProcess, setImgSrcValueToProcess] = useState<
    string[] | undefined
  >();
  const [fixedUrlImg, setFixedUrlImg] = useState<
    { [key: string]: string }[] | undefined
  >();
  const { addNotification } = useNotification();

  useEffect(() => {
    const eventListener = ({
      data,
    }: {
      data: {
        type?: string;
        variables?: string[];
        imagesWithAttachment?: string[];
      };
    }) => {
      if (data?.type === "ready") {
        setIsListening(true);
      }
    };
    window.addEventListener("message", eventListener);
    return () => window.removeEventListener("message", eventListener);
  }, []);
  const [relativeImgsUrl, setRelativeImgsUrl] = useState<string[]>([]);
  useEffect(() => {

    if(!Object.keys(substitutions).length)return;
    if (clientContext?.enabledTemplateWithActito && channel === "EMAIL") {
      const imagesWithAttachment: string[] = [];
      templateCustomizations?.sections.map((section: Section) =>
        section.fields.map((field: Field) => {
          if (field.type === "imageUrl") {
            imagesWithAttachment.push(`$(${field.fieldName})`);
          }
        })
      );

      const imagesStaticUrl = relativeImgsUrl.length ? relativeImgsUrl : [""];
      if (!relativeImgsUrl.length) {
        const templateHtmlOriginal = template?.zipUrl;
        if (!templateHtmlOriginal) return;
        const parser = new DOMParser();
        const parsedTemplateHtml = parser.parseFromString(
          templateHtmlOriginal,
          "text/html"
        );
        const imgFromTemplate = parsedTemplateHtml.querySelectorAll("img");
        const regex = /\$\(([^)]+)\)/g;
        imgFromTemplate.forEach((image) => {
          const srcValue = image.attributes?.getNamedItem("src")?.value;
          if (srcValue) {
            const matches = srcValue.match(regex);
            if (!matches) {
              imagesStaticUrl.push(srcValue);
            }
          }
        });

        setRelativeImgsUrl(imagesStaticUrl);
      }

      setImgSrcValueToProcess([...imagesWithAttachment, ...imagesStaticUrl]);
    }
  }, [substitutions]);

  useEffect(() => {
    if (!iframeWindow || !isListening) return;
    iframeWindow.postMessage({ type: "setup", variableNames }, "*");
  }, [iframeWindow, isListening, variableNames]);
  useEffect(() => {
    if (
      !iframeWindow ||
      !isListening &&(
       !clientContext?.enabledTemplateWithActito ||
       channel !== "EMAIL" ||
       !imgSrcValueToProcess ||
       !fixedUrlImg)
    )
      return;
    iframeWindow.postMessage(
      { type: "preview", substitutions, highlightsVisible, fixedUrlImg },
      "*"
    );
  }, [
    iframeWindow,
    isListening,
    substitutions,
    highlightsVisible,
    fixedUrlImg,
    channel,
    clientContext,
  ]);

  const [recordOfContent, setRecordOfContent] = useState<{
    [key: string]: string;
  }>({});
  useEffect(() => {
    if (imgSrcValueToProcess) {
      const fetchImages = async () => {
        const urlFixedImages: {
          [key: string]: string;
        }[] = [];
        let keyExists;
        for (const url of imgSrcValueToProcess) {
          if (url === "") {
            continue;
          }
          let urlToBackend: string = "";
          const regex = /\$\(([^)]+)\)/g;
          const regexHttp = /^https?:\/\//;
          const matches = url.match(regex);
          let desiredKey;
          let imgHttps = false;
          let urlHttps;
          if (matches && matches?.length > 0) {
            desiredKey = url.replace(regex, (match, extractedContent) => {
              return extractedContent;
            });
            keyExists = substitutions[desiredKey];
            if (regexHttp.test(keyExists)) {
              desiredKey = url;
              imgHttps = true;
              urlHttps = desiredKey;
            } else {
              urlToBackend = `/api/template/actito/${template?.linkedDraftId}/attachments?path=${keyExists}`;
            }
          } else if (regexHttp.test(url)) {
            continue;
          } else {
            urlToBackend = `/api/template/actito/${template?.linkedDraftId}/attachments?path=${url}`;
          }
          const rightKey = desiredKey ? desiredKey : url;
          let contentOfRightKey: string = "";
          try {
            if (urlToBackend && recordOfContent[urlToBackend]) {
              contentOfRightKey = recordOfContent[urlToBackend];
            } else {
              contentOfRightKey = await apiGetUrlImageAttachment<Response>(
                String(urlToBackend)
              );
              setRecordOfContent((prevValue) => ({
                ...prevValue,
                [urlToBackend]: contentOfRightKey,
              }));
            }
          } catch (e) {
            addNotification({
              message: translate("notification.image.error") + keyExists,
              props: { status: "error" },
            });
          }

          if (imgHttps) {
            urlFixedImages.push({
              [rightKey]: `${urlHttps}`,
            });
          } else {
            urlFixedImages.push({
              [rightKey]: `data:image/png;base64,${contentOfRightKey}`,
            });
          }
        }
        setFixedUrlImg(urlFixedImages);
      };
      fetchImages();

      setIsListening(true);
    }
  }, [imgSrcValueToProcess, template]);

  const handleIframeLoad: (
    event: React.SyntheticEvent<HTMLIFrameElement, Event>
  ) => void = (event) => {
    const newIframeWindow = event.currentTarget.contentWindow;
    if (!newIframeWindow) throw new Error("iframe window is null or undefined");
    if (!template?.zipUrl) return;
    const iframeHead = event.currentTarget.contentDocument?.head;
    loadIframeCSS(iframeHead);
    setIframeWindow(newIframeWindow);
  };
  if (!template) return null;
  let src;

  if (clientContext?.enabledTemplateWithActito) {
    src = template.zipUrl;
  } else {
    src = template.zipUrl.replace(".zip", `/index_${language || "en"}.html`);
  }

  return (
    <>
      <article className="message">
        <div className="message-body">
          <p>
            {substitutions.subject
              ? `${translate("preview.subject")} : ${substitutions.subject}`
              : translate("preview.subject.error")}
          </p>
          <p>
            {substitutions.replyTo
              ? `${translate("preview.replyTo")} : ${substitutions.replyTo}`
              : translate("preview.replyTo.error")}
          </p>
        </div>
      </article>

      {clientContext?.enabledTemplateWithActito ? (
        <iframe
          data-testid="preview-iframe"
          onLoad={handleIframeLoad}
          className={styles.preview}
          title="email preview"
          srcDoc={src}
        />
      ) : (
        <iframe
          data-testid="preview-iframe"
          onLoad={handleIframeLoad}
          className={styles.preview}
          title="email preview"
          src={src}
        />
      )}
    </>
  );
};
