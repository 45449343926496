import React from "react";
import cx from "classnames";
import styles from "./loader.module.scss";
type LoaderPropsType = {
  isSmall?: boolean;
};
export const Loader = (props: LoaderPropsType) => {
  const { isSmall } = props;
  return (
    <div className={cx("loader-wrapper is-active")}>
      <div className={cx("loader is-loading", { [styles.loaderSmall]: isSmall })}></div>
    </div>
  );
};
