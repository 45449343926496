import { createContext } from "react";
import * as SmsCounter from "sms-counter-npm";

export interface SmsData {
  smsCounterData: SmsCounter.ICounterResult;
  setSmsCounterData: (smsCounterData: SmsCounter.ICounterResult) => void;
  smsText: string;
  setSmsText: (smsText: string) => void;
}

export const emptySmsCounterData = { encoding: "", length: 0, messages: 0, per_message: 0, remaining: 160 };

export const SmsContext = createContext<SmsData>({
  smsCounterData: emptySmsCounterData,
  setSmsCounterData: (smsCounterData: SmsCounter.ICounterResult) => {},
  smsText: "",
  setSmsText: (smsText: string) => {},
});
