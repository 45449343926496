import React, { useContext } from "react";
import { useTranslation } from "../../hooks/use-translation";
import styles from "./message-preview.module.scss";
import { SmsContext } from "../../context/sms-context";
import { useTemplateConfiguration } from "../../hooks/use-template-config";
import { WizardContext } from "../../context/wizard-context";
import { Loader } from "../loader";
import cx from "classnames";

type Props = {
  message?: string;
};

export const MessagePreview: React.FC<Props> = () => {
  const { translate } = useTranslation();
  const {
    smsText,
    smsCounterData: { length, messages, per_message },
  } = useContext(SmsContext);
  const { templateId } = useContext(WizardContext);

  const { isLoading: isTemplateConfigurationLoading } = useTemplateConfiguration(templateId);

  return (
    <>
      {isTemplateConfigurationLoading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.phone}>
            <header className={styles.phoneHeader}>{translate("sms.preview.title")}</header>
            <div className={styles.phoneMessage}>
              <p>{smsText}</p>
            </div>
            <div
              className={cx("icon-text", "has-background-info-light", `${styles.phoneCount}`, {
                "has-background-warning has-text-warning-dark": length >= 140 && length < per_message,
                "has-background-danger has-text-white has-text-weight-bold": length >= per_message,
              })}
            >
              <span className="icon">
                <i
                  className={cx("fas", {
                    "fa-info-circle": length < 140,
                    "fa-exclamation-triangle": length >= 140 && length < per_message,
                    "fa-exclamation-circle": length >= per_message,
                  })}
                ></i>
              </span>{" "}
              <span className="is-size-7">
                {length} / {per_message} | {translate("sms.preview.number")} : {messages}
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
};
