import React, { useContext, useState } from "react";
import { useTemplateConfiguration } from "../hooks/use-template-config";
import { useTranslation } from "../hooks/use-translation";
import { Substitutions } from "../types";
import { Preview } from "./preview";
import { useTemplateFormConfiguration } from "../hooks/use-template-form-config";
import { useClientContext } from "../hooks/use-client-context";
import { WizardContext } from "../context/wizard-context";

type Props = {
  templateId: string; // Provided by navigation
  substitutions?: Substitutions;
  campaignId?: string;
};

export const EmailPreview: React.FC<Props> = ({
  templateId,
  substitutions,
  campaignId,
}) => {
  const [highlightsVisible, setHighlightsVisible] = useState(false);

  const { clientContext } = useClientContext();
  const { channel } = useContext(WizardContext);
  const { translate } = useTranslation();

  const {
    variableNames: variablesNamesWithActito,
    substitutions: substitutionsWithActito,
    isLoading: isLoadingWithActito,
  } = useTemplateFormConfiguration(campaignId, templateId, "EMAIL");
  const {
    variableNames: variableNamesWithoutActito,
    substitutions: substitutionsWithoutActito,
    isLoading: isLoadingWthoutActito,
  } = useTemplateConfiguration(templateId);
  const displayEmailTemplateWithActito =
    clientContext?.enabledTemplateWithActito && channel === "EMAIL";

  const variableNames = displayEmailTemplateWithActito
    ? variablesNamesWithActito
    : variableNamesWithoutActito;
  const defaultSubstitutions = displayEmailTemplateWithActito
    ? substitutionsWithActito
    : substitutionsWithoutActito;
  const isLoading = displayEmailTemplateWithActito
    ? isLoadingWithActito
    : isLoadingWthoutActito;

  const toggleHightsVisibility = () => {
    setHighlightsVisible((previousValue) => !previousValue);
  };

  if (isLoading) {
    return null;
  }
  return (
    <>
      <div className="level">
        <h1 className="is-size-4 has-text-weight-bold">
          {translate("preview.title")}
        </h1>
        <div className="field">
          <input
            id="toggle"
            type="checkbox"
            name="switchExample"
            className="switch"
            onChange={toggleHightsVisibility}
          />
          <label htmlFor="toggle">{translate("preview.highlight")}</label>
        </div>
      </div>
      <Preview
        substitutions={substitutions || defaultSubstitutions}
        {...{ templateId, variableNames, highlightsVisible, campaignId }}
      />
    </>
  );
};
