import { useContext } from "react";
import { LoginContext } from "../context/login-context/login-context";

type ModulesPermissions = {
  isSmsModuleActivated: boolean;
  isEmailModuleActivated: boolean;
};

export const useModulesPermissions = (): ModulesPermissions => {
  const {
    modulesPermissions: {
      SMS: { enabled: isSmsModuleActivated },
      EMAIL: { enabled: isEmailModuleActivated },
    },
  } = useContext(LoginContext);

  return { isSmsModuleActivated, isEmailModuleActivated };
};
