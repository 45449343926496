import React, { FC } from "react";
import { useTranslation } from "../../hooks/use-translation";
interface ButtonsModalFooterProps {
  onConfirm: () => void;
}
export const ButtonsModalFooterSpellCheck: FC<ButtonsModalFooterProps> = ({  onConfirm }) => {
  const { translate } = useTranslation();

  return (
    <>
      <div className="buttons is-right">
        <button onClick={onConfirm} className="button is-success">
          {translate("wizard.template.spellcheck.disclaimer.accept")}
        </button>
      </div>
    </>
  );
};
