import { useQuery } from "react-query";
import { apiGet } from "../api/api-calls";

type FetchedAttributes = {
  attributes: { technicalName: string; displayName: string; type: string; acceptedValues?: string[] }[];
};

type AttributesReturn = {
  fetchedAttributes: FetchedAttributes;
  isLoading: boolean;
};

const EmptyAttributes: AttributesReturn = {
  fetchedAttributes: { attributes: [] },
  isLoading: true,
};

export function useAttributes(): AttributesReturn {
  const { data, isLoading } = useQuery("customizations", attributesListFetcher, {
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });
  const { fetchedAttributes } = data || EmptyAttributes;
  return { isLoading, fetchedAttributes };
}

async function attributesListFetcher(_: string) {
  const fetchedAttributes = await apiGet<FetchedAttributes>(`/api/attributes`);
  return {
    fetchedAttributes,
  };
}
