import { Targeting, FormValues } from "../types";

export function generateNewTargeting(initialTargeting: Targeting, data: FormValues): Targeting {
  const { targetingId, scope } = initialTargeting;
  return {
    targetingId,
    scope,
    subscription : data.subscription ? data.subscription : initialTargeting.subscription,
    segmentCriteria: extractSelectedList(data),
    attributeCriteria: data.attributeCriteria,
  } as Targeting;
}

export const extractSelectedList = (data: FormValues): [{ operator: string; segmentNames: string[] }] | [] => {
  //Return lists whose are checked (true)
  const checkedValues = Object.keys(data.selectedLists).filter((k) => data.selectedLists[k]);

  if (checkedValues.length === 0) {
    return [];
  }

  return [{ operator: "SOME", segmentNames: checkedValues }];
};

// const trimEmptyValuesFromAttributeCriterias = (data: FormValues): AttributeCriterion[] => {
//   const trimedData = [...data.attributeCriteria];

//   const deleteCriterion = (index: number) => {
//     trimedData.splice(index, 1);
//     index--;
//     return trimedData;
//   };

//   for (let index = 0; index < trimedData.length; index++) {
//     const criterion = trimedData[index];

//     if (!criterion.attributeName) {
//       deleteCriterion(index);
//     }

//     if (!criterion.operator) {
//       deleteCriterion(index);
//     }

//     // if the value of the criterion equals an empty string, remove the criterion
//     if (criterion.value === "") {
//       deleteCriterion(index);
//     }

//     if (!criterion.value) {
//       if (!criterion.operator.includes("IN" || "NOT_IN")) {
//         deleteCriterion(index);
//       }
//     }

//     // if the value of the criterion equals an array =>
//     if (Array.isArray(criterion.value)) {
//       // => remove empty strings from the array
//       criterion.value = criterion.value.filter((x) => x !== "");
//       // => check if the array contains only one string
//       if (criterion.value.length === 1) {
//         // => if so, convert the multiple operator (IN/NOT_IN) to the single value counterpart (EQ/NE)
//         if (criterion.operator === "IN") {
//           criterion.operator = "EQ";
//         }
//         if (criterion.operator === "NOT_IN") {
//           criterion.operator = "NE";
//         }
//         // => if so, set criterion value as a simple string instead of array
//         criterion.value = criterion.value[0];
//       }
//       // => check if the array is empty then delete the criterion
//       if (criterion.value.length === 0) {
//         deleteCriterion(index);
//       }
//     }
//   }
//   return trimedData;
// };
